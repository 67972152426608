<template>
  <div>
    <h3>Attribution Library</h3>
    <attribution-library-table />
  </div>
</template>

<script>
import AttributionLibraryTable from '../../attribution/AttributionLibraryTable';

export default {
  components: {
    AttributionLibraryTable,
  },
};
</script>
