<template>
  <div>
    <h3 v-if="isDialog != true">
      Create Post Campaign Report
    </h3>
    <el-form :model="form">
      <el-form-item label="Report Name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="Report Title">
        <el-input v-model="form.title" />
      </el-form-item>
      <span style="font-size: 14px; color: #606266;">Upload a logo (optional):</span><br><br>
      <uploader
        ref="postCampaignUploader"
        :accept-file-exts="fileExts"
        api-endpoint="/api/v1/attribution/post-campaign/post-campaign-logo"
        single-file
        upload-field-name="post-campaign-logo"
        @complete="uploadComplete"
        @file-change="handleFileChange"
        @upload-state-change="handleStateChange"
      />
      <el-form-item label="Date Range">
        <br>
        {{ organization }}
        <date-picker
          :date-range.sync="form.dateRange"
          tooltip="Providing no date range will generate a report for the entire campaign length."
          :range-date-override="true"
        />
        <el-button
          size="small"
          @click="clearDatRange()"
        >
          Clear
        </el-button>
      </el-form-item>
      <el-form-item label="Bidder UUID">
        <el-input
          v-model="form.bidder_uuid"
          placeholder="Please input a bidder campaign uuid"
        />
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="filterLineitems">
          Filter line items
        </el-checkbox>
        <div v-if="filterLineitems">
          <div v-if="filterLineitems">
            <div
              v-for="(value, index) in lineItem_uuids"
              :key="index"
            >
              <el-input
                v-model="lineItem_uuids[index]"
                placeholder="Please input a bidder campaign uuid"
                style="margin-bottom: 5px;"
              />
            </div>
          </div>
          <div v-if="filterLineitems">
            <el-button
              size="small"
              type="primary"
              @click="addUUID()"
            >
              Add
            </el-button>
            <el-button
              size="small"
              @click="removeUUID()"
            >
              Remove
            </el-button>
            <el-button
              size="small"
              type="danger"
              @click="clearUUIDS()"
            >
              Clear
            </el-button>
          </div>
          <el-form-item />
        </div>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >

      <el-button
        type="primary"
        :loading="loading"
        @click="generatePostCampaign"
      >Confirm</el-button>
    </span>
  </div>
</template>

<script>
import { attribution as attributionApi } from '@/adonis-api';
import DatePicker from '../../global/DatePicker.vue';
import Uploader from '../../global/Uploader.vue';

export default {
  components: {
    DatePicker,
    Uploader,
  },
  props: {
    report: {
      type: Object,
      required: false,
      default: () => {},
    },
    isDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      logo: null,
      fileExts: ['.png', '.svg', '.jpg'],
      filterLineitems: false,
      lineItem_uuids: [null],
      form: {
        name: null,
        bidder_uuid: null,
        attribution_id: null,
        title: null,
        dateRange: null,
        logo_url: null,
      },
      dialogFormVisible: false,
      loading: false,
      logo_loading: false,
    };
  },

  methods: {
    clearDateRange() {
      this.form.dateRange = null;
    },
    addUUID() {
      this.lineItem_uuids.push(null);
    },
    removeUUID() {
      this.form.lineItem_uuids.pop();
    },
    clearUUIDS() {
      this.form.lineItem_uuids = [null];
    },
    async generatePostCampaign() {
      if (this.filterLineitems) {
        this.form.lineitems = this.lineItem_uuids;
      }
      if (this.form.name == null || this.form.bidder_uuid == null || this.form.title == null) {
        return this.$alert('Please ensure name, uuid, and title are filled in.');
      }
      this.form.attribution_id = this.report != null ? this.report.id : null;
      await this.$refs.postCampaignUploader.startUpload({
        name: this.form.name,
      });
      this.loading = true;
      this.$alert('Your report is being generated and will arrive by email.');
      const response = await attributionApi.generatePostCampaign(this.form);
      if (response.status === 'success') {
        this.$store.dispatch('mqtt/addRawJob', response.data.job);
      }
      this.clearForm();
      if (this.isDialog) {
        this.$emit('close');
      } else {
        this.$router.push('/attribution-reports/library');
      }
    },
    handleStateChange(isUploading) {
      this.loading = isUploading;
    },
    uploadComplete(result) {
      this.form.logo_url = result.data.logo_url;
    },
    handleFileChange(fileList) {
      this.logo = fileList[0];
    },
    clearForm() {
      this.form.name = null;
      this.form.bidder_uuid = null;
      this.form.dateRange = null;
      this.form.title = null;
      this.loading = false;
    },
  },
};
</script>

<style>
</style>
