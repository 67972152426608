import { Line, mixins } from 'vue-chartjs';

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'labels'],
  mounted() {
    // Initialize an array with all the weekday names
    const labels = this.labels;

    // Initialize data arrays with 0 for each weekday for both initial and repeat kinds
    const initialData = Array(7).fill(0);
    const repeatData = Array(7).fill(0);

    // Map your data to the correct day and sum of visits for both initial and repeat kinds
    this.chartData.attributionVisitsByWeekday.forEach(item => {
      const dayIndex = item.date_day - 1; // Subtract 1 since array index is 0-based
      if (item.kind === 'initial') {
        initialData[dayIndex] = item.sum;
      } else if (item.kind === 'repeat') {
        repeatData[dayIndex] = item.sum;
      }
    });

    const datasets = [
      {
        data: initialData,
        borderColor: 'rgba(43, 116, 184, 0.4)',
        backgroundColor: 'rgba(43, 116, 184, 0.4)',
        tension: 0.1,
        fill: true,
      },
      {
        data: repeatData,
        borderColor: 'rgba(244, 212, 112, 0.4)',
        backgroundColor: 'rgba(244, 212, 112, 0.4)',
        tension: 0.1,
        fill: true,
      },
    ];

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      scales: {
        x: {
          grid: { display: false },
          ticks: { font: { weight: 'bold' } },
        },
        y: {
          stacked: true,
          grid: { display: false },
          ticks: { font: { weight: 'bold' } },
        },
      },
      legend: { display: false },
    };

    this.renderChart({ labels, datasets }, options);
  },
};
