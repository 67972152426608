<template>
  <div class="legend">
    <div class="legend-title">
      <slot>Legend:</slot>
    </div>
    <div class="dots">
      <div
        v-for="status in config.statuses"
        :key="status.value"
        class="legend-line"
      >
        <status-dot
          :config="config"
          :value="status.value"
        />
        <div class="label">
          {{ status.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusDot from './StatusDot.vue';

export default {
  components: { StatusDot },
  props: {
    config: {
      validator(value) {
        return value.statuses && value.statuses.length > 0;
      },
      required: true,
    },
  },
};
</script>

<style scoped>
.legend {
  font-size: 0.8em;
}

.legend-title {
  font-weight: bold;
  margin-bottom: 0.4em;
}

.legend-line {
  align-items: center;
  display: flex;
}

.label {
  margin-left: 0.2em;
}
</style>
