<template>
  <div class="weather-main-container">
    <div class="weather-card-container">
      <div
        v-for="day in visibleDays"
        :key="day.datetime"
        class="weather-card"
      >
        <div class="weather-icon">
          <img
            :src="`/images/weather-icons/${day.icon}.svg`"
            alt="Weather Icon"
          >
        </div>
        <div class="weather-details">
          <h2>{{ formatDate(day.datetime) }}</h2>
          <h4>{{ weatherData.resolvedAddress }}</h4>
          <!-- Added location -->
          <p>{{ day.description }}</p>
          <div class="temperature">
            <span>{{ day.tempmax }}°F / {{ day.tempmin }}°F</span>
          </div>
          <div class="humidity-wind">
            <span>Humidity: {{ day.humidity }}%</span>
            <span>Wind: {{ day.windspeed }} mph</span>
          </div>
        </div>
      </div>
    </div>
    <div class="slider-container">
      <el-button
        icon="el-icon-minus"
        @click="selectedDay -= 1"
      />
      <input
        v-model="selectedDay"
        type="range"
        :max="weatherData.days.length - 1"
        @input="updateWeatherData"
      >
      <el-button
        icon="el-icon-plus"
        @click="selectedDay += 1"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    weatherData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedDay: 0,
      visibleDays: [],
    };
  },
  watch: {
    selectedDay() {
      this.updateWeatherData();
    },
  },
  mounted() {
    this.updateWeatherData();
  },
  methods: {
    updateWeatherData() {
      this.visibleDays = this.weatherData.days.slice(
        this.selectedDay,
        Number(this.selectedDay) + 4,
      );
    },
    formatDate(dateStr) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      // Add "T12:00:00Z" to ensure it won't shift to the previous day
      return new Date(dateStr + 'T12:00:00Z').toLocaleDateString(
        undefined,
        options,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.weather-card-container {
  display: flex;
  gap: 20px;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  height: 300px;
  min-height: 100%;
}
.weather-card {
  display: flex;
  align-items: center;
  background-color: #e5e5e5;
  border-radius: 10px;
  padding: 10px;
  width: 300px;
  height: 250px;
}
.weather-icon img {
  width: 50px;
  height: 50px;
}
.weather-details {
  margin-left: 10px;
}
.temperature {
  font-weight: bold;
}
.humidity-wind span {
  margin-right: 10px;
}
.slider-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 12px;
  height: 20%;
}
.weather-details h4 {
  font-size: 14px;
  color: #777;
  margin: 0;
}

@media (max-width: 900px) {
  .weather-main-container {
    height: 100%;
    max-height: 100%;

    .slider-container {
      margin: 0;
      height: 10%;
    }
  }
  .weather-card-container {
    display: grid;
    grid-template-columns: auto auto;
    height: 80%;
    gap: 12px;
  }

  .weather-card {
    width: 300px;
    height: 130px;
    padding: 10px;
    .weather-details {
      h2 {
        font-size: 18px;
      }

      h2,
      h4,
      p {
        margin: 0;
      }
    }
  }
}

@media (max-height: 1200px) {
  .weather-main-container {
    height: 80%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .slider-container {
      margin: 0;
    }
  }

  .weather-card-container {
    height: 100%;
    max-height: 100%;
  }

  .weather-card {
    width: 25%;
    height: 80%;
    padding: 12px;
    .weather-details {
      h2 {
        font-size: 18px;
      }

      h2,
      h4,
      p {
        margin: 0;
      }
    }
  }
}

@media (max-height: 800px) {
  .weather-card {
    width: 25%;
    height: 80%;
    padding: 12px;
    .weather-details {
      h2 {
        font-size: 16px;
      }

      h2,
      h4,
      p {
        margin: 0;
      }

      h4,
      p,
      span {
        font-size: 14px;
      }
    }
  }

  .weather-card-container {
    gap: 8px;
  }
}
</style>
