<template>
  <div>
    <div
      v-if="loading"
      class="loader"
      style="margin: auto; margin-top: 25%;"
    />
    <div v-if="!loading">
      <div>
        <h3>Create Campaign Impact Report</h3>
      </div>
      <div class="sections">
        <div v-if="matchbackS3Location == null">
          <h2>Step 1.</h2>
          <matchback-form @location="handleLocation" />
        </div>

        <div v-if="matchbackS3Location != null">
          <h2>Step 2.</h2>
          <campaign-audience-selection @submit="handleSubmit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MatchbackForm from '../../attribution/MatchbackForm.vue';
import CampaignAudienceSelection from '../../attribution/CampaignAudienceSelection.vue';
import { attribution as attributionApi } from '@/adonis-api';

export default {
  components: {
    MatchbackForm,
    CampaignAudienceSelection,
  },
  data() {
    return {
      count: 0,
      matchbackS3Location: null,
      report_name: null,
      targets: null,
      organization_id: null,
      loading: false,
    };
  },
  computed: {

  },
  methods: {
    handleLocation(data) {
      this.matchbackS3Location = data.location;
      this.report_name = data.report_name;
      this.organization_id = data.organization_id;
      this.count = data.count;
    },
    async handleSubmit(data) {
      this.loading = true;
      this.targets = data.targets;
      const reportData = {
        matchback_location: this.matchbackS3Location,
        report_name: this.report_name,
        targets: this.targets,
        organization_id: this.organization_id,
        address_count: this.count,
      };

      const response = await attributionApi.createMatchbackReport(reportData);
      if (response.status === 'success') {
        this.$router.push('/attribution-reports/library');
        this.loading = false;
      }
    },
  },

};
</script>

<style lang="scss">
  .sections > div:not(:last-of-type) {
  margin-bottom: 1em;
  }

  .loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #EC7C30;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
  }

  @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
