<template>
  <div class="main-container">
    <!-- Sidebar -->
    <nav
      v-if="orientation == 'landscape'"
      class="navbar"
    >
      <div
        v-for="(item, index) in navItems"
        v-if="item.display"
        :key="index"
      >
        <img
          alt="any"
          :src="item.image"
          class="navbar-image"
          :class="{ active: item.name === currentPage, grown: item.isGrown }"
          @click="navigate(item.name)"
          @mouseover="growImage(index)"
          @mouseout="shrinkImage(index)"
        >
      </div>
      &emsp;
    </nav>

    <!-- LANDSCAPE -->
    <title-page
      v-if="isTitlePage && reportData"
      :orientation="orientation"
      :report-data="reportData"
    />
    <attribution-summary
      v-if="reportData && orientation == 'landscape' && isAttributionSummary"
      :orientation="orientation"
      :report-data="reportData"
    />
    <footfall-report
      v-if="reportData && orientation == 'landscape' && isFootfallReport"
      :orientation="orientation"
      :report-data="reportData"
    />
    <footfall-report-2
      v-if="reportData && orientation == 'landscape' && isSecondFootfallReport"
      :orientation="orientation"
      :report-data="reportData"
    />
    <responders-report
      v-if="reportData && orientation == 'landscape' && isRespondersReport"
      :orientation="orientation"
      :report-data="reportData"
    />
    <responders-report-2
      v-if="
        reportData && orientation == 'landscape' && isSecondRespondersReport
      "
      :orientation="orientation"
      :report-data="reportData"
    />
    <online-report
      v-if="reportData && orientation == 'landscape' && isOnlineReport"
      :orientation="orientation"
      :report-data="reportData"
    />

    <online-report-2
      v-if="reportData && orientation == 'landscape' && isSecondOnlineReport"
      :orientation="orientation"
      :report-data="reportData"
    />

    <!-- PORTRAIT -->
    <attribution-summary-portrait
      v-if="reportData && orientation == 'portrait' && isAttributionSummary"
      :orientation="orientation"
      :report-data="reportData"
    />
    <footfall-report-portrait
      v-if="reportData && orientation == 'portrait' && isFootfallReport"
      :orientation="orientation"
      :report-data="reportData"
    />
    <footfall-report-2-portrait
      v-if="reportData && orientation == 'portrait' && isSecondFootfallReport"
      :orientation="orientation"
      :report-data="reportData"
    />

    <responders-report-portrait
      v-if="reportData && orientation == 'portrait' && isRespondersReport"
      :orientation="orientation"
      :report-data="reportData"
    />
    <responders-report-2-portrait
      v-if="reportData && orientation == 'portrait' && isSecondRespondersReport"
      :orientation="orientation"
      :report-data="reportData"
    />
    <online-report-portrait
      v-if="reportData && orientation == 'portrait' && isOnlineReport"
      :orientation="orientation"
      :report-data="reportData"
    />
    <online-report-2-portrait
      v-if="reportData && orientation == 'portrait' && isSecondOnlineReport"
      :orientation="orientation"
      :report-data="reportData"
    />

    <end-page
      v-if="isEndPage && reportData"
      :orientation="orientation"
      :report-data="reportData"
    />

    <!-- Exception handling components -->
    <div
      v-if="loading"
      class="loader-container"
    >
      <div class="loader" />
    </div>
    <div v-if="error">
      An error occurred: {{ error.message }}
    </div>
    <div v-if="!loading && !reportData">
      No report data found.
    </div>
  </div>
</template>
<script>
import TitlePage from '../TitlePage.vue';
import AttributionSummary from './Landscape/AttributionSummary.vue';
import AttributionSummaryPortrait from './Portrait-v2/AttributionSummary.vue';

import FootfallReport from './Landscape/FootfallReport.vue';
import FootfallReportPortrait from './Portrait-v2/FootfallReport.vue';
import FootfallReport2 from './Landscape/FootFallReport2.vue';
import FootfallReport2Portrait from './Portrait-v2/FootFallReport2.vue';
import RespondersReport from './Landscape/RespondersReport.vue';
import RespondersReportPortrait from './Portrait-v2/RespondersReport.vue';
import RespondersReport2 from './Landscape/RespondersReport2.vue';
import RespondersReport2Portrait from './Portrait-v2/RespondersReport2.vue';
import OnlineReport from './Landscape/OnlineReport.vue';
import OnlineReportPortrait from './Portrait-v2/OnlineReport.vue';
import OnlineReport2 from './Landscape/OnlineReport2.vue';
import OnlineReport2Portrait from './Portrait-v2/OnlineReport2.vue';
import EndPage from './EndPage.vue';
import { attribution as attributionApi } from '@/adonis-api';

export default {
  name: 'AttributionReport',
  components: {
    TitlePage,
    AttributionSummary,
    AttributionSummaryPortrait,
    FootfallReport,
    FootfallReportPortrait,
    FootfallReport2,
    FootfallReport2Portrait,
    RespondersReport,
    RespondersReportPortrait,
    RespondersReport2,
    RespondersReport2Portrait,
    OnlineReport,
    OnlineReportPortrait,
    OnlineReport2,
    OnlineReport2Portrait,
    EndPage,
  },
  data() {
    return {
      reportData: null,
      loading: false,
      error: null,
      currentPage: '',
    };
  },
  computed: {
    navItems() {
      const digitalOnly = this.reportData ? this.reportData.digital_only : false;
      const digitalDisplay = this.reportData ? this.reportData.digital_display : false;
      const respondersDisplay = this.reportData ? this.reportData.display_responders : false;
      return [
        { name: 'title_page', image: '/images/title.webp', isGrown: false, display: true },
        { name: 'attribution_summary', image: '/images/attributionsummary.png', isGrown: false, display: true },
        { name: 'footfall_report', image: '/images/footfall.png', isGrown: false, display: !digitalOnly },
        { name: 'footfall_report_2', image: '/images/footfall2.png', isGrown: false, display: !digitalOnly },
        { name: 'online_report', image: '/images/online.png', isGrown: false, display: digitalDisplay },
        { name: 'online_report_2', image: '/images/online2.png', isGrown: false, display: digitalDisplay },
        { name: 'responders_report', image: '/images/responders.png', isGrown: false, display: respondersDisplay },
        { name: 'responders_report_page_2', image: '/images/responders2.png', isGrown: false, display: respondersDisplay },
        { name: 'end_page', image: '/images/end.webp', isGrown: false, display: true },
      ];
    },
    reportType() {
      return this.$route.path.split('/')[3];
    },
    reportId() {
      return this.$route.query.reportId;
    },
    orientation() {
      return this.$route.query.orientation || 'portrait';
    },
    isTitlePage() {
      return this.reportType === 'title_page';
    },
    isAttributionSummary() {
      return this.reportType === 'attribution_summary';
    },
    isFootfallReport() {
      return this.reportType === 'footfall_report';
    },
    isSecondFootfallReport() {
      return this.reportType === 'footfall_report_2';
    },
    isOnlineReport() {
      return this.reportType === 'online_report';
    },
    isSecondOnlineReport() {
      return this.reportType === 'online_report_2';
    },
    isRespondersReport() {
      return this.reportType === 'responders_report';
    },
    isSecondRespondersReport() {
      return this.reportType === 'responders_report_page_2';
    },
    isEndPage() {
      return this.reportType === 'end_page';
    },
  },
  created() {
    this.fetchReportData();
    this.currentPage = this.currentPage || this.reportType; // Default to 'title_page' if currentPage is empty
  },
  methods: {
    scrollLeft() {
      const currentIndex = this.navItems.findIndex(
        (item) => item.name === this.currentPage,
      );
      if (currentIndex > 0) {
        this.navigate(this.navItems[currentIndex - 1].name);
      }
    },

    scrollRight() {
      const currentIndex = this.navItems.findIndex(
        (item) => item.name === this.currentPage,
      );
      if (currentIndex < this.navItems.length - 1) {
        this.navigate(this.navItems[currentIndex + 1].name);
      }
    },

    growImage(index) {
      this.navItems[index].isGrown = true;
    },
    shrinkImage(index) {
      this.navItems[index].isGrown = false;
    },

    navigate(pageName) {
      this.currentPage = pageName;

      this.$router.push({
        path: `/attribution/reports/${pageName}`,
        query: {
          reportId: this.reportId,
          orientation: this.orientation,
        },
      });
    },
    async fetchReportData() {
      this.loading = true;
      try {
        const response = await attributionApi.getReportData(this.reportId);
        this.reportData = response;
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.main-container {
  width: 100%;
  max-width: 100vw;
  /* overflow: hidden; */
  display: flex;
}

.fake-nav {
  min-width: 135px;
}

.container {
  flex-grow: 1;
}

.content-wrapper {
  height: 100vh;
  overflow-y: hidden;
  max-width: 100%;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.navbar {
  background-color: #333;
  max-height: 100vh;
  /* position: fixed; */
  /* top: 0; */
  /* width: fit-content; */
  width: 150px;
  max-width: 20%;
  overflow-y: auto;
  overflow-x: hidden;
  /* padding-top: 1rem; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 0 12px;
  gap: 12px;
}

.navbar div {
  margin: auto;
}

img {
  width: 100px;
  height: 150px;
  transition: all 0.3s ease;
}

/* Updated navbar styles */

/* Updated arrow styles */
.arrow {
  cursor: pointer;
  font-size: 36px;
  margin: auto;
  color: white;
}

/* width */
.navbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.navbar::-webkit-scrollbar-track {
  background: #333;
}

/* Handle */
.navbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.navbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.right-arrow {
  margin: auto;
}

.navbar-image {
  /* margin-bottom: 10px; */
}

.navbar-image.active,
.navbar-image.grown {
  border: 2px solid rgb(230, 82, 45);
}

.grown {
  transform: scale(1.1);
}

.arrow-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.arrow-button:active .arrow {
  transform: scale(0.9);
}
</style>
./Landscape/OnlineReport2.vue
