<template>
  <div>
    <el-container>
      <el-aside width="200px">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-menu-item index="1">
            Title Page
          </el-menu-item>
          <el-menu-item index="2">
            Attribution Summary
          </el-menu-item>
          <el-menu-item index="3">
            Footfall Attribution
          </el-menu-item>
          <el-menu-item index="4">
            Online Attribution
          </el-menu-item>
          <el-menu-item index="5">
            Responders Profile
          </el-menu-item>
          <el-menu-item index="6">
            Final Page
          </el-menu-item>
        </el-menu>
      </el-aside>

      <el-main class="main-content">
        <el-form
          ref="ruleForm"
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          label-width="120px"
          class="ruleForm"
        >
          <div v-show="activeIndex === '1'">
            <!-- Options for Title Page -->
            <p>Title Page Options</p><br>
            <el-row
              :gutter="20"
              class="section"
            >
              <el-col
                :xs="24"
                :sm="24"
                :md="6"
              >
                <el-form-item
                  label="URL:"
                  prop="titlePageOptions.url"
                >
                  <el-input
                    id="url"
                    v-model="ruleForm.titlePageOptions.url"
                    placeholder="Enter URL"
                    maxlength="100"
                    class="input-field"
                    @input="updateOptions"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="6"
              >
                <el-form-item
                  label="PDF Title:"
                  prop="titlePageOptions.pdfTitle"
                >
                  <el-input
                    id="pdfTitle"
                    v-model="ruleForm.titlePageOptions.pdfTitle"
                    placeholder="Enter PDF Title"
                    maxlength="100"
                    class="input-field"
                    @input="updateOptions"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              :gutter="20"
              class="section"
            >
              <el-col>
                <div>
                  <label>Upload a logo (optional):</label>
                  <p class="upload-instructions">
                    Recommended format: SVG or PNG with a minimum size of 500x500 pixels for best results when stretched.
                  </p>
                  <uploader
                    ref="pdfCoverLogo"
                    :accept-file-exts="fileExts"
                    api-endpoint="/api/v1/attribution/pdf/pdf-logo"
                    single-file
                    upload-field-name="pdf-logo"
                    @complete="uploadComplete"
                    @file-change="handleFileChange"
                    @upload-state-change="handleStateChange"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-show="activeIndex === '2'">
            <!-- Options for Attribution Summary -->
            <p>Attribution Summary Options</p><br>
            <div>
              <el-row
                class="section"
                :gutter="20"
              >
                <el-col
                  :xs="24"
                  :sm="24"
                  :md="12"
                >
                  <el-form-item
                    label="Description:"
                    prop="attributionSummaryOptions.attribution_summary_description"
                  >
                    <el-input
                      v-model="ruleForm.attributionSummaryOptions.attribution_summary_description"
                      type="textarea"
                      :placeholder="placeholderText"
                      resize="none"
                      :autosize="{ minRows: 6, maxRows: 10}"
                      show-word-limit
                      :maxlength="500"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div v-show="activeIndex === '3'">
            <!-- Options for Footfall Attribution -->
            <p>Footfall Attribution Options</p><br>
            <el-row
              class="section"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="24"
                :md="12"
              >
                <el-form-item
                  label="Description:"
                  prop="footfallAttributionOptions.attribution_footfall_description"
                >
                  <el-input
                    v-model="ruleForm.footfallAttributionOptions.attribution_footfall_description"
                    type="textarea"
                    :placeholder="placeholderText"
                    resize="none"
                    :autosize="{ minRows: 6, maxRows: 10}"
                    show-word-limit
                    :maxlength="500"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              :gutter="20"
              class="section"
            >
              <el-col
                :xs="24"
                :sm="24"
                :md="6"
              >
                <div class="field-group">
                  <label for="visitsByWeekday">Visits by Weekday: </label>
                  <chart-toggle-input
                    :on-click="
                      () =>
                        (ruleForm.footfallAttributionOptions.visitsByWeekday.enabled =
                          !ruleForm.footfallAttributionOptions.visitsByWeekday.enabled)
                    "
                    :is-active="ruleForm.footfallAttributionOptions.visitsByWeekday.enabled"
                  />
                </div>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="6"
              >
                <div class="field-group">
                  <label for="weekday">Choose Days:</label>
                  <el-checkbox-group
                    v-model="ruleForm.footfallAttributionOptions.visitsByWeekday.days"
                  >
                    <el-checkbox
                      v-for="item in daysOfWeek"
                      :key="item.value"
                      :label="item.value"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-col>
            </el-row>
            <el-row
              class="section"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="24"
                :md="6"
              >
                <div class="map-toggle">
                  <label for="mapDisplay">Display Top Performing Zip Code Boundary Map: </label><br><br>
                  <i
                    class="fa fa-globe-americas map-icon"
                    :class="{ 'active-map': ruleForm.footfallAttributionOptions.mapDisplay }"
                    @click="ruleForm.footfallAttributionOptions.mapDisplay = !ruleForm.footfallAttributionOptions.mapDisplay"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-show="activeIndex === '4'">
            <p>Online Attribution Options</p><br>
            <el-row
              class="section"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="24"
                :md="12"
              >
                <el-form-item
                  label="Description:"
                  prop="onlineAttributionOptions.attribution_online_description"
                >
                  <el-input
                    v-model="ruleForm.onlineAttributionOptions.attribution_online_description"
                    type="textarea"
                    :placeholder="placeholderText"
                    resize="none"
                    :autosize="{ minRows: 6, maxRows: 10}"
                    show-word-limit
                    :maxlength="500"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <div>
              <el-row
                class="section"
                :gutter="20"
              >
                <el-col
                  :xs="24"
                  :sm="24"
                  :md="8"
                >
                  <div>
                    <div class="field-group">
                      <label for="visitsByWeekday">Online Visits by Weekday Chart: </label>
                      <chart-toggle-input
                        :is-active="ruleForm.onlineAttributionOptions.visitsByWeekday.enabled"
                        :on-click="() => {
                          ruleForm.onlineAttributionOptions.visitsByWeekday.enabled = !ruleForm.onlineAttributionOptions.visitsByWeekday.enabled
                        }"
                      />
                    </div>
                  </div>
                </el-col>
                <el-col
                  :xs="24"
                  :sm="24"
                  :md="8"
                >
                  <div class="field-group">
                    <label for="weekday">Choose Days:</label>
                    <el-checkbox-group
                      v-model="ruleForm.onlineAttributionOptions.visitsByWeekday.days"
                    >
                      <el-checkbox
                        v-for="item in daysOfWeek"
                        :key="item.value"
                        :label="item.value"
                      >
                        {{ item.label }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-row class="section">
              <el-col
                :xs="24"
                :sm="24"
                :md="8"
              >
                <div>
                  <div class="field-group">
                    <label for="visitsByWeekday">Online Visits by Date: </label>
                    <chart-toggle-input
                      :is-active="ruleForm.onlineAttributionOptions.visitsByDate.enabled"
                      :on-click="() => { ruleForm.onlineAttributionOptions.visitsByDate.enabled = !ruleForm.onlineAttributionOptions.visitsByDate.enabled }"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row class="section">
              <el-col
                :xs="24"
                :sm="24"
                :md="16"
              >
                <div class="map-toggle">
                  <label for="mapDisplay">Display Top Performing Zip Code Boundary Maps: </label><br><br>
                  <i
                    class="fa fa-globe-americas map-icon"
                    :class="{ 'active-map': ruleForm.onlineAttributionOptions.mapDisplay }"
                    @click="ruleForm.onlineAttributionOptions.mapDisplay = !ruleForm.onlineAttributionOptions.mapDisplay"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-show="activeIndex === '5'">
            <!-- Options for Title Page -->
            <p>Responders Options</p><br>
            <el-row
              class="section"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="24"
                :md="12"
              >
                <el-form-item
                  label="Description:"
                  prop="respondersOptions.attribution_responders_description"
                >
                  <el-input
                    v-model="ruleForm.respondersOptions.attribution_responders_description"
                    type="textarea"
                    :placeholder="placeholderText"
                    resize="none"
                    :autosize="{ minRows: 6, maxRows: 10}"
                    show-word-limit
                    :maxlength="500"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div v-show="activeIndex === '6'">
            <!-- Options for Title Page -->
            <p>Final Page Options</p><br>
            <el-row
              class="section"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="24"
                :md="12"
              >
                <el-form-item
                  label="Conclusion:"
                  prop="finalPageOptions.conclusionText"
                >
                  <el-input
                    v-model="ruleForm.finalPageOptions.conclusionText"
                    type="textarea"
                    placeholder="Enter your conclusion here"
                    resize="none"
                    :autosize="{ minRows: 6, maxRows: 10}"
                    show-word-limit
                    :maxlength="500"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="6"
              >
                <el-form-item
                  label="Contact Email:"
                  prop="finalPageOptions.contactEmail"
                >
                  <el-input
                    v-model="ruleForm.finalPageOptions.contactEmail"
                    type="email"
                    placeholder="Enter your email here"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-main>
    </el-container>
    <div>
      <el-button
        type="info"
        @click="previousStep()"
      >
        <span>Previous Step</span>
      </el-button>
      <el-button
        type="primary"
        @click="nextStep()"
      >
        <span>
          Next Step
        </span>
      </el-button>
    </div>
  </div>
</template>

<script>
import Uploader from '../global/Uploader.vue';
import uuid from 'uuid';
import ChartToggleInput from './components/ChartToggleInput.vue';

export default {
  components: {
    Uploader,
    ChartToggleInput,
  },
  props: {
    attributionTitle: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    const DESCRIPTION_VALIDATE = [
      { min: 3, max: 500, message: 'Length should be 3 to 500', trigger: 'blur' },
    ];
    return {
      placeholderText: 'We advertised to visitors of 10 competitor restaurants as well as the local trade area with a display and social media message to visit XYZ restaurant for a 10% off coupon. The goal was to increase awareness and visits to XYZ restaurant. Below is a detailed view of the campaign results.',
      activeIndex: '1',
      fileList: [],
      logo_url: null,
      loading: false,
      logo: null,
      fileExts: ['.png', '.svg', '.jpg'],
      logoFileName: null,
      daysOfWeek: [
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
      ],
      ruleForm: {
        titlePageOptions: {
          url: '',
          pdfTitle: this.attributionTitle,
        },
        attributionSummaryOptions: {
          attribution_summary_description: '',
          footfallUniqueResponders: true,
          onlineUniqueResponders: true,
          footfallTotalVisits: true,
          onlineTotalVisits: true,
          totalResponders: true,
          footTrafficByMonth: {
            enabled: true,
          },
          totalCombinedVisits: true,
        },
        footfallAttributionOptions: {
          attribution_footfall_description: '',
          footfallUniqueResponders: true,
          footfallTotalVisits: true,
          visitsByWeekday: {
            enabled: true,
            days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
          },
          FootfallResponsesTarget: true,
          FootfallResponsesDestination: true,
          FootfallByCity: true,
          FootfallByZipCode: true,
          weatherReport: true,
          mapDisplay: true,
        },
        onlineAttributionOptions: {
          attribution_online_description: '',
          onlineUniqueResponders: true,
          onlineTotalVisits: true,
          onlineResponsesByTargetAudience: true,
          onlineResponsesByCity: true,
          onlineResponsesByZipCode: true,
          mapDisplay: true,
          visitsByWeekday: {
            enabled: true,
            days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
          },
          visitsByDate: {
            enabled: true,
          },
        },
        respondersOptions: {
          attribution_responders_description: '',
        },
        finalPageOptions: {
          conclusionText: '',
          contactEmail: '',
        },
      },
      rules: {
        'titlePageOptions.url': [
          {
            trigger: 'blur',
            validator: (_, value, callback) => {
              const urlRegex = /^(https?:\/\/)?([^\s$.?#].[^\s]*)$/i;
              if (value && !urlRegex.test(value)) {
                callback(new Error('Invalid URL format'));
              }
              return callback();
            },
          },
        ],
        'titlePageOptions.pdfTitle': [
          { required: true, message: 'Please enter a PDF Title', trigger: 'blur' },
          { min: 3, max: 100, message: 'Length should be 3 to 100', trigger: 'blur' },
        ],
        'attributionSummaryOptions.attribution_summary_description': DESCRIPTION_VALIDATE,
        'onlineAttributionOptions.attribution_online_description': DESCRIPTION_VALIDATE,
        'respondersOptions.attribution_responders_description': DESCRIPTION_VALIDATE,
        'finalPageOptions.conclusionText': DESCRIPTION_VALIDATE,
        'finalPageOptions.contactEmail': [
          { type: 'email', message: 'Please enter a valid email address', trigger: 'blur' },
        ],
      },
    };
  },
  watch: {
    ruleForm: {
      handler: 'updateOptions',
      deep: true,
    },
    logoFileName: {
      handler: 'updateOptions',
      deep: true,
    },
  },
  mounted() {
    this.updateOptions();
  },
  methods: {
    uploadComplete(result) {
      this.logo_url = result.data.logo_url;
    },
    handleStateChange(isUploading) {
      this.loading = isUploading;
    },
    async handleFileChange(fileList) {
      this.logo = fileList[0];
      this.logoFileName =  uuid();
      await this.$refs.pdfCoverLogo.startUpload({
        logoFileName: this.logoFileName,
      });
    },
    handleSelect(key) {
      this.activeIndex = key;
    },
    updateOptions() {
      this.$emit('update-options', {
        titlePageOptions: this.ruleForm.titlePageOptions,
        attributionSummary: this.ruleForm.attributionSummaryOptions,
        footfallAttributionOptions: this.ruleForm.footfallAttributionOptions,
        onlineAttributionOptions: this.ruleForm.onlineAttributionOptions,
        respondersOptions: this.ruleForm.respondersOptions,
        finalPageOptions: this.ruleForm.finalPageOptions,
        logoFileName: this.logoFileName,
      });
    },
    handleRouteToError(error) {
      const keys = Object.keys(error);
      const keyInPage = [
        ['titlePageOptions.url', 'titlePageOptions.pdfTitle'],
        ['attributionSummaryOptions.attribution_summary_description'],
        ['footfallAttributionOptions.attribution_footfall_description'],
        ['onlineAttributionOptions.attribution_online_description'],
        ['respondersOptions.attribution_responders_description'],
        ['finalPageOptions.conclusionText', 'finalPageOptions.contactEmail'],
      ];
      keyInPage.forEach((value, index) => {
        if (value.some((v) => keys[0] === v)) {
          this.activeIndex = (index + 1)?.toString();
        }
      });
    },
    nextStep() {
      this.$refs.ruleForm.validate((valid, error) => {
        if (valid) {
          this.updateOptions();
          this.$emit('next-step');
        } else this.handleRouteToError(error);
      });
    },
    previousStep() {
      this.$emit('previous-step');
    },
  },
};
</script>

  <style scoped>
    .main-content {
      height: 600px;
      overflow-y: auto;
    }
    .field-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;
    }
    .options-row {
      display: flex;
      justify-content: space-around;
      margin-bottom: 20px;
    }
    .input-field {
      max-width: 300px;
    }

label {
    margin-bottom: 10px;
}
.section {
  background-color: #f8f8f8;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
}
.map-icon {
  color: gray;
  cursor: pointer;
  font-size: 75px; /* Adjust as needed */
  border: 2px solid gray;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

.map-icon:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.map-icon:active {
  box-shadow: none;
}

.active-map {
  color: #00D200; /* Change this to whatever color you want when active */
}

.upload-instructions {
  font-size: 0.9rem;
  color: #666;
  margin-top: 4px;
  margin-bottom: 12px;
}

  </style>
