<template>
  <div class="online-container">
    <div class="header">
      <h1>Online Attribution</h1>
      <p
        v-if="
          reportData.reportconfigs.onlineAttributionOptions
            .attribution_online_description
        "
      >
        {{ reportData.reportconfigs.onlineAttributionOptions.attribution_online_description }}
      </p>
    </div>
    <div class="content-wrapper">
      <div class="first-row">
        <div class="cell-wrapper">
          <div class="cell">
            <p class="number">
              {{
                Number(
                  reportData.reportData.digitalUniqueResponders[0]
                    .response_uniques
                ).toLocaleString() || 0
              }}
            </p>
            <p class="description">
              Online Unique Responders
            </p>
          </div>
          <div class="cell">
            <p class="number">
              {{
                Number(
                  reportData.reportData.digitalTotalVisits[0].total
                ).toLocaleString() || 0
              }}
            </p>
            <p class="description">
              Online Total Visits
            </p>
          </div>
        </div>

        <div
          v-if="
            reportData.reportconfigs.onlineAttributionOptions.visitsByWeekday
              .enabled
          "
          class="chart-wrapper"
        >
          <div class="legend-wrapper">
            <h1 class="title">
              Visits by Weekday
            </h1>
            <div class="legend-container">
              <div class="legend-item">
                <div class="circle blue" />
                <span>Initial</span>
              </div>
              <div
                class="legend-item"
                style="margin-top: 10px"
              >
                <div class="circle yellow" />
                <span>Repeat</span>
              </div>
            </div>
          </div>

          <line-chart
            :chart-data="reportData.reportData"
            :labels="
              reportData.reportconfigs.onlineAttributionOptions.visitsByWeekday
                .days
            "
            style="width: 100%; margin: 12px"
          />
        </div>
      </div>

      <div class="second-row">
        <h1 class="title">
          Online Visits by Date
        </h1>
        <line-chart-visits
          v-if="
            reportData.reportData.digitalUniqueResponders[0].response_uniques
          "
          :chart-data="reportData.reportData"
          style=""
        />
      </div>

      <footer-section :logo-u-r-l="logoURL" />
    </div>
  </div>
</template>

<script>
import NameAndResponses from '../NameAndResponses.vue';
import LineChart from '../../report-components/OnlineLineChart';
import LineChartVisits from '../../report-components/OnlineChartVisits';
import FooterSection from '../FooterSection.vue';

export default {
  name: 'OnlineReport',
  components: {
    NameAndResponses,
    LineChart,
    LineChartVisits,
    FooterSection,
  },
  props: {
    orientation: {
      type: String,
      required: true,
      validator: (value) => ['landscape', 'portrait'].includes(value),
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    displayData() {
      return {};
    },

    logoURL() {
      return this.reportData.logoURL || '/images/onspot-logo.svg';
    },
  },

  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>

<style lang="scss" scoped>
.online-container {
  max-height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  height: 10vh;
  margin: 12px;

  h1,
  p {
    margin: 0;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 90vh;
  gap: 12px;
  margin: 12px;
  border-radius: 8px;
  justify-content: space-between;

  background-color: #f3f3f3;
  padding: 14px;
}

.first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  margin: auto;
  height: 40%;
  gap: 12px;

  .cell-wrapper {
    height: 100%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;

    .cell {
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      height: 100%;

      p {
        margin: 0;
        text-align: left;
        margin: 18px;
        font-size: 24px;
      }

      .number {
        font-size: 36px;
        margin-bottom: 12px;
      }
    }
  }

  .chart-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 100%;

    .legend-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      gap: 12px;
      width: fit-content;
      margin: 14px;

      h1 {
        text-align: center;
        font-size: 24px;
        margin: 0;
      }
    }
  }
}

.second-row {
  height: 35%;
  width: 97%;
  margin: auto;
  max-height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;

  .title {
    height: 20%;
    margin: 0 12px 12px 0;
  }

  div {
    height: 80%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
  }
}

.legend-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: auto;
}

.blue {
  background-color: rgba(43, 116, 184, 0.4);
}

.yellow {
  background-color: rgba(244, 212, 112, 0.4);
}

@media (max-height: 900px) {
  .circle {
    width: 20px;
    height: 20px;
  }

  .footer-wrapper {
    height: 25%;
    align-items: end;
  }

  .first-row {
    width: 100%;
    margin: 0;
  }

  .legend-wrapper {
    .title {
        font-size: 20px !important;
    }
  }
  .second-row {
    width: 100%;
    height: 40%;
    margin: 0;
    .title {
      margin: 0;
    }
  }
  .header {
    height: 15vh;
  }
  .content-wrapper {
    height: 75vh;
    .cell-wrapper {
      .cell {
        p {
          margin: 8px;
        }

        .number {
          font-size: 24px;
        }

        .description {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
