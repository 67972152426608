<template>
  <div class="status-dot">
    <div
      :style="dotStyle"
      class="dot"
      :title="statusLabel"
    />
  </div>
</template>

<script>
export default {
  props: {
    config: {
      validator(value) {
        return value.statuses && value.statuses.length > 0;
      },
      required: true,
    },
    hollow: { type: Boolean, default: false },
    value: { type: String, required: true },
  },

  computed: {
    dotStyle() {
      const status = this.config.statuses.find(
        stat => stat.value === this.value,
      );

      if (!status) throw new Error(`StatusDot: invalid value ${this.value}`);

      return {
        backgroundColor: status.hollow ? 'transparent' : status.color,
        border: status.hollow ? `1px solid ${status.color}` : '',
      };
    },

    statusLabel() {
      const status = this.config.statuses.find(
        stat => stat.value === this.value,
      );

      if (!status) throw new Error(`StatusDot: invalid value ${this.value}`);

      return status.label;
    },
  },
};
</script>

<style scoped>
.status-dot {
  display: inline-block;
}

.dot {
  border-radius: 10px;
  height: 10px;
  width: 10px;
}
</style>
