<template>
  <div class="form-control">
    <label>Campaign Audience(s)</label>
    <el-row gutter="10">
      <el-col span="14">
        <el-table
          v-if="targets.length > 0"
          :data="targets"
          stripe
        >
          <el-table-column
            prop="name"
            label="Campaign Name"
          >
            <template slot-scope="{ row }">
              <audience-type-tooltip :type="row.type" />
              &nbsp;
              {{ getItemName(row) }}
              &nbsp;
              <el-tag
                v-if="isOutdated(row)"
                class="outdated-tag"
                effect="dark"
                size="mini"
                type="danger"
              >
                out of date
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="count"
            label="Devices"
            :width="110"
          >
            <template slot-scope="{ row }">
              <span>{{
                $options.filters.numeral(
                  row.targetType !== 'publication'
                    ? row.count
                    : row.publish_count
                )
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="start_date"
            label="Start Date"
            :width="110"
          />
          <el-table-column
            prop="end_date"
            label="End Date"
            :width="110"
          />
          <el-table-column
            prop="completed"
            label="Publication Completed"
            :width="160"
          >
            <template slot-scope="{ row }">
              {{ row.completed | dateString({ includeTime: true }) }}
            </template>
          </el-table-column>
          <el-table-column :width="60">
            <template slot-scope="{ $index }">
              <el-button
                class="remove"
                size="small"
                type="danger"
                icon="fa fa-trash"
                @click="removeAudience($index, 'targets')"
              />
            </template>
          </el-table-column>
        </el-table>

        <div class="attr-picker">
          <attribution-picker
            :selected-items="targets"
            stage="target"
            @select="handleChooseTarget"
          />
        </div>
      </el-col>
      <el-col span="10">
        <attribution-legend stage="target" />

        <div
          v-if="outdatedTargets"
          class="outdated-warning"
        >
          You have outdated audiences selected, please remove or replace them before continuing.
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-button
        style="margin-top: 5px;"
        type="primary"
        @click="submit"
      >
        Submit
      </el-button>
    </el-row>
  </div>
</template>

<script>
import _sumBy from 'lodash/sumBy';
import { mapGetters } from 'vuex';
import AudienceTypeTooltip from '../global/AudienceTypeTooltip.vue';
import AttributionLegend from '../global/AttributionLegend.vue';
import AttributionPicker from '../global/AttributionPicker.vue';
import moment from 'moment';

export default {
  name: 'PageAttributionCreate',
  components: {
    AttributionLegend,
    AttributionPicker,
    AudienceTypeTooltip,
  },

  data() {
    return {
      busy: false,
      targets: [],
      destinations: [],
      showDsp: this.$store.state.user.orgDetails.attribution_overrides,
      limitWarningOffset: 0.9,
    };
  },

  computed: {
    ...mapGetters('settings', [
      'dspMatchPercent',
      'uniqueServedPercent',
      'rangeStart',
      'destinationDeviceLimit',
      'targetDeviceLimit',
      'targetGeoframeLimit',
      'destinationGeoframeLimit',
    ]),
    ...mapGetters('user', ['hasPermission', 'preferences', 'userId']),

    campaignDevices() {
      return _sumBy(this.targets, i => i.count || i.publish_count);
    },
    destinationDevices() {
      return _sumBy(this.destinations, 'count');
    },
    campaignGeoframes() {
      return _sumBy(this.targets, i => i.geo_count);
    },
    destinationGeoframes() {
      return _sumBy(this.destinations, 'geo_count');
    },
    historical() {
      return (
        this.targets.some(t => t.historical) ||
        this.destinations.some(t => t.historical)
      );
    },
    exceedTargetDeviceLimit() {
      return this.campaignDevices > this.targetDeviceLimit;
    },
    exceedDestinationLimit() {
      return this.destinationDevices > this.destinationDeviceLimit;
    },
    exceedGeoframeTargetLimit() {
      return this.campaignGeoframes > this.targetGeoframeLimit;
    },
    exceedGeoframeDestinationLimit() {
      return this.destinationGeoframes > this.destinationGeoframeLimit;
    },
    outdatedDestinations() {
      return this.destinations.some(d => moment(d.start_date).isBefore(this.rangeStart) && !d.historical);
    },
    outdatedTargets() {
      return this.targets.some(t => moment(t.start_date).isBefore(this.rangeStart) && !t.historical);
    },
    outdated() {
      return this.outdatedTargets || this.outdatedDestinations;
    },
    pageTitle() {
      if (this.$route.params.id) {
        return 'Clone Attribution Report';
      }
      return 'Create Attribution Report';
    },
  },

  created() {
  },

  mounted() {

  },

  methods: {
    getItemName(item) {
      return item.targetType !== 'publication'
        ? item.name
        : item.parent.name +
            ` (${item.publish_partner.partner.name}` +
            (item.extension ? ', extended)' : ')');
    },
    isOutdated(row) {
      if (row && row.start_date) {
        return moment(row.start_date).isBefore(this.rangeStart);
      }
      return false;
    },

    handleChooseTarget(items) {
      for (const { item, type } of items) {
        if (this.exceedTargetDeviceLimit) {
          return this.$alert(`Target devices exceeded. Maximum number of target devices is ${this.targetDeviceLimit}`);
        }
        if (this.exceedGeoframeTargetLimit) {
          return this.$alert(`Target geoframes exceeded. Maximum number of target geoframes is ${this.targetGeoframeLimit}.`);
        }
        this.targets.push({
          ...item,
          targetType: type,
        });
        if (this.campaignDevices > this.targetDeviceLimit * this.limitWarningOffset) {
          if (this.exceedTargetDeviceLimit) {
            this.$alert(`Target devices exceeded. Maximum number of target devices is ${this.targetDeviceLimit}`);
          } else {
            this.$notify.warning({
              title: 'Approaching Limit Warning',
              message: `You are approaching the maximum number of target devices of ${this.targetDeviceLimit}.`,
            });
          }
        }
        if (this.campaignGeoframes > this.targetGeoframeLimit * this.limitWarningOffset) {
          if (this.exceedGeoframeTargetLimit) {
            this.$alert(`Target geoframes exceeded. Maximum number of target geoframes is ${this.targetGeoframeLimit}.`);
          } else {
            this.$notify.warning({
              title: 'Approaching Limit Warning',
              message: `You are approaching the maximum number of target geoframes of ${this.targetGeoframeLimit}.`,
            });
          }
        }
      }
    },

    removeAudience(index, property) {
      this[property].splice(index, 1);
      if (this.campaignDevices < this.targetDeviceLimit * this.limitWarningOffset && this.targetLimitWarning === true) {
        this.toggleTargetLimitWarning();
      }
      if (this.destinationDevices < this.destinationDeviceLimit * this.limitWarningOffset && this.destinationLimitWarning === true) {
        this.toggleDestinationLimitWarning();
      }
    },

    async submit() {
      if (this.historical && !this.organization.historical_access) {
        return this.$notify.error({
          title: 'Date Range Error',
          message: `The selected organization is not configured to access dates prior to ${this.rangeStart.format(
            'YYYY-MM-DD',
          )}.`,
        });
      }

      if (this.exceedDestinationLimit || this.exceedTargetDeviceLimit || this.exceedGeoframeDestinationLimit || this.exceedGeoframeTargetLimit) {
        return this.$alert(
          'Maximum audience devices or geoframe limits exceeded. Reduce to submit report.',
        );
      }

      const data = {
        targets: {
          audiences: this.targets
            .filter(t => t.targetType === 'audience')
            .map(t => t.id),
          publications: this.targets
            .filter(t => t.targetType === 'publication')
            .map(t => t.id),
        },
      };
      this.$emit('submit', data);
    },
  },
};
</script>
<style lang="scss">
.stats {
  display: inline-block;
  background: #ededed;
  padding: 12px;
  font-size: 14px;
  border-radius: 8px;
  margin-bottom: 20px;
  span {
    display: block;
    text-align: left;
  }
}

.form-control {
  margin: 14px 0;
  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
    display: block;
  }
  .checkboxes {
    margin-bottom: 1.2em;
  }

  .el-table {
    width: 100%;
    max-width: 1000px;
  }

  em {
    font-weight: bold;
    color: #dc7733;
    font-style: normal;
  }
}

.exceed-warning {
  font-size: 12px;
  color: red;
}

.geo-stats {
  margin-left: .5em;
}

.outdated-tag {
  font-size: 10px;
  padding: 0 2px;
  height: 16px;
  line-height: 15px;
}

.attr-picker {
  margin-top: 1em;
  max-width: 1000px;
}

.outdated-warning {
  font-size: .8em;
  margin: 1em 0;
  padding: .8em;
  background: #fecccc;
  width: 510px;
  border-radius: 3px;
  color: #550000;
  border: 1px solid #cc0000;
}
</style>
