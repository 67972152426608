<template>
  <div class="container">
    <h2 :style="{ color: 'rgb(117, 117, 117)' }">
      {{ title }}
    </h2>
    <el-table
      :data="pagedTableData"
      style="width: 100%"
      :height="height"
    >
      <el-table-column
        prop="attribute"
        label="Attribute"
      />
      <el-table-column
        label="Head Count"
        width="180"
      >
        <template slot-scope="{ row }">
          <el-progress
            :percentage="calcPercentage(row.headcount)"
            :show-text="false"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="showPagination"
      layout="prev, pager, next"
      :total="data.length"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      @current-change="handlePageChange"
    />
  </div>
</template>

<script>
export default {
  name: 'ProgressTable',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Progress Table',
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: '550px',
    },
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    maxHeadcount() {
      return Math.max(...this.data.map((item) => item.headcount));
    },
    pagedTableData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.data.slice(start, end);
    },
  },
  methods: {
    calcPercentage(headcount) {
      return (headcount / this.maxHeadcount) * 100;
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100% !important;
  max-height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 !important;

  .el-table {
    height: 80% !important;
    max-height: 80% !important;
  }

  .el-pagination {
    background-color: white;
    max-height: 20%;
  }
}

h2 {
  margin: 8px 12px;
  font-size: 16px;
  text-align: center;
}
</style>
