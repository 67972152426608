<template>
  <div class="help">
    <h4>Note:</h4>
    <p>
      If you do not see your audience listed here, check the spelling of
      the audience or the audience itself may be out of date range. If out
      of range, it will not display.
    </p>
    <h5>Legend:</h5>
    <p>
      <small>
        <div
          v-for="item in items"
          :key="item.type"
        >
          <audience-type-tooltip :type="item.type" />
          {{ item.label }}
          <span v-if="item.extra">
            - {{ item.extra }}
          </span>
        </div>
        <div v-if="stage === 'target' && !recur">
          <i class="fa fa-fw fa-paper-plane" /> Publication
        </div>
      </small>
    </p>
  </div>
</template>

<script>

import AudienceTypeTooltip from '@/components/global/AudienceTypeTooltip';
import * as Constants from '@/constants';

import { audienceTypes, isDestinationCompatible, isDeviceAudience } from '@/helpers';

const { audienceTypes: types } = Constants;

export default {
  components: {
    AudienceTypeTooltip,
  },
  props: {
    recur: {
      type: Boolean,
      default: false,
    },
    stage: {
      type: String,
      default: 'target',
    },
  },

  computed: {
    items() {
      const items = types.map(type => {
        return {
          type,
          label: audienceTypes(type),
          extra: (isDeviceAudience(type) && !isDestinationCompatible(type)) ? 'No dates associated, not applicable other than for target audiences' : null,
        };
      });

      if (this.stage === 'destination') {
        return items.filter(i => !isDeviceAudience(i.type) || isDestinationCompatible(i.type));
      }

      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
  .help {
    font-size: 0.8em;
    max-width: 472px;
    background: #fafaed;
    padding: 0 0.5em;
    margin: 1em 0;
    border-radius: 10px;
    border: 1px solid #eea;
    color: #666;
    small > div {
      margin-bottom: .5em;
    }
  }
</style>
