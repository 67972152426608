<template>
  <div>
    <el-button
      type="info"
      @click="$router.push({ path: '/attribution-reports/scheduled/create' })"
    >
      <i class="fa fa-plus" /> Add New Schedule
    </el-button>
    <data-table
      ref="dataTable"
      :key="forceRender"
      :actions="dataTable.actions"
      :data-class="attribution"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      disable-archive
      :page-size="dataTable.pageSize"
      permission-suffix="audiences"
      :row-class-name="tableRowClassName"
      disable-checkboxes
      @action="handleAction"
      @data-fetch="handleDataFetch"
    >
      <!-- <el-table-column type="expand">
        <template slot-scope="{ row }">
          <attribution-schedule-detail :attribution-schedule-id="row.id" />
        </template>
      </el-table-column> -->
      <el-table-column
        prop="name"
        label="Name"
        sortable="custom"
      />
      <el-table-column
        label="Last Run"
        :width="110"
      >
        <template slot-scope="{ row }">
          {{ row.__meta__.last_run | dateString }}
        </template>
      </el-table-column>
      <el-table-column
        label="Remaining"
        :width="110"
      >
        <template slot-scope="{ row }">
          {{ row.__meta__.items_remaining | numeral }}
        </template>
      </el-table-column>
      <el-table-column
        label="Next Run"
        :width="110"
      >
        <template slot-scope="{ row }">
          {{ row.__meta__.next_run | dateString }}
        </template>
      </el-table-column>
      <el-table-column
        prop="organization"
        label="Organization"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.organization.name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="Created"
        sortable="custom"
        :width="110"
      >
        <template slot-scope="{ row }">
          {{ row.created_at | dateString }}
        </template>
      </el-table-column>
      <el-table-column label="Author">
        <template slot-scope="{ row }">
          {{ row.author | fullName }}
        </template>
      </el-table-column>
    </data-table>
    <el-dialog
      v-if="currentExtendSchedule"
      title="Extend Schedule"
      :visible.sync="extendScheduleDialogVisible"
      width="30%"
      :before-close="handleCloseExtendSchedule"
    >
      <span>Extend Schedule for: </span>
      <el-select
        v-model="selectedExtendScheduleValue"
        placeholder="Select"
      >
        <el-option
          v-for="item in extendScheduleOptions[extendFrequencyValue]"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleCloseExtendSchedule">Cancel</el-button>
        <el-button
          :disabled="!selectedExtendScheduleValue"
          :loading="extendScheduleLoading"
          type="primary"
          @click="handleConfirmExtendSchedule"
        >Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _flattenDeep from 'lodash/flattenDeep';
import { mapGetters, mapState } from 'vuex';
import { attributionSchedule as attributionApi } from '@/adonis-api';
// import AttributionScheduleDetail from './AttributionScheduleDetail';
import DataTable from '../global/DataTable.vue';
import { getAttributionScheduleMenu } from '@/menus';
import jobWatcher from '@/mixins/job-watcher';
import moment from 'moment';
import { rangeStart } from '../../store/modules/settings';
import { isEmpty } from '../../helpers';
// import EditDialog from '../global/EditDialog.vue';
// import TagPicker from '../global/TagPicker.vue';
// import OrgPicker from '../global/OrgPicker.vue';
// import AttributionDialogPostCampaign from './AttributionDialogPostCampaign.vue';

export default {
  components: {
    // AttributionScheduleDetail,
    DataTable,
    // EditDialog,
    // OrgPicker,
    // TagPicker,
    // AttributionDialogPostCampaign,
  },
  mixins: [jobWatcher],

  data() {
    return {
      forceRender: 0,
      dataTable: Object.freeze({
        actions: getAttributionScheduleMenu(this),
        order: 'desc',
        pageSize: this.$store.state.settings.options['pagination:pageSize'],
        sortBy: 'created_at',
      }),
      extendScheduleDialogVisible: false,
      currentExtendSchedule: null,
      extendScheduleOptions: {
        month: new Array(12).fill('').map((_, index) => ({
          value: index + 1,
          label: (index + 1) + ' month' + (!index ? '' : 's'),
        })),
        week: new Array(20).fill('').map((_, index) => ({
          value: index + 1,
          label: (index + 1) + ' week' + (!index ? '' : 's'),
        })),
      },
      selectedExtendScheduleValue: null,
      extendScheduleLoading: false,
    };
  },

  computed: {
    ...mapGetters('user', ['isInternal', 'hasPermission']),
    ...mapState('attribution', ['chosenAttribution', 'dialog']),
    ...mapGetters('settings', ['rangeStart']),
    extendFrequencyValue() {
      const [value, name] = this.currentExtendSchedule?.config?.frequency ?? [];

      if (!name) return 'null';
      if (value == 7 && name === 'days') return 'week';

      return name;
    },
  },

  created() {
    this.attribution = attributionApi;
  },

  methods: {
    handleDialogClose() {
      this.$store.dispatch('attribution/closeDialog');
    },

    async handleSave(data) {
      this.showEditNameDialog = false;
      try {
        await attributionApi.update(
          this.selectedReport.id,
          { ...data },
        ).then(response => {
          const that = this;
          if (response.status === 'success') {
            this.$message({
              message: 'Successfully updated report name..',
              onClose: function() {
                that.$refs.dataTable.updateItemById(data.id, response.data.attribution_report);
                that.forceRender++;
              },
              duration: 1000,
            });
          } else {
            throw new Error('Bad response while editing attribution report name..');
          }
        });
      } catch (e) {
        this.$reportError(e);
        this.$notify.error({
          message:
                'There was a problem editing the report name.',
          duration: 1000,
        });
        this.forceRender++;
      }
    },

    async handleAction(command, schedule) {
      switch (command) {
        case 'deleteSchedule':
          try {
            await attributionApi
              .archive(schedule.id)
              .then(response => {
                if (response.status === 'success') {
                  this.$message.info({
                    message: `${schedule.name} has been deleted.`,
                    duration: 3000,
                  });
                }
              });
          } catch (e) {
            this.$reportError(e);
            this.$notify.error({
              message: 'There was a problem deleting the schedule.',
              duration: 1000,
            });
            this.forceRender++;
          } finally {
            this.$refs.dataTable.clearSelection();
            this.$refs.dataTable.invokeQuery();
          }
          break;
        case 'cancelSchedule':
          try {
            await attributionApi
              .delete(schedule.id)
              .then(response => {
                if (response.status === 'success') {
                  this.$message.info({
                    message: response.message,
                    duration: 3000,
                  });
                }
              });
          } catch (e) {
            this.$reportError(e);
            this.$notify.error({
              message: 'There was a problem cancelling the remaining schedule items.',
              duration: 1000,
            });
            this.forceRender++;
          } finally {
            this.$refs.dataTable.clearSelection();
            this.$refs.dataTable.invokeQuery();
          }
          break;
        case 'extendSchedule':
          this.extendScheduleDialogVisible = true;
          this.currentExtendSchedule = schedule;
          break;
        default:
          break;
      }
    },

    handleDataFetch(reports) {
      const jobIds = _flattenDeep(reports.map(report => report.job_ids)).filter(
        id => id,
      );
      this.setWatchedJobs(jobIds);
    },

    tableRowClassName({ row }) {
      if (+row.__meta__.items_remaining < 1) {
        return 'expired';
      }

      if (+row.__meta__.items_remaining < 2) {
        return 'ending-soon';
      }
    },

    validDateRange(item) {
      let earliest = [
        ...item.source_audiences.map(i => i.start_date),
        ...item.destination_audiences.map(i => i.start_date),
      ];

      if (earliest.length > 0) {
        earliest = earliest.reduce((previous, current) => previous > current ? current : previous);
      }

      let latest = [
        ...(item.source_audiences.map(i => i.end_date) || [null]),
        ...(item.destination_audiences.map(i => i.end_date) || [null]),
      ];

      if (latest.length > 0) {
        latest = latest.reduce((previous, current) => previous > current ? previous : current);
      }

      if ((earliest !== null && moment(earliest, 'YYYY-MM-DD').isBefore(rangeStart)) || (latest !== null && moment(latest, 'YYYY-MM-DD').isBefore(rangeStart))) {
        return false;
      } else if ((Array.isArray(earliest) && isEmpty(earliest)) || (Array.isArray(latest) && isEmpty(latest))) {
        return false;
      }

      return true;
    },

    handleCloseExtendSchedule() {
      this.extendScheduleDialogVisible = false;
      this.currentExtendSchedule = null;
      this.selectedExtendScheduleValue = null;
    },
    async handleConfirmExtendSchedule() {
      try {
        this.extendScheduleLoading = true;
        const res = await attributionApi.extendSchedule(this.currentExtendSchedule.id, this.selectedExtendScheduleValue);

        // Update Remaining column
        this.$refs.dataTable.items = this.$refs.dataTable.items.map((i) =>
          i.id === this.currentExtendSchedule.id
            ? {
              ...i,
              __meta__: {
                ...i.__meta__,
                items_remaining:
                  Number(i.__meta__.items_remaining) + res.data.items?.length,
              },
            }
            : i,
        );
      } catch (error) {
        this.$reportError(error);
        this.$notify.error({
          message:
                'There was a problem while extending schedule.',
          duration: 1000,
        });
      } finally {
        this.extendScheduleLoading = false;
        this.handleCloseExtendSchedule();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: inline-block;
  margin: 16px 0;
}

.search-box {
  max-width: 180px;
}

.small-text {
  font-size: 0.75em;
}

.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin-left: 4px;
}

.controls {
  align-items: center;
  display: flex;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}

.dot-legend {
  margin-top: 1em;
}

:deep( .el-table__row) {
  &.ending-soon,
  &.ending-soon.el-table__row--striped td {
    background: #fff1c4 !important;
  }

  &.expired,
  &.expired.el-table__row--striped td {

    background: #fbe6eb !important;
  }
}
</style>
