<template>
  <div class="container">
    <!-- BANNER -->
    <div
      class="banner"
      :class="orientation"
      :style="bannerStyle"
    />

    <div class="content-wrapper">
      <!-- Page Title and Description -->
      <p
        v-if="reportData.reportconfigs.finalPageOptions"
        class="description"
      >
        {{ reportData.reportconfigs.finalPageOptions.conclusionText }}
      </p>

      <!-- Footer with Text and Logo -->
      <div class="logo-description">
        <img
          :src="logoURL"
          alt="Logo"
          class="logo"
          @error="setDefaultImage"
        >
      </div>

      <span
        class="title"
        :class="orientation"
      >
        {{ reportData.reportconfigs.titlePageOptions.pdfTitle }}
      </span>

      <div class="footer">
        <div
          v-if="reportData.reportconfigs.titlePageOptions.pdfTitle"
          class="footer-item"
        />
        <div class="footer-item">
          <a
            :href="reportData.reportconfigs.titlePageOptions.url"
            :class="orientation"
          >
            {{ reportData.reportconfigs.titlePageOptions.url }}
          </a>
        </div>
        <div
          v-if="
            reportData.reportconfigs.finalPageOptions &&
              reportData.reportconfigs.finalPageOptions.contactEmail
          "
          class="footer-item"
        >
          <a
            :href="
              'mailto:' + reportData.reportconfigs.finalPageOptions.contactEmail
            "
          >
            {{ reportData.reportconfigs.finalPageOptions.contactEmail }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttributionSummary',
  props: {
    orientation: {
      type: String,
      required: true,
      validator: (value) => ['landscape', 'portrait'].includes(value),
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bannerStyle() {
      const defaultBannerUrl =
        'https://osd-v2-backend-development.s3.amazonaws.com/public/attribution-logos/busytrafficbanner.png';
      const bannerUrl = this.reportData.banner_url || defaultBannerUrl;
      return {
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.3)), url(${bannerUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        height: this.orientation === 'landscape' ? '40vh' : '60vh', // Dynamic height based on orientation
        color: 'white', // Ensuring text is visible on dark backgrounds
      };
    },
    logoURL() {
      return this.reportData.logoURL || '/images/onspot-logo.svg';
    },
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-wrapper {
  height: 100%;
  padding: 24px;
}

.description {
  min-height: fit-content;
  margin-bottom: 3rem;
}

.logo-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  max-width: 50vh;
  margin: auto;

  .logo {
    max-width: 100%;
  }
}

.title {
  display: block;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

@media screen and (min-width: 1366px) {
  .banner {
    height: 100% !important;
  }
}
</style>
