import { Line, mixins } from 'vue-chartjs';

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['chartData'],
  mounted() {
    // Extract the labels (date_day) and visits data
    const labels = this.chartData.digitalVisitsPerDay.map(item => new Date(item.date_day).toLocaleDateString());
    const visitsData = this.chartData.digitalVisitsPerDay.map(item => item.visits);

    const datasets = [
      {
        label: 'Visits', // Add a label
        data: visitsData,
        borderColor: 'rgba(43, 116, 184, 1)',
        borderWidth: 1,
        fill: false,
        tension: 0,
        pointBackgroundColor: 'rgba(43, 116, 184, 1)', // Add point background color
        pointBorderColor: 'rgba(43, 116, 184, 1)', // Add point border color
      },
    ];

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 1000,
        easing: 'easeInOutQuad',
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false, // Hide grid lines
          },
          ticks: {
            fontColor: 'rgba(0,0,0,0.7)',
          },
        }],
        yAxes: [{
          gridLines: {
            display: false, // Hide grid lines
          },
          ticks: {
            fontColor: 'rgba(0,0,0,0.7)',
            autoSkip: true,
            maxTicksLimit: 5,
          },
        }],
      },
      legend: {
        display: false,
        position: 'top', // Position it above the chart
        labels: {
          fontSize: 24, // Increase the font size
          usePointStyle: true, // Set point style to circle
          boxWidth: 20, // Set box width for better alignment
          padding: 20, // Add padding for better positioning
        },
      },
    };

    this.renderChart({ labels, datasets }, options);
  },
};
