<template>
  <div class="main-container">
    <div class="header">
      <h1>Responders Profile</h1>
      <p
        v-if="
          reportData.reportconfigs.respondersOptions
            .attribution_responders_description
        "
      >
        {{
          reportData.reportconfigs.respondersOptions
            .attribution_responders_description
        }}
      </p>
    </div>

    <div class="content-container">
      <div class="chart-wrapper">
        <div class="box">
          <div class="title">
            Age
          </div>
          <responders-bar-chart
            :chart-data="reportData.reportData.age"
            :horizontal="false"
            style="height: 80%"
          />
        </div>
        <div class="box">
          <gender-chart
            v-if="reportData.reportData.gender"
            id="responders"
            :gender="reportData.reportData.gender"
            style="height: 80%"
          />
        </div>
        <div class="box">
          <div class="title">
            Marital Status
          </div>
          <responder-doughnut-chart
            :chart-data="reportData.reportData.maritalStatus"
            style="height: 80%"
          />
        </div>
        <div class="box">
          <div class="title">
            Education
          </div>
          <responder-pie-chart
            :chart-data="reportData.reportData.education"
            style="height: 80%"
          />
        </div>
        <div class="box">
          <div class="title">
            Household Income Distribution
          </div>
          <responder-horizontal-bar
            :household-income="reportData.reportData.householdIncome"
            style="height: 80%"
          />
        </div>
        <div class="box">
          <div class="title">
            Children at Home
          </div>
          <responder-doughnut-chart
            :chart-data="reportData.reportData.presenceOfChildren"
            style="height: 80%"
          />
        </div>
      </div>

      <footer-section :logo-u-r-l="logoURL" />
    </div>
  </div>
</template>

<script>
import RespondersBarChart from '../../report-components/RespondersBarChart';
import GenderChart from '../../report-components/GenderChart.vue';
import ResponderDoughnutChart from '../../report-components/ResponderDoughnutChart';
import ResponderPieChart from '../../report-components/ResponderPieChart';
import ResponderHorizontalBar from '../../report-components/ResponderHorizontalBar';
import FooterSection from '../FooterSection.vue';
export default {
  name: 'ResponsesProfile',
  components: {
    RespondersBarChart,
    GenderChart,
    ResponderDoughnutChart,
    ResponderPieChart,
    ResponderHorizontalBar,
    FooterSection,
  },
  props: {
    orientation: {
      type: String,
      required: true,
      validator: (value) => ['landscape', 'portrait'].includes(value),
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    displayData() {
      return {};
    },

    logoURL() {
      return this.reportData.logoURL || '/images/onspot-logo.svg';
    },
  },

  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  max-height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    height: 10vh;
    margin: 12px;

    h1,
    p {
      margin: 0;
    }
  }

  .content-container {
    height: 80vh;
    width: 95%;
    margin: auto;
    background-color: #f5f5f5;
    margin: 12px;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;

    .chart-wrapper {
      height: 85%;
      width: 100%;
      display: grid;
      row-gap: 35px;
      column-gap: 12px;
      grid-template-columns: 32.5% 32.5% 32.5%;
      grid-template-rows: 45% 45%;
      justify-content: center;

      .box {
        background-color: white;
        padding: 12px;

        .title {
          font-size: 24px;
          margin-bottom: 10px;
          text-align: center;
        }
      }
    }
  }
}
</style>
