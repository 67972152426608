<template>
  <div class="footer-section">
    <img
      :src="logo"
      alt="Logo"
      class="logo"
      @error="setDefaultImage"
    >
  </div>
</template>

<script>
export default {
  name: 'AttributionFooterSection',
  props: {
    logoURL: {
      type: String,
      required: true,
    },
  },
  computed: {
    logo() {
      return this.logoURL || '/images/onspot-logo.svg';
    },
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>

<style scoped>
.footer-section {
  max-height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.logo {
  max-height: 65px;
  max-width: 10%;
  object-fit: contain;
  object-position: center;
}

/* Using ::v-deep for increased specificity */
::v-deep .footer-section {
  justify-content: flex-end !important;
}

::v-deep .logo {
  margin-left: auto !important;
}
</style>
