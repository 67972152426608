var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footfall-container"},[_c('div',{staticClass:"header"},[_c('h1',[_vm._v("Footfall Attribution")]),(
        _vm.reportData.reportconfigs.footfallAttributionOptions
          .attribution_footfall_description
      )?_c('p',[_vm._v(" "+_vm._s(_vm.reportData.reportconfigs.footfallAttributionOptions .attribution_footfall_description)+" ")]):_vm._e()]),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"first-row"},[_c('div',{staticClass:"cell-wrapper"},[(
            _vm.reportData.reportconfigs.footfallAttributionOptions
              .footfallUniqueResponders
          )?_c('div',{staticClass:"cell"},[_c('p',{staticClass:"number"},[_vm._v(" "+_vm._s(Number( _vm.reportData.reportData.footfallUniqueResponders[0] .response_uniques ).toLocaleString() || 0)+" ")]),_c('p',{staticClass:"description"},[_vm._v(" Footfall Unique Responders ")])]):_vm._e(),(
            _vm.reportData.reportconfigs.footfallAttributionOptions
              .footfallTotalVisits
          )?_c('div',{staticClass:"cell"},[_c('p',{staticClass:"number"},[_vm._v(" "+_vm._s(Number( _vm.reportData.reportData.footfallTotalVisits[0].sum ).toLocaleString() || 0)+" ")]),_c('p',{staticClass:"description"},[_vm._v(" Footfall Total Visits ")])]):_vm._e()]),(
          _vm.reportData.reportconfigs.footfallAttributionOptions.visitsByWeekday
            .enabled
        )?_c('div',{staticClass:"chart-wrapper"},[_vm._m(0),_c('line-chart',{staticClass:"line-chart-container",attrs:{"chart-data":_vm.reportData.reportData,"labels":_vm.reportData.reportconfigs.footfallAttributionOptions
              .visitsByWeekday.days}})],1):_vm._e()]),_c('div',{staticClass:"second-row"},[(
          _vm.reportData.reportconfigs.footfallAttributionOptions
            .FootfallResponsesTarget
        )?_c('div',{staticClass:"table"},[_c('name-and-responses',{attrs:{"orientation":_vm.orientation,"items":_vm.reportData.reportData.footfallResponsesByAudience,"title":"Footfall Responses By Target Audience"}})],1):_vm._e(),(
          _vm.reportData.reportconfigs.footfallAttributionOptions
            .FootfallResponsesDestination
        )?_c('div',{staticClass:"table"},[_c('name-and-responses',{attrs:{"orientation":_vm.orientation,"items":_vm.reportData.reportData.responsesByAudienceDestinations,"title":"Footfall Responses By Destination Location","responses-key":"Responses"}})],1):_vm._e()]),_c('footer-section',{attrs:{"logo-u-r-l":_vm.logoURL}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend-wrapper"},[_c('h1',{staticClass:"title"},[_vm._v(" Visits by Weekday ")]),_c('div',{staticClass:"legend-container"},[_c('div',{staticClass:"legend-item"},[_c('div',{staticClass:"circle blue"}),_c('span',[_vm._v("Initial")])]),_c('div',{staticClass:"legend-item",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"circle yellow"}),_c('span',[_vm._v("Repeat")])])])])
}]

export { render, staticRenderFns }