<template>
  <div class="container">
    <div class="header">
      <img
        :src="logoURL"
        alt="Logo"
        class="logo"
        @error="setDefaultImage"
      >
      <div
        v-if="reportData.reportconfigs.titlePageOptions.pdfTitle"
        class="title"
      >
        {{ reportData.reportconfigs.titlePageOptions.pdfTitle }}
      </div>
    </div>
    <div class="footer">
      <a
        :href="reportData.reportconfigs.titlePageOptions.url"
        class="url"
        style="margin-bottom: 25px"
      >
        {{ reportData.reportconfigs.titlePageOptions.url }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitlePage',
  props: {
    reportData: {
      type: Object,
      required: true,
    },
    orientation: {
      type: String,
      required: true,
      validator: (value) => ['landscape', 'portrait'].includes(value),
    },
  },
  computed: {
    logoURL() {
      return this.reportData.logoURL || '/images/onspot-logo.svg';
    },
    headerBottomMargin() {
      return this.orientation === 'landscape' ? '300px' : '600px';
    },
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .header {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    max-height: 50vh;
    max-width: 50vw;

    img {
      max-width: 35%;
    }

    .title {
      font-size: 40px;
      font-weight: bold;
      text-align: center;
    }
  }

  .footer {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .header {
      img {
        width: 45%;
      }
      .title {
        font-size: 35px;
      }
    }
  }
}
</style>
