<template>
  <el-container style="height: 100%;">
    <div
      :id="mapId"
      style="display: none"
    >
      <!-- Expose the stringified JSON of your map configurations -->
      {{ mapConfigJSON }}
    </div>
    <el-row
      :ref="map.id"
      :style="{ height: mapHeight }"
      class="map-container"
    >
      <div
        :id="map.id"
        class="map"
      />
    </el-row>
  </el-container>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import { Row, Container } from 'element-ui';

export default {
  components: {
    'el-row': Row,
    'el-container': Container,
  },
  props: {
    idMap: {
      type: Number,
      default: 0,
    },
    height: {
      type: String,
      default: '700px',
    },
    mapsConfig: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    boundaryStyle: {
      type: String,
      default: 'shaded',
    },
  },
  data() {
    return {
      maps: {},
    };
  },
  computed: {
    mapHeight() {
      return this.height; // Returning the height prop
    },
    mapId() {
      return `mapConfig-${this.idMap}`;
    },
    mapConfigJSON() {
      const combinedConfig = {
        ...this.map,
        ...this.mapsConfig,
      };

      // If boundary data exists, add it to the combined config
      if (this.mapsConfig.boundary) {
        combinedConfig.boundary = this.mapsConfig.boundary;
      }

      return JSON.stringify(combinedConfig);
    },
  },
  watch: {
    mapsConfig: {
      deep: true,
      handler: 'initMaps',
    },
  },
  mounted() {
    // Check if mapsConfig is not an object and throw an error if not
    if (typeof this.mapsConfig !== 'object' || Array.isArray(this.mapsConfig)) {
      throw new Error('Maps config must be an object.');
    }

    // Check if mapsConfig is empty
    if (Object.keys(this.mapsConfig).length === 0) {
      throw new Error('Maps config is undefined or empty.');
    }

    this.initMaps();
  },
  methods: {
    combineMapsAndConfig() {
      this.combinedMaps = { ...this.map, ...this.mapsConfig };
    },
    initMaps() {
      const mapConfig = this.mapsConfig;
      const mapContainer = this.$refs[this.map.id].$el;
      const map = new mapboxgl.Map({
        container: mapContainer,
        style: this.map.style || 'mapbox://styles/mapbox/streets-v11',
        center: mapConfig.center || [0, 0],
        zoom: this.map.zoom || 1,
        maxZoom: this.map.maxZoom || 20, // Set max zoom here
        preserveDrawingBuffer: true,
        accessToken: this.map.accessToken,
      });

      map.on('load', () => {
        map.resize();

        if (mapConfig.boundary) {
          map.addSource('highlighted-area', {
            type: 'geojson',
            data: mapConfig.boundary,
          });

          const boundaryStyle = this.boundaryStyle;

          if (boundaryStyle === 'shaded') {
            map.addLayer({
              id: 'highlighted-area-layer',
              type: 'fill',
              source: 'highlighted-area',
              layout: {},
              paint: {
                'fill-color': '#0080ff',
                'fill-opacity': 0.5,
              },
            });
          } else if (boundaryStyle === 'dotted') {
            map.addLayer({
              id: 'highlighted-area-layer',
              type: 'line',
              source: 'highlighted-area',
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': '#ff0000',
                'line-dasharray': [2, 2],
              },
            });
          }
        }
      });

      this.maps[mapConfig.id] = map;
    },
  },
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100%;
}

.map {
  width: 100%;
  height: 100%;
}
</style>
