<template>
  <div v-loading="busy">
    <h3>Recurring Attribution Report Schedule</h3>
    <div class="stats">
      <span class="campaign">
        Total Target Devices: {{ campaignDevices | numeral }}
      </span>
      <span class="campaign">
        Total Destination Devices: {{ destinationDevices | numeral }}
      </span>
    </div>

    <div class="stats geo-stats">
      <span class="campaign">
        Total Target Geoframes: {{ campaignGeoframes | numeral | handleNull }}
      </span>
      <span class="campaign">
        Total Destination Geoframes: {{ destinationGeoframes | numeral | handleNull }}
      </span>
    </div>

    <div class="config-wrapper">
      <div class="config">
        <el-switch
          v-model="weekly"
          active-text="Weekly"
          inactive-text="Monthly"
        />
      </div>
      <div
        class="config"
      >
        <el-switch
          v-model="rollSources"
          active-text="Roll Target Dates Forward"
        />
        <help-tip
          style="margin-left:.5em"
          height="40px"
        >
          If enabled, the target date range will be rolled forward with each scheduled report.
        </help-tip>
      </div>

      <div
        v-if="!weekly"
        class="config"
      >
        <el-form inline>
          <el-form-item label="Recur for">
            <el-select
              v-model="duration"
              placeholder="Select duration"
            >
              <el-option
                v-for="i in recurRange"
                :key="i"
                :label="`${i} Month${ i !== 1 ? 's' : ''}`"
                :value="i"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div
        v-else
        class="config"
      >
        <el-date-picker
          v-model="weeklyStartDate"
          type="date"
          placeholder="First Report End Date"
          :picker-options="datePickerOptions"
          format="M/d/yyyy"
        />
        <help-tip
          style="margin-left:.5em"
          height="40px"
        >
          Selected date is the end date of the 7 day destination audience for the first scheduled report.
          Ex. Selecting 4/18/2022 will generate a report for 4/12/2022 - 4/18/2022
        </help-tip>
      </div>
      <div
        v-if="weekly"
        class="config"
      >
        <el-form inline>
          <el-form-item label="Recur for">
            <el-select
              v-model="durationWeekly"
              placeholder="Select duration"
            >
              <el-option
                v-for="i in recurRangeWeekly"
                :key="i"
                :label="`${i} Week${ i !== 1 ? 's' : ''}`"
                :value="i"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-steps :active="activeStep">
      <el-step
        v-for="(step, index) of steps"
        :key="index"
        :title="step.title"
        :description="step.description"
        :status="step.status"
      />
    </el-steps>

    <div v-if="activeStep === 1">
      <div class="form-control">
        <label>Select Target Audience(s)</label>
        <el-table
          v-if="targets.length > 0"
          :data="targets"
          stripe
        >
          <el-table-column
            prop="name"
            label="Target Name"
          >
            <template slot-scope="{ row }">
              <audience-type-tooltip :type="row.type" />
              &nbsp;
              <span v-if="rollSources && !isDeviceAudience(row.type)">
                <el-input
                  v-model="row.overrideName"
                  class="editable-name"
                >
                  <template slot="suffix">
                    <i
                      v-if="row.overrideName !== row.name"
                      class="fa fa-undo-alt reset"
                      @click="row.overrideName = row.name"
                    />
                    <help-tip
                      v-else
                      height="40px"
                    >
                      Date ranges will be appended to any cloned audiences, you may override the original audiences title to omit dates.
                    </help-tip>
                  </template>
                </el-input>
                <div v-if="row.overrideName !== row.name">
                  <small style="margin-left: 2.5em;">
                    Renaming <em>{{ row.name }}</em> to <em class="success">{{ row.overrideName }}</em>
                  </small>
                </div>
              </span>
              <span
                v-else
                style="margin-left:1em;"
              >
                {{ row.name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="count"
            label="Devices"
            :width="110"
          >
            <template slot-scope="{ row }">
              <span>{{ row.count | numeral }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="start_date"
            label="Start Date"
            :width="110"
          />
          <el-table-column
            prop="end_date"
            label="End Date"
            :width="110"
          />
          <el-table-column :width="60">
            <template slot-scope="{ $index }">
              <el-button
                class="remove"
                size="small"
                type="danger"
                icon="fa fa-trash"
                @click="removeAudience($index, 'targets')"
              />
            </template>
          </el-table-column>
        </el-table>

        <div class="attr-picker">
          <attribution-picker
            :selected-items="targets"
            stage="target"
            recur
            @select="handleChooseTarget"
          />
        </div>

        <attribution-legend
          stage="target"
          recur
        />

        <div
          v-if="outdatedTargets"
          class="outdated-warning"
        >
          You have outdated audiences selected, please remove or replace them, or enable target rolling before continuing.
        </div>
      </div>
    </div>

    <div v-if="activeStep === 2">
      <div class="form-control">
        <label>Select Destination Audience(s)</label>
        <el-table
          v-if="destinations.length > 0"
          :data="destinations"
          stripe
        >
          <el-table-column
            prop="name"
            label="Destination Name"
          >
            <template slot-scope="{ row }">
              <audience-type-tooltip :type="row.type" />
              &nbsp;
              <span v-if="!isDeviceAudience(row.type)">
                <el-input
                  v-model="row.overrideName"
                  class="editable-name"
                >
                  <template slot="suffix">
                    <i
                      v-if="row.overrideName !== row.name"
                      class="fa fa-undo-alt reset"
                      @click="row.overrideName = row.name"
                    />
                    <help-tip
                      v-else
                      height="40px"
                    >
                      Date ranges will be appended to any cloned audiences, you may override the original audiences title to omit dates.
                    </help-tip>
                  </template>
                </el-input>
                <div v-if="row.overrideName !== row.name">
                  <small style="margin-left: 2.5em;">
                    Renaming <em>{{ row.name }}</em> to <em class="success">{{ row.overrideName }}</em>
                  </small>
                </div>
              </span>
              <span
                v-else
                style="margin-left:1em;"
              >
                {{ row.name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="count"
            label="Devices"
            :width="110"
          >
            <template slot-scope="{ row }">
              <span>{{ row.count | numeral }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="start_date"
            label="Start Date"
            :width="110"
          />
          <el-table-column
            prop="end_date"
            label="End Date"
            :width="110"
          />
          <el-table-column :width="60">
            <template slot-scope="{ $index }">
              <el-button
                class="remove"
                size="small"
                type="danger"
                icon="fa fa-trash"
                @click="removeAudience($index, 'destinations')"
              />
            </template>
          </el-table-column>
        </el-table>

        <div class="attr-picker">
          <attribution-picker
            :selected-items="[...targets, ...destinations]"
            stage="destination"
            recur
            @select="handleChooseDestination"
          />
        </div>

        <attribution-legend
          stage="destination"
          recur
        />

        <div
          v-if="outdatedDestinations"
          class="outdated-warning"
        >
          You have outdated audiences selected, please remove or replace them before continuing.
        </div>
      </div>
    </div>

    <div v-if="showDsp && activeStep === 3">
      <div class="form-control">
        <label>DSP Matched Devices (optional)</label>
        <el-input
          v-model="dspMatches"
          :placeholder="
            `If blank, ${dspMatchPercent}% of Target devices will be used.`
          "
        />
      </div>
      <div class="form-control">
        <label>DSP Devices Served (optional)</label>
        <el-input
          v-model="uniquesServed"
          :placeholder="
            `If blank, ${uniqueServedPercent}% of DSP Matched devices will be used.`
          "
        />
      </div>
    </div>

    <div
      v-if="(showDsp && activeStep === 4) || (!showDsp && activeStep === 3)"
      style="display:flex;"
    >
      <div class="schedule-wrapper">
        <div class="form-control">
          <label>Attribution Report Title</label>
          <el-input v-model="reportTitle" />
        </div>
        <div class="form-control">
          <label>Organization</label>
          <org-picker :organization.sync="organization" />
        </div>
        <tag-picker :tags.sync="tags" />
        <div class="checkboxes">
          <el-checkbox
            v-if="canDownloadLocations"
            v-model="generateLocationReport"
            label="Generate Attribution Locations Report"
          />
          <el-checkbox
            v-if="canDownloadJourney && !fileAudiencePresent"
            v-model="generateJourneyReport"
            label="Generate Attribution Journey Report"
          />
          <el-checkbox
            v-if="canDownloadHHLocations"
            v-model="generateHHReport"
            label="Generate Household Locations Report"
          />
        </div>
        <div
          v-if="!weekly"
          class="form-control"
        >
          <el-switch
            v-model="startAsap"
            active-text="Start ASAP"
            inactive-text="Start Next Month"
          />
        </div>
        <div class="form-control">
          <el-switch
            v-model="cloneFromStart"
            active-text="Clone on first run"
            inactive-text="Clone on second run"
          />
        </div>
      </div>

      <div class="summary">
        <el-alert
          :closable="false"
          title="Attribution Report Schedule Summary"
          type="success"
        >
          Your report will begin processing <strong>{{ startText }}</strong> {{ continuingText }}<br>
          Starting with the <strong>{{ cloneStartText }}</strong> run, {{ targetCloneText }}your destination audience(s) will be cloned to the preceeding {{ weekOrMonth() }}. For example:<br><br>
          <strong>Target:</strong> {{ targetCloneExample }}<br>
          <strong>Destination:</strong> {{ destinationCloneExample }}
        </el-alert>
      </div>
    </div>

    <el-button
      v-if="activeStep > 1"
      type="info"
      :disabled="disabled('prev')"
      @click="previousStep()"
    >
      &laquo; Previous Step
    </el-button>
    <el-button
      :loading="saving"
      type="primary"
      :disabled="disabled('next')"
      @click="nextStep()"
    >
      <span v-if="activeStep < steps.length">
        Next Step &raquo;
      </span>
      <span v-if="activeStep === steps.length">
        Save &amp; Run Report &#x2713;
      </span>
    </el-button><br>
    <span
      v-if="exceedDestinationLimit"
      class="exceed-warning"
    >
      Max destination devices ({{ destinationDeviceLimit }}) exceeded.
    </span>
    <span
      v-if="exceedTargetDeviceLimit"
      class="exceed-warning"
    >
      Max target devices ({{ targetDeviceLimit }}) exceeded.
    </span>
    <span
      v-if="exceedGeoframeTargetLimit"
      class="exceed-warning"
    >
      Max target geoframes ({{ targetGeoframeLimit }}) exceeded.
    </span>
    <span
      v-if="exceedGeoframeDestinationLimit"
      class="exceed-warning"
    >
      Max destination geoframes ({{ destinationGeoframeLimit }}) exceeded.
    </span>
  </div>
</template>

<script>
import ElCheckbox from 'element-ui/lib/checkbox';
import _sumBy from 'lodash/sumBy';
import { mapGetters } from 'vuex';
import TagPicker from '../../global/TagPicker.vue';
import DatePicker from '../../global/DatePicker.vue';
import OrgPicker from '../../global/OrgPicker.vue';
import AudienceTypeTooltip from '../../global/AudienceTypeTooltip.vue';
import AttributionLegend from '../../global/AttributionLegend.vue';
import AttributionPicker from '../../global/AttributionPicker.vue';
import HelpTip from '../../global/HelpTip.vue';
import { attributionSchedule as attributionScheduleApi, user as userApi } from '@/adonis-api';
import { isDeviceAudience } from '@/helpers';
import moment from 'moment';

const _ordinal = function(number) {
  let selector;

  if (number <= 0) {
    selector = 4;
  } else if ((number > 3 && number < 21) || number % 10 > 3) {
    selector = 0;
  } else {
    selector = number % 10;
  }

  return ['th', 'st', 'nd', 'rd', ''][selector];
};

export default {
  name: 'PageRecurringAttributionCreate',
  components: {
    AttributionLegend,
    AttributionPicker,
    AudienceTypeTooltip,
    HelpTip,
    TagPicker,
    OrgPicker,
  },

  data() {
    return {
      datePickerOptions: {
        disabledDate: date => {
          return moment(date).isBefore(
            moment().subtract(1, 'w'),
          );
        },
      },
      weekly: false,
      weeklyStartDate: '',
      weeklyEndDate: '',
      activeStep: 1,
      busy: false,
      dontWarnBilling: false,
      targets: [],
      destinations: [],
      reportTitle: '',
      organization: this.$store.state.user.orgDetails,
      showDsp: this.$store.state.user.orgDetails.attribution_overrides,
      dspMatches: null,
      uniquesServed: null,
      generateLocationReport: false,
      generateJourneyReport: false,
      generateHHReport: false,
      autoComplete: '',
      tags: [],
      saving: false,
      steps: [],
      limitWarningOffset: 0.9,
      duration: 6,
      durationWeekly: 4,
      rollSources: false,
      cloneFromStart: true,
      startAsap: true,
      isDelayed: false,
    };
  },

  computed: {
    ...mapGetters('settings', [
      'dspMatchPercent',
      'uniqueServedPercent',
      'rangeStart',
      'rangeEnd',
      'destinationDeviceLimit',
      'targetDeviceLimit',
      'targetGeoframeLimit',
      'destinationGeoframeLimit',
    ]),
    ...mapGetters('user', ['hasPermission', 'preferences', 'userId']),

    canDownloadLocations() {
      return this.hasPermission('create_attribution_location_reports');
    },
    canDownloadJourney() {
      return this.hasPermission('create_attribution_journey_reports');
    },
    canDownloadHHLocations() {
      return this.hasPermission('create_attribution_hh_location_reports');
    },
    campaignDevices() {
      return _sumBy(this.targets, i => i.count);
    },
    destinationDevices() {
      return _sumBy(this.destinations, 'count');
    },
    campaignGeoframes() {
      return _sumBy(this.targets, i => i.geo_count);
    },
    destinationGeoframes() {
      return _sumBy(this.destinations, 'geo_count');
    },

    startText() {
      if (this.weekly) {
        return moment(this.delayDate(this.weeklyStartDate)).format('MMM D, YYYY');
      }
      if (this.startAsap) {
        if (moment().date() >= this.rangeEnd || !this.cloneFromStart) {
          return this.weeklyStartDate;
        }
        const dayDelay = this.rangeEnd - moment().date();

        if (dayDelay === 1) {
          return 'tomorrow';
        }

        return `in ${dayDelay} days`;
      }

      return 'on ' + moment().add(1, 'month').startOf('month').add(this.rangeEnd - 1, 'day').format('MMM D, YYYY');
    },

    continuingText() {
      if (this.weekly) {
        if (this.durationWeekly > 2) {
          return `and continue ${this.durationWeekly - 1} more time${this.durationWeekly - 1 > 1 ? 's' : ''} every seven days.`;
        } else if (this.durationWeekly > 1) {
          return `and one more time on ${moment(this.delayDate(this.weeklyStartDate)).add(1, 'week').format('MMM D, YYYY')}`;
        }
        return '';
      }
      if (this.duration > 2) {
        return `and continue ${this.duration - 1} more time${this.duration - 1 > 1 ? 's' : ''} on the 7th of each month.`;
      } else if (this.duration > 1) {
        if (this.startText === 'tomorrow' || this.startText === 'immediately') {
          return `and one more time on ${moment().add(1, 'month').startOf('month').add(6, 'day').format('MMM D, YYYY')}`;
        }
        return `and one more time on ${moment().add(2, 'month').startOf('month').add(6, 'day').format('MMM D, YYYY')}`;
      }
      return '';
    },

    cloneStartText() {
      if (this.cloneFromStart) {
        return 'first';
      }
      return 'second';
    },

    targetCloneText() {
      if (this.weekly && this.rollSources && this.targets.some(t => !isDeviceAudience(t.type))) {
        return 'your polygon-based target audience(s) will be rolled forward 1 week, and ';
      }
      if (this.rollSources && this.targets.some(t => !isDeviceAudience(t.type))) {
        return 'your polygon-based target audience(s) will be rolled forward 1 month, and ';
      }
      return '';
    },

    targetCloneExample() {
      if (this.rollSources && this.targets.some(t => !isDeviceAudience(t.type))) {
        const source = this.targets.filter(t => !isDeviceAudience(t.type))[0];
        let range = moment(source.start_date).add('1', 'month').format('MMM D YYYY') + ' - ' + moment(source.end_date).add(1, 'month').format('MMM D YYYY');
        if (this.weekly) {
          range = moment(source.start_date).add(1, 'week').format('MMM D YYYY') + ' - ' + moment(source.end_date).add(1, 'week').format('MMM D YYYY');
        }
        return `${source.overrideName} ${range}`;
      }

      return 'N/A';
    },

    destinationCloneExample() {
      let extra = this.startAsap ? 0 : 1;
      if (!this.cloneFromStart) {
        extra += 1;
      }
      let range = moment().add(extra, 'month').subtract(1, 'month').startOf('month').format('MMM D YYYY') + ' - ' + moment().add(extra, 'month').subtract(1, 'month').endOf('month').format('MMM D YYYY');
      if (this.weekly) {
        range = moment(this.weeklyStartDate).subtract(6, 'days').format('MMM D YYYY') + ' - ' + moment(this.weeklyStartDate).format('MMM D YYYY');
      }
      return `${this.destinations[0].overrideName} ${range}`;
    },

    submissionReady() {
      if (
        this.campaignDevices > 0 &&
        this.destinationDevices > 0 &&
        this.reportTitle &&
        !this.outdated
      ) {
        return true;
      }

      return false;
    },
    exceedTargetDeviceLimit() {
      return this.campaignDevices > this.targetDeviceLimit;
    },
    exceedDestinationLimit() {
      return this.destinationDevices > this.destinationDeviceLimit;
    },
    exceedGeoframeTargetLimit() {
      return this.campaignGeoframes > this.targetGeoframeLimit;
    },
    exceedGeoframeDestinationLimit() {
      return this.destinationGeoframes > this.destinationGeoframeLimit;
    },
    outdatedDestinations() {
      return this.destinations.some(d => moment(d.start_date).isBefore(this.rangeStart));
    },
    outdatedTargets() {
      return this.targets.some(t => moment(t.start_date).isBefore(this.rangeStart));
    },
    outdated() {
      return this.outdatedTargets || this.outdatedDestinations;
    },
    recurRange() {
      let max = 12;

      if (!this.rollSources) {
        const offsets = this.targets.filter(t => t.start_date).map(t => {
          const offset = moment(t.start_date).startOf('month').diff(moment(this.rangeStart).startOf('month'), 'months');
          if (offset < 1) {
            return 1;
          }
          return offset;
        });

        max = offsets.length > 0 ? Math.min.apply(Math, offsets) : 12;
      }

      return Array.from(new Array(max), (x, i) => i + 1);
    },
    recurRangeWeekly() {
      let max = 26;

      if (!this.rollSources) {
        const offsets = this.targets.filter(t => t.start_date).map(t => {
          const outOfDate = moment(t.start_date).add(1, 'year');
          const offset = outOfDate.diff(moment(), 'weeks');
          if (offset < 1) {
            return 1;
          }
          if (offset > 20) {
            return 20;
          }
          return offset;
        });

        max = offsets.length > 0 ? Math.min.apply(Math, offsets) : 20;
      }

      return Array.from(new Array(max), (x, i) => i + 1);
    },
    fileAudiencePresent() {
      if (this.targets && this.destinations) {
        return this.targets.some(audience => isDeviceAudience(audience.type)) || this.destinations.some(audience => isDeviceAudience(audience.type));
      }
      return true;
    },
  },

  watch: {
    recurRange(val) {
      if (val[val.length - 1] < this.duration) {
        this.$nextTick(() => {
          this.duration = val[val.length - 1];
        });
      }
    },
    recurRangeWeekly(val) {
      if (val[val.length - 1] < this.durationWeekly) {
        this.$nextTick(() => {
          this.durationWeekly = val[val.length - 1];
        });
      }
    },
  },

  created() {
    this.steps = [
      {
        title: 'Target Audience',
        description: 'Define your target audience',
        status: 'finish',
      },
      {
        title: 'Destination Audience',
        description: 'Define your destination audience',
        status: 'wait',
      },
    ];

    if (this.organization.attribution_overrides) {
      this.steps.push({
        title: 'Campaign Details',
        description: "Define your campaign's details",
        status: 'wait',
      });
    }

    this.steps.push({
      title: 'Schedule Report',
      description: 'Name, save and schedule your report',
      status: 'wait',
    });
  },

  mounted() {
    if (!this.$route.params.id && !this.preferences.options.disable_attrib_warn) {
      const h = this.$createElement;

      this.$msgbox({
        title: 'Attribution Report',
        // message: 'This report is a billable item. Do you wish to continue?',
        message: h('div', null, [
          h('div', null, 'This report is a billable item. Do you wish to continue?'),
          h('div', { style: 'margin-top: 1em' }, [
            h(ElCheckbox, {
              input: {
                value: this.dontWarnBilling,
              },
              on: {
                change: value => { this.dontWarnBilling = value; },
              },
            }, 'Don\'t show this again'),
          ]),
        ]),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async () => {
        if (this.dontWarnBilling) {
          await userApi.update(this.userId, {
            preferences: {
              ...this.preferences,
              options: {
                ...this.preferences.options,
                disable_attrib_warn: this.dontWarnBilling,
              },
            },
          });
        }
      }).catch(err => {
        if (err !== 'cancel') {
          this.$reportError(err);
        } else {
          this.$router.go(-1);
        }
      });
    }
  },

  methods: {
    isOutdated(row) {
      if (row && row.start_date) {
        return moment(row.start_date).isBefore(this.rangeStart);
      }
      return false;
    },
    handleChooseTarget(items) {
      for (const { item, type } of items) {
        if (this.exceedTargetDeviceLimit) {
          return this.$alert(`Target devices exceeded. Maximum number of target devices is ${this.targetDeviceLimit}`);
        }
        if (this.exceedGeoframeTargetLimit) {
          return this.$alert(`Target geoframes exceeded. Maximum number of target geoframes is ${this.targetGeoframeLimit}.`);
        }
        this.targets.push({
          ...item,
          targetType: type,
          overrideName: item.name,
        });
        if (this.campaignDevices > this.targetDeviceLimit * this.limitWarningOffset) {
          if (this.exceedTargetDeviceLimit) {
            this.$alert(`Target devices exceeded. Maximum number of target devices is ${this.targetDeviceLimit}`);
          } else {
            this.$notify.warning({
              title: 'Approaching Limit Warning',
              message: `You are approaching the maximum number of target devices of ${this.targetDeviceLimit}.`,
            });
          }
        }
        if (this.campaignGeoframes > this.targetGeoframeLimit * this.limitWarningOffset) {
          if (this.exceedGeoframeTargetLimit) {
            this.$alert(`Target geoframes exceeded. Maximum number of target geoframes is ${this.targetGeoframeLimit}.`);
          } else {
            this.$notify.warning({
              title: 'Approaching Limit Warning',
              message: `You are approaching the maximum number of target geoframes of ${this.targetGeoframeLimit}.`,
            });
          }
        }
      }
    },
    handleChooseDestination(items) {
      for (const { item } of items) {
        if (this.exceedDestinationLimit) {
          return this.$alert(`Destination devices exceeded. Maximum number of destination devices is ${this.destinationDeviceLimit}.`);
        }
        if (this.exceedGeoframeDestinationLimit) {
          return this.$alert(`Destination geoframes exceeded. Maximum number of destination geoframes is ${this.destinationGeoframeLimit}.`);
        }
        this.destinations.push({
          ...item,
          overrideName: item.name,
        });
        if (this.destinationDevices > this.destinationDeviceLimit * this.limitWarningOffset) {
          if (this.exceedDestinationLimit) {
            this.$alert(`Destination devices exceeded. Maximum number of destination devices is ${this.destinationDeviceLimit}.`);
          } else {
            this.$notify.warning({
              title: 'Approaching Limit Warning',
              message: `You are approaching the maximum number of destination devices of ${this.destinationDeviceLimit}.`,
            });
          }
        }
        if (this.destinationGeoframes > this.destinationGeoframeLimit * this.limitWarningOffset) {
          if (this.exceedGeoframeDestinationLimit) {
            this.$alert(`Destination geoframes exceeded. Maximum number of destination geoframes is ${this.destinationGeoframeLimit}.`);
          } else {
            this.$notify.warning({
              title: 'Approaching Limit Warning',
              message: `You are approaching the maximum number of destination geoframes of ${this.destinationGeoframeLimit}.`,
            });
          }
        }
      }
    },

    isDeviceAudience(type) {
      return isDeviceAudience(type);
    },

    disabled(direction) {
      if (this.exceedDestinationLimit || this.exceedTargetDeviceLimit || this.exceedGeoframeDestinationLimit || this.exceedGeoframeTargetLimit) {
        return true;
      }
      switch (direction) {
        case 'prev':
          if (this.activeStep === 1) return true;
          break;
        case 'next':
          if (
            this.activeStep === 1 &&
            (this.campaignDevices === 0 ||
            this.outdatedTargets)
          ) {
            return true;
          }
          if (
            this.activeStep === 2 &&
            (this.destinationDevices === 0 ||
            this.outdatedDestinations)
          ) {
            return true;
          }
          if (this.activeStep === this.steps.length) {
            // Test required fields for save
            if (!this.submissionReady) return true;
          }
          break;
        default:
          break;
      }
      return false;
    },
    nextStep() {
      if (this.activeStep === this.steps.length) {
        this.steps[this.activeStep - 1].status = 'success';
        this.submitReport();
      } else {
        this.steps[this.activeStep - 1].status = 'success';
        this.activeStep++;
        this.steps[this.activeStep - 1].status = 'finish';
        this.autoComplete = '';
      }
    },
    previousStep() {
      this.steps[this.activeStep - 1].status = 'process';
      this.activeStep--;
      this.steps[this.activeStep - 1].status = 'finish';
      this.autoComplete = '';
    },
    removeAudience(index, property) {
      this[property].splice(index, 1);
      if (this.campaignDevices < this.targetDeviceLimit * this.limitWarningOffset && this.targetLimitWarning === true) {
        this.toggleTargetLimitWarning();
      }
      if (this.destinationDevices < this.destinationDeviceLimit * this.limitWarningOffset && this.destinationLimitWarning === true) {
        this.toggleDestinationLimitWarning();
      }
    },
    reset() {
      this.activeStep = 1;
      this.targets = [];
      this.destinations = [];
      this.reportTitle = '';
      this.organization = this.$store.state.user.orgDetails;
      this.dspMatches = null;
      this.uniquesServed = null;
      this.autoComplete = '';
      this.tags = [];
    },
    selectCampaignAudience(item) {
      this.autoComplete = '';
      this.targets.push(item.data);
    },
    selectDestinationAudience(item) {
      this.autoComplete = '';
      this.destinations.push(item.data);
    },
    weekOrMonth() {
      if (this.weekly) {
        return 'week';
      }
      return 'month';
    },

    delayDate(date) {
      if (!moment(date).isBefore(moment().subtract(3, 'days'))) {
        return moment(this.weeklyStartDate).add(3, 'days');
      }
      return moment(this.weeklyStartDate);
    },

    async submitReport() {
      if (this.exceedDestinationLimit || this.exceedTargetDeviceLimit || this.exceedGeoframeDestinationLimit || this.exceedGeoframeTargetLimit) {
        return this.$alert(
          'Maximum audience devices or geoframe limits exceeded. Reduce to submit report.',
        );
      }

      if (this.weekly) {
        if (!moment(this.weeklyStartDate).isBefore(moment().subtract(3, 'days'))) {
          this.startAsap = false;
          this.$alert(`Due to a 3 day delay your reports are scheduled to begin ${moment(this.weeklyStartDate).add(3, 'days').utc().format('YYYY-MM-DD')}`);
          this.isDelayed = true;
        }
      }
      this.saving = true;

      const params = {
        name: this.reportTitle,
        weekly: this.weekly,
        weeklyStartDate: moment(this.weeklyStartDate).utc().format('YYYY-MM-DD'),
        weeklyEndDate: moment(this.weeklyEndDate).utc().format('YYYY-MM-DD'),
        isDelayed: this.isDelayed,
        auto_dedupe: true,
        organization_id: this.organization.id,
        source_count: this.campaignDevices,
        destination_count: this.destinationDevices,
        sources: this.targets
          .filter(t => t.targetType === 'audience')
          .map(t => ({
            id: t.id,
            name: t.overrideName !== t.name ? t.overrideName : null,
          })),
        destinations: this.destinations.map(d => ({
          id: d.id,
          name: d.overrideName !== d.name ? d.overrideName : null,
        })),
        tags: this.tags,
        generate_household_report: this.generateHHReport,
        generate_location_report: this.generateLocationReport,
        generate_journey_report: this.generateJourneyReport,
        ...(this.dspMatches ? { dsp_count: this.dspMatches } : {}),
        ...(this.uniquesServed ? { unique_count: this.uniquesServed } : {}),
        duration: this.duration,
        durationWeekly: this.durationWeekly,
        startAsap: this.startAsap,
        cloneFromStart: this.cloneFromStart,
        rollSources: this.rollSources,
        file_audience_present: this.fileAudiencePresent,
      };

      try {
        await attributionScheduleApi.createReport(params);
        this.$message({
          message: 'Attribution report schedule created.',
          type: 'success',
        });
        this.$router.push('/attribution-reports/scheduled');
      } catch (e) {
        this.$notify.error({
          message:
            'The report failed to generate. This issue has been reported.',
        });
        this.$reportError(e, params);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style lang="scss">
.editable-name {
  padding-left: .5em;
  max-width: 554px;
  width: 93%;
}
.config-wrapper {
  float: right;
}
.config {
  display: inline-block;
  background: #ededed;
  padding: 12px;
  border-radius: 8px;
  margin-right: 1em;
  margin-bottom: 20px;
  height: 40px;
  line-height: 40px;
}
.stats {
  display: inline-block;
  background: #ededed;
  padding: 12px;
  font-size: 14px;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-right: 1em;
  span {
    display: block;
    text-align: left;
  }
}

i.reset {
  line-height: 40px;
  cursor: pointer;
}

.el-steps {
  clear: both;
  margin-top: 1em;
  margin-bottom: 20px;
}

.form-control {
  margin: 14px 0;
  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
    display: block;
  }
  .checkboxes {
    margin-bottom: 1.2em;
  }

  .el-table {
    width: 100%;
    max-width: 1000px;
  }

  em {
    font-weight: bold;
    color: #dc7733;
    font-style: normal;
    &.success {
      color: #33ab77
    }
  }
}

.schedule-wrapper {
  max-width: 1000px;
  width: 100%;
}

.exceed-warning {
  font-size: 12px;
  color: red;
}

.outdated-tag {
  font-size: 10px;
  padding: 0 2px;
  height: 16px;
  line-height: 15px;
}

.attr-picker {
  margin-top: 1em;
  max-width: 1000px;
}

.outdated-warning {
  font-size: .8em;
  margin: 1em 0;
  padding: .8em;
  background: #fecccc;
  width: 510px;
  border-radius: 3px;
  color: #550000;
  border: 1px solid #cc0000;
}

.summary {
  margin: 2em;
}
</style>
