<template>
  <div
    class="nameAndResponses container"
    :class="containerClass"
  >
    <h1>
      {{ titleWithTop10 }}
    </h1>

    <el-table
      :data="displayItems"
      :height="'80%'"
      style="width: 100%"
      :span-method="customSpanMethod"
      @row-click="handleRowClick"
    >
      <el-table-column
        prop="nameKey"
        :label="nameColumnLabel"
        align="left"
      >
        <template slot-scope="scope">
          <span style="font-weight: bold; font-size: 14px">{{
            scope.row[nameKey]
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="">
        <template>
          <span />
        </template>
      </el-table-column>
      <el-table-column
        v-if="showThirdColumn"
        prop="thirdColumnKey"
        :label="thirdColumnLabel"
        align="right"
      >
        <template slot-scope="scope">
          <span>{{ scope.row[thirdColumnKey] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="responsesKey"
        :label="responsesColumnLabel"
        align="center"
      >
        <template slot-scope="scope">
          <span :style="{ color: getResponseColor(scope.row[responsesKey]) }">
            {{ scope.row[responsesKey] }}
          </span>
        </template>
      </el-table-column>
    </el-table>

    <div
      v-if="totalPages > 1 && orientation === 'landscape'"
      class="pagination-buttons"
    >
      <p class="page-info">
        Page {{ currentPage }} of {{ totalPages }}
      </p>
      <button
        v-if="currentPage > 1"
        @click="prevPage"
      >
        Prev
      </button>
      <button
        v-if="currentPage < totalPages"
        @click="nextPage"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    items: Array,
    orientation: String,
    nameColumnLabel: {
      type: String,
      default: 'Name',
    },
    responsesColumnLabel: {
      type: String,
      default: 'Responses',
    },
    thirdColumnLabel: {
      type: String,
      default: 'State',
    },
    showThirdColumn: {
      type: Boolean,
      default: false,
    },
    nameKey: {
      type: String,
      default: 'Name',
    },
    responsesKey: {
      type: String,
      default: 'responses',
    },
    thirdColumnKey: {
      type: String,
      default: 'State',
    },
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: this.orientation === 'landscape' ? 8 : 10,
    };
  },
  computed: {
    containerClass() {
      return this.orientation === 'landscape'
        ? 'landscape-container'
        : 'portrait-container';
    },
    titleWithTop10() {
      return this.orientation === 'landscape'
        ? this.title
        : `Top 10 ${this.title}`;
    },
    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    displayItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      // Sort only by 'responsesKey' as before
      return this.items
        .slice()
        .sort((a, b) => b[this.responsesKey] - a[this.responsesKey])
        .slice(start, end);
    },
  },
  methods: {
    handleRowClick(row, column, event) {
      this.$emit('item-clicked', row);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    getResponseColor(responses) {
      const maxResponse = Math.max(
        ...this.items.map((item) => Number(item[this.responsesKey] || 0)),
      );
      const ratio = responses / maxResponse;
      const hue = 30;
      const lightness = 50 - ratio * 25;
      return `hsl(${hue}, 100%, ${lightness}%)`;
    },
    customSpanMethod({ columnIndex }) {
      if (columnIndex === 0) {
        return [1, 2];
      }
      if (columnIndex === 1) {
        return [0, 0];
      }
      if (columnIndex === 2) {
        return [1, 1];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    max-height: fit-content;
  }
  // .el-table {
  //   height: 80%;
  // }
  .pagination-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 0 12px;
    height: 20%;
  }
}

.page-info {
  margin-right: 10px;
}
.el-table {
  overflow-y: hidden;
}

h1 {
  font-size: 24px;
  margin: 0;
  padding: 14px 8px;
  background-color: white;
}

@media only screen and (max-height: 900px) {
  h1 {
    font-size: 18px !important;
    padding: 12px 8px;
  }

  .container {
    width: 100%;
  }
}
</style>
