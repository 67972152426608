<template>
  <div class="chart-toggle">
    <div class="chart-option">
      <svg
        width="200"
        height="100"
        xmlns="http://www.w3.org/2000/svg"
        class="chart-svg"
        @click="onClick"
      >
        <polygon
          points="0,100 20,80 40,70 60,60 80,40 100,30 120,50 140,20 160,40 180,30 200,20 200,100"
          fill="rgba(0, 116, 217, 0.1)"
        />
        <polyline
          fill="none"
          stroke="#0074d9"
          stroke-width="2"
          points="0,100 20,80 40,70 60,60 80,40 100,30 120,50 140,20 160,40 180,30 200,20"
        />
        <circle
          cx="20"
          cy="80"
          r="3"
          fill="#0074d9"
        />
        <circle
          cx="40"
          cy="70"
          r="3"
          fill="#0074d9"
        />
        <circle
          cx="60"
          cy="60"
          r="3"
          fill="#0074d9"
        />
        <circle
          cx="80"
          cy="40"
          r="3"
          fill="#0074d9"
        />
        <circle
          cx="100"
          cy="30"
          r="3"
          fill="#0074d9"
        />
        <circle
          cx="120"
          cy="50"
          r="3"
          fill="#0074d9"
        />
        <circle
          cx="140"
          cy="20"
          r="3"
          fill="#0074d9"
        />
        <circle
          cx="160"
          cy="40"
          r="3"
          fill="#0074d9"
        />
        <circle
          cx="180"
          cy="30"
          r="3"
          fill="#0074d9"
        />
        <circle
          cx="200"
          cy="20"
          r="3"
          fill="#0074d9"
        />
        <g
          class="checkmark"
          :class="{
            active: isActive,
          }"
        >
          <circle
            cx="20"
            cy="20"
            r="15"
            fill="#ffffff"
          />
          <polyline
            points="13,15 20,25 30,10"
            fill="none"
            stroke-width="3"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChartToggleInput',
  props: {
    onClick: {
      type: Function,
      default: () => {},
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-toggle {
  display: flex;
  align-items: center;
}
.chart-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.chart-svg {
  padding: 10px;
  transition: all 0.5s ease;
  border: 1px solid #0074d9;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.checkmark.active {
  transform: scale(0.98);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.active {
  background-color: #13ce66;
  color: #fff;
}

.checkmark polyline {
  stroke: #ccc;
}

.checkmark.active polyline {
  stroke: #00d200; /* Green color for the active checkmark */
}
</style>
