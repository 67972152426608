<template>
  <div>
    <h3>Scheduled Attribution Reports</h3>
    <scheduled-attribution-table />
  </div>
</template>

<script>
import ScheduledAttributionTable from '../../attribution/ScheduledAttributionTable';

export default {
  components: {
    ScheduledAttributionTable,
  },
};
</script>
