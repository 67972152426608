<template>
  <el-dialog
    title="Create Post Campaign Report"
    :visible="showDialog === 'postCampaign'"
    :before-close="closeDialog"
  >
    <page-post-campaign-create
      :report="report"
      :is-dialog="true"
      @close="closeDialog"
    />
  </el-dialog>
</template>

<script>
import PagePostCampaignCreate from '@/components/pages/attribution/PagePostCampaignCreate.vue';
export default {
  components: {
    PagePostCampaignCreate,
  },
  props: {
    showDialog: {
      type: String,
      required: true,
    },
    report: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        name: null,
        bidder_uuid: null,
        attribution_id: null,
        title: null,
        date: null,
        attribution_url: null,
      },
      dialogFormVisible: false,
      loading: false,
    };
  },

  watch: {
    showDialog(value) {
      // if (!value) return;
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style>
</style>
