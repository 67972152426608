<template>
  <div class="container">
    <!-- Header with Banner Image -->
    <div
      class="banner"
      :class="orientation"
      :style="bannerStyle"
    >
      <!-- Banner content -->
    </div>
    <!-- Page Title and Description -->
    <div class="page-content">
      <h1>Attribution Summary</h1>
      <p
        v-if="
          reportData.reportconfigs.attributionSummary
            .attribution_summary_description
        "
      >
        {{
          reportData.reportconfigs.attributionSummary
            .attribution_summary_description
        }}
      </p>
    </div>

    <div class="data-display-area">
      <div class="card-wrapper">
        <div
          v-if="
            reportData.reportconfigs.attributionSummary
              .footfallUniqueResponders && !reportData.digital_only
          "
          class="data-card"
        >
          <p class="number">
            {{ displayData.footfallUniqueResponders }}
          </p>
          <p class="description">
            Footfall Unique Responders
          </p>
        </div>
        <div
          v-if="
            reportData.reportconfigs.attributionSummary.footfallTotalVisits &&
              !reportData.digital_only
          "
          class="data-card"
        >
          <p class="number">
            {{ displayData.footfallTotalVisits }}
          </p>
          <p class="description">
            Footfall Total Visits
          </p>
        </div>
        <div
          v-if="
            reportData.reportconfigs.attributionSummary.totalResponders &&
              reportData.digital_display &&
              !reportData.digital_only
          "
          class="data-card"
        >
          <p class="number">
            {{ displayData.totalResponders }}
          </p>
          <p class="description">
            Total Combined Responders
          </p>
        </div>
        <div
          v-if="
            reportData.reportconfigs.attributionSummary
              .onlineUniqueResponders && reportData.digital_display
          "
          class="data-card"
        >
          <p class="number">
            {{ displayData.onlineUniqueResponders }}
          </p>
          <p class="description">
            Online Unique Responders
          </p>
        </div>
        <div
          v-if="
            reportData.reportconfigs.attributionSummary.onlineTotalVisits &&
              reportData.digital_display &&
              !reportData.digital_only
          "
          class="data-card"
        >
          <p class="number">
            {{ displayData.onlineTotalVisits }}
          </p>
          <p class="description">
            Online Total Visits
          </p>
        </div>
        <div
          v-if="displayData.totalCombinedVisits && reportData.digital_display"
          class="data-card"
        >
          <p class="number">
            {{ displayData.totalCombinedVisits }}
          </p>
          <p class="description">
            Total Combined Visits
          </p>
        </div>
      </div>

      <!-- CHART -->
      <div
        id="heatmap"
        class="heat-map"
      />

      <footer-section :logo-u-r-l="logoURL" />
    </div>
  </div>
</template>

<script>
import { whitelabel } from '@/adonis-api';
import * as d3 from 'd3';
import FooterSection from '@/components/attribution/reports/AttributionReportV2/FooterSection';

export default {
  name: 'AttributionSummaryV2',
  components: { FooterSection },
  props: {
    orientation: {
      type: String,
      required: true,
      validator: (value) => ['landscape', 'portrait'].includes(value),
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dynamicStyle: {},
    };
  },
  computed: {
    displayData() {
      const footfallTotalVisits = Number(
        this.reportData.reportData.footfallTotalVisits &&
          this.reportData.reportData.footfallTotalVisits[0]
          ? this.reportData.reportData.footfallTotalVisits[0].sum
          : 0,
      ).toLocaleString();
      const onlineTotalVisits = Number(
        this.reportData.reportData.digitalTotalVisits &&
          this.reportData.reportData.digitalTotalVisits[0]
          ? this.reportData.reportData.digitalTotalVisits[0].total
          : 0,
      ).toLocaleString();
      let totalCombinedVisits = Number(
        this.reportData.reportData.totalCombindVisits &&
          this.reportData.reportData.totalCombindVisits[0]
          ? this.reportData.reportData.totalCombindVisits[0].total
          : 0,
      ).toLocaleString();
      // Numeric versions for calculations
      const footfallUniqueRespondersNum = Number(
        this.reportData.reportData.footfallUniqueResponders &&
          this.reportData.reportData.footfallUniqueResponders[0]
          ? this.reportData.reportData.footfallUniqueResponders[0]
            .response_uniques
          : 0,
      );
      const onlineUniqueRespondersNum = Number(
        this.reportData.reportData.digitalUniqueResponders &&
          this.reportData.reportData.digitalUniqueResponders[0]
          ? this.reportData.reportData.digitalUniqueResponders[0]
            .response_uniques
          : 0,
      );

      // Calculate total responders using numeric versions
      const totalRespondersNum =
        footfallUniqueRespondersNum + onlineUniqueRespondersNum;

      // Convert to localized strings
      const footfallUniqueResponders =
        footfallUniqueRespondersNum.toLocaleString();
      const onlineUniqueResponders = onlineUniqueRespondersNum.toLocaleString();
      const totalResponders = totalRespondersNum.toLocaleString();

      // Check if totalCombinedVisits is 0 and set it to footfallTotalVisits or onlineTotalVisits based on their values
      if (totalCombinedVisits === 0) {
        if (footfallTotalVisits !== 0) {
          totalCombinedVisits = footfallTotalVisits;
        } else if (onlineTotalVisits !== 0) {
          totalCombinedVisits = onlineTotalVisits;
        }
      }

      return {
        footfallUniqueResponders,
        onlineUniqueResponders,
        footfallTotalVisits,
        onlineTotalVisits,
        totalResponders,
        totalCombinedVisits,
      };
    },

    bannerStyle() {
      return {
        backgroundImage: this.reportData.banner_url
          ? `url(${this.reportData.banner_url})`
          : 'url("https://osd-v2-backend-development.s3.amazonaws.com/public/attribution-logos/busytrafficbanner.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        // minHeight: '300px', // Set a minimum height
        // height: 'auto', // Adjust the height dynamically
      };
    },
    logoURL() {
      return this.reportData.logoURL || '/images/onspot-logo.svg';
    },
  },
  mounted() {
  // Select container and calculate dimensions
    const containerRef = '#heatmap';
    const container = d3.select(containerRef);
    const containerWidth = container.node().getBoundingClientRect().width;
    const containerHeight = container.node().getBoundingClientRect().height;

    // Set map dimensions, adjusting for margins and legend
    const margin = { top: 50, right: 20, bottom: 200, left: 50 };
    const width = containerWidth;
    const height = containerHeight - margin.top;

    // Get report data
    const reportData = this.reportData.reportData;

    // Projection and path
    const projection = d3.geoAlbersUsa().fitSize([width, height], reportData.states);
    const path = d3.geoPath().projection(projection);

    // Filter and limit data points for better visibility
    const householdData = this.reportData.digital_only ? reportData.digitalVisitsByHousehold : reportData.attributionVisitsByHousehold;
    const maxDataPoints = 100; // Limit the number of data points

    // Randomly sample data points if more than maxDataPoints
    const filteredHouseholdData = householdData.length > maxDataPoints
      ? householdData.filter((d, i) => i % Math.ceil(householdData.length / maxDataPoints) === 0)
      : householdData;

    const maxBubbleSize = 15;

    const colorScale = d3.scaleSequential(d3.interpolateViridis)
      .domain([d3.min(filteredHouseholdData, d => d.sum), d3.max(filteredHouseholdData, d => d.sum)]);

    const radiusScale = d3.scaleLinear()
      .domain([0, d3.max(filteredHouseholdData, d => d.sum)])
      .range([3, maxBubbleSize]);

    // SVG element
    const svg = d3.select(containerRef).selectAll('svg').remove(); // Clear previous SVG if any
    const newSvg = d3.select(containerRef).append('svg')
      .attr('width', containerWidth)
      .attr('height', containerHeight)
      .append('g')
      .attr('transform', `translate(0, ${margin.top - 20})`);

    // Add light background
    newSvg.append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', '#f0f0f0');

    // States
    newSvg.append('g')
      .attr('class', 'states')
      .selectAll('path')
      .data(reportData.states.features)
      .enter().append('path')
      .attr('d', path)
      .style('fill', '#ffffff')
      .attr('stroke', '#cccccc')
      .attr('stroke-width', 0.5);

    // Circles for filtered data points
    newSvg.append('g')
      .selectAll('circle')
      .data(filteredHouseholdData)
      .enter()
      .append('circle')
      .attr('cx', d => {
        const coords = projection([d.longitude, d.latitude]);
        return coords ? coords[0] : null;
      })
      .attr('cy', d => {
        const coords = projection([d.longitude, d.latitude]);
        return coords ? coords[1] : null;
      })
      .attr('r', d => Math.min(radiusScale(d.sum), maxBubbleSize))
      .attr('fill', d => colorScale(d.sum))
      .attr('opacity', 0.7);

    // Title
    newSvg.append('text')
      .attr('x', width / 2)
      .attr('y', -10)
      .attr('text-anchor', 'middle')
      .style('font-size', '20px')
      .style('font-weight', 'bold')
      .text('Household Visits Heatmap');
  },

  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// 1. Banner
.banner {
  height: 300px;
  max-height: 25vh;
}
// 2. Page Title and Description
.page-content {
  height: auto;
  margin: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
}

.page-content h1 {
  margin: 0;
}

.page-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

// 3. Data Display Area
.data-display-area {
  height: 50vh;
  overflow-y: hidden;
  padding: 20px;
  background: #f3f3f3;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px;
  justify-content: space-between;
  border-radius: 12px;
}
.card-wrapper {
  height: auto;
  max-height: 25%;
  display: flex;
  justify-content: center;
  // flex-wrap: wrap;
  gap: 20px;
  // margin-bottom: 12px;
}
.data-card {
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-radius: 4px;
}
.data-card p {
  margin: 0;
}
.data-card .number {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}
.heat-map {
  height: 90%;
  max-height: 90%;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
}

@media (max-height: 768px) {
  .data-display-area {
    padding: 12px;
    height: 60vh;
  }

  .data-card {
    padding: 12px;
  }
  .data-card .number {
    font-size: 18px;
  }

  .data-card .description {
    font-size: 14px;
  }
}
</style>
