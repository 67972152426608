var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"online-container"},[_c('div',{staticClass:"header"},[_c('h1',[_vm._v("Online Attribution")]),(
        _vm.reportData.reportconfigs.onlineAttributionOptions
          .attribution_online_description
      )?_c('p',[_vm._v(" "+_vm._s(_vm.reportData.reportconfigs.onlineAttributionOptions.attribution_online_description)+" ")]):_vm._e()]),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"first-row"},[_c('div',{staticClass:"cell-wrapper"},[_c('div',{staticClass:"cell"},[_c('p',{staticClass:"number"},[_vm._v(" "+_vm._s(Number( _vm.reportData.reportData.digitalUniqueResponders[0] .response_uniques ).toLocaleString() || 0)+" ")]),_c('p',{staticClass:"description"},[_vm._v(" Online Unique Responders ")])]),_c('div',{staticClass:"cell"},[_c('p',{staticClass:"number"},[_vm._v(" "+_vm._s(Number( _vm.reportData.reportData.digitalTotalVisits[0].total ).toLocaleString() || 0)+" ")]),_c('p',{staticClass:"description"},[_vm._v(" Online Total Visits ")])])]),(
          _vm.reportData.reportconfigs.onlineAttributionOptions.visitsByWeekday
            .enabled
        )?_c('div',{staticClass:"chart-wrapper"},[_vm._m(0),_c('line-chart',{staticStyle:{"width":"100%","margin":"12px"},attrs:{"chart-data":_vm.reportData.reportData,"labels":_vm.reportData.reportconfigs.onlineAttributionOptions.visitsByWeekday
              .days}})],1):_vm._e()]),_c('div',{staticClass:"second-row"},[_c('h1',{staticClass:"title"},[_vm._v(" Online Visits by Date ")]),(
          _vm.reportData.reportData.digitalUniqueResponders[0].response_uniques
        )?_c('line-chart-visits',{attrs:{"chart-data":_vm.reportData.reportData}}):_vm._e()],1),_c('footer-section',{attrs:{"logo-u-r-l":_vm.logoURL}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend-wrapper"},[_c('h1',{staticClass:"title"},[_vm._v(" Visits by Weekday ")]),_c('div',{staticClass:"legend-container"},[_c('div',{staticClass:"legend-item"},[_c('div',{staticClass:"circle blue"}),_c('span',[_vm._v("Initial")])]),_c('div',{staticClass:"legend-item",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"circle yellow"}),_c('span',[_vm._v("Repeat")])])])])
}]

export { render, staticRenderFns }