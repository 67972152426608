<template>
  <div class="main-container">
    <div class="header">
      <h1>Responders Profile</h1>
      <p
        v-if="
          reportData.reportconfigs.respondersOptions
            .attribution_responders_description
        "
      >
        {{
          reportData.reportconfigs.respondersOptions
            .attribution_responders_description
        }}
      </p>
    </div>
    <div class="content-container">
      <div class="chart-wrapper">
        <responder-doughnut-chart
          :chart-data="reportData.reportData.home_owner"
          :chart-title="'Home Ownership'"
          :chart-type="'doughnut'"
          style="height: 100%"
        />
        <responder-doughnut-chart
          :chart-data="reportData.reportData.dwellingType"
          :chart-title="'Dwelling Type'"
          :chart-type="'doughnut'"
          style="height: 100%"
        />
        <progress-table
          title="Media Usage"
          :data="reportData.reportData.mediaUsage"
          :show-pagination="true"
        />
        <progress-table
          title="Interests"
          :data="reportData.reportData.interests"
          :show-pagination="true"
        />
      </div>
      <footer-section :logo-u-r-l="logoURL" />
    </div>
  </div>
</template>

<script>
import ResponderDoughnutChart from '../../report-components/ResponderDoughnutChart';
import FooterSection from '../FooterSection.vue';
import ProgressTable from '../ProgressTable';

export default {
  name: 'ResponsesProfile',
  components: {
    ResponderDoughnutChart,
    ProgressTable,
    FooterSection,
  },
  props: {
    orientation: {
      type: String,
      required: true,
      validator: (value) => ['landscape', 'portrait'].includes(value),
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    displayData() {
      return {};
    },

    logoURL() {
      return this.reportData.logoURL || '/images/onspot-logo.svg';
    },
  },

  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    margin: 12px;
    * {
      margin: 0;
    }
  }

  .content-container {
    height: 90vh;
    width: 95%;
    background-color: #f5f5f5;
    margin: 12px;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;

    .chart-wrapper {
      height: 100%;
      width: 100%;
      display: grid;
      gap: 12px;
      row-gap: 35px;
      grid-template-columns: 50% 50%;
      grid-template-rows: 30% 65%;
      justify-content: center;

      div {
        background-color: white;
        padding: 12px;
      }
    }
  }
}

@media (max-height: 900px) {
  .main-container {
    .content-container {
      .chart-wrapper {
        height: 85%;
      }
    }
  }
}
</style>
