<template>
  <div v-loading="busy">
    <h3>{{ pageTitle }}</h3>
    <div class="stats">
      <span class="campaign">
        Total Target Devices: {{ campaignDevices | numeral }}
      </span>
      <span class="campaign">
        Total Destination Devices: {{ destinationDevices | numeral }}
      </span>
    </div>

    <div class="stats geo-stats">
      <span class="campaign">
        Total Target Geoframes: {{ campaignGeoframes | numeral | handleNull }}
      </span>
      <span class="campaign">
        Total Destination Geoframes: {{ destinationGeoframes | numeral | handleNull }}
      </span>
    </div>

    <el-steps :active="activeStep">
      <el-step
        v-for="(step, index) of steps"
        :key="index"
        :title="step.title"
        :description="step.description"
        :status="step.status"
      />
    </el-steps>

    <div v-if="activeStep === 1">
      <div class="form-control">
        <label>Select Target Audience(s)</label>
        <el-table
          v-if="targets.length > 0"
          :data="targets"
          stripe
        >
          <el-table-column
            prop="name"
            label="Target Name"
          >
            <template slot-scope="{ row }">
              <audience-type-tooltip :type="row.type" />
              &nbsp;
              {{ getItemName(row) }}
              &nbsp;
              <el-tag
                v-if="isOutdated(row)"
                class="outdated-tag"
                effect="dark"
                size="mini"
                type="danger"
              >
                out of date
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="count"
            label="Devices"
            :width="110"
          >
            <template slot-scope="{ row }">
              <span>{{
                $options.filters.numeral(
                  row.targetType !== 'publication'
                    ? row.count
                    : row.publish_count
                )
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="start_date"
            label="Start Date"
            :width="110"
          />
          <el-table-column
            prop="end_date"
            label="End Date"
            :width="110"
          />
          <el-table-column
            prop="completed"
            label="Publication Completed"
            :width="160"
          >
            <template slot-scope="{ row }">
              {{ row.completed | dateString({ includeTime: true }) }}
            </template>
          </el-table-column>
          <el-table-column :width="60">
            <template slot-scope="{ $index }">
              <el-button
                class="remove"
                size="small"
                type="danger"
                icon="fa fa-trash"
                @click="removeAudience($index, 'targets')"
              />
            </template>
          </el-table-column>
        </el-table>

        <div class="attr-picker">
          <attribution-picker
            :selected-items="targets"
            stage="target"
            @select="handleChooseTarget"
          />
        </div>

        <attribution-legend stage="target" />

        <div
          v-if="outdatedTargets"
          class="outdated-warning"
        >
          You have outdated audiences selected, please remove or replace them before continuing.
        </div>
      </div>
    </div>

    <div v-if="activeStep === 2">
      <div class="form-control">
        <label>Select Destination Audience(s)</label>
        <el-table
          v-if="destinations.length > 0"
          :data="destinations"
          stripe
        >
          <el-table-column
            prop="name"
            label="Destination Name"
          >
            <template slot-scope="{ row }">
              <audience-type-tooltip :type="row.type" />
              &nbsp;
              {{ getItemName(row) }}
              &nbsp;
              <el-tag
                v-if="isOutdated(row)"
                class="outdated-tag"
                effect="dark"
                size="mini"
                type="danger"
              >
                out of date
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="count"
            label="Devices"
            :width="110"
          >
            <template slot-scope="{ row }">
              <span>{{
                $options.filters.numeral(
                  row.targetType !== 'publication'
                    ? row.count
                    : row.publish_count
                )
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="start_date"
            label="Start Date"
            :width="110"
          />
          <el-table-column
            prop="end_date"
            label="End Date"
            :width="110"
          />
          <el-table-column :width="60">
            <template slot-scope="{ $index }">
              <el-button
                class="remove"
                size="small"
                type="danger"
                icon="fa fa-trash"
                @click="removeAudience($index, 'destinations')"
              />
            </template>
          </el-table-column>
        </el-table>

        <div class="attr-picker">
          <attribution-picker
            :selected-items="[...targets, ...destinations]"
            stage="destination"
            @select="handleChooseDestination"
          />
        </div>

        <attribution-legend stage="destination" />

        <div
          v-if="outdatedDestinations"
          class="outdated-warning"
        >
          You have outdated audiences selected, please remove or replace them before continuing.
        </div>
      </div>
    </div>

    <div v-if="showDsp && activeStep === 3">
      <div class="form-control">
        <label>DSP Matched Devices (optional)</label>
        <el-input
          v-model="dspMatches"
          :placeholder="
            `If blank, ${dspMatchPercent}% of Target devices will be used.`
          "
        />
      </div>
      <div class="form-control">
        <label>DSP Devices Served (optional)</label>
        <el-input
          v-model="uniquesServed"
          :placeholder="
            `If blank, ${uniqueServedPercent}% of DSP Matched devices will be used.`
          "
        />
      </div>
    </div>

    <div
      v-show=" canCreateEnhancedAttribution
        && activeStep === 3"
    >
      <attribution-customize
        :attribution-title="reportTitle"
        @update-options="handleCustomOptions"
        @next-step="nextStep"
        @previous-step="previousStep"
      />
    </div>

    <div v-if="(showDsp && activeStep === 5) || (!showDsp && activeStep === 4) || (!showDsp && activeStep === 3 && !canCreateEnhancedAttribution)">
      <div class="form-control">
        <label>Attribution Report Title</label>
        <el-input v-model="reportTitle" />
      </div>
      <div class="form-control">
        <label>Organization</label>
        <org-picker :organization.sync="organization" />
      </div>
      <tag-picker :tags.sync="tags" />
      <div class="checkboxes">
        <el-checkbox
          v-if="canDownloadLocations"
          v-model="generateLocationReport"
          label="Generate Attribution Locations Report"
        />
        <el-checkbox
          v-if="canDownloadJourney && !fileAudiencePresent"
          v-model="generateJourneyReport"
          label="Generate Attribution Journey Report"
        />
        <el-checkbox
          v-if="canDownloadHHLocations"
          v-model="generateHHReport"
          label="Generate Household Locations Report"
        />
        <el-checkbox
          v-if="canCreateResponderAudience && canCreateEnhancedAttribution"
          v-model="createRespondersAudience"
          label="Create Responders Audience"
        />
      </div>

      <div class="form-control">
        <div
          v-if="historical"
          class="help"
        >
          <h4>Note:</h4>
          <p>
            You have selected audience(s) containing dates prior to
            {{ $store.state.settings.date.rangeStart.format('YYYY-MM-DD') }},
            only the Attribution Locations &amp; Journey Reports are available.
          </p>
          <p v-if="!canDownloadLocations">
            <em>You do not have access to the Attribution Locations Report!</em>
          </p>
          <p v-if="!canDownloadJourney">
            <em>You do not have access to the Attribution Journey Report!</em>
          </p>
          <p v-if="!canDownloadHHLocations">
            <em>You do not have access to the Attribution Household Report!</em>
          </p>
        </div>
      </div>
    </div>

    <el-button
      v-if="activeStep > 1 && !isReportStep"
      type="info"
      :disabled="disabled('prev')"
      @click="previousStep()"
    >
      &laquo; Previous Step
    </el-button>
    <el-button
      v-if="!isReportStep"
      :loading="saving"
      type="primary"
      :disabled="disabled('next')"
      @click="nextStep()"
    >
      <span v-if="activeStep < steps.length">
        Next Step &raquo;
      </span>
      <span v-if="activeStep >= steps.length">
        Save &amp; Run Report &#x2713;
      </span>
    </el-button><br>
    <span
      v-if="exceedDestinationLimit"
      class="exceed-warning"
    >
      Max destination devices ({{ destinationDeviceLimit }}) exceeded.
    </span>
    <span
      v-if="exceedTargetDeviceLimit"
      class="exceed-warning"
    >
      Max target devices ({{ targetDeviceLimit }}) exceeded.
    </span>
    <span
      v-if="exceedGeoframeTargetLimit"
      class="exceed-warning"
    >
      Max target geoframes ({{ targetGeoframeLimit }}) exceeded.
    </span>
    <span
      v-if="exceedGeoframeDestinationLimit"
      class="exceed-warning"
    >
      Max destination geoframes ({{ destinationGeoframeLimit }}) exceeded.
    </span>
  </div>
</template>

<script>
import ElCheckbox from 'element-ui/lib/checkbox';
import _sumBy from 'lodash/sumBy';
import { mapGetters } from 'vuex';
import TagPicker from '../../global/TagPicker.vue';
import OrgPicker from '../../global/OrgPicker.vue';
import AudienceTypeTooltip from '../../global/AudienceTypeTooltip.vue';
import AttributionLegend from '../../global/AttributionLegend.vue';
import AttributionPicker from '../../global/AttributionPicker.vue';
import { attribution as attributionApi, user as userApi } from '@/adonis-api';
import moment from 'moment';
import { isDeviceAudience } from '@/helpers';
import  AttributionCustomize  from '../../attribution/AttributionCustomize.vue';

export default {
  name: 'PageAttributionCreate',
  components: {
    AttributionLegend,
    AttributionPicker,
    AttributionCustomize,
    AudienceTypeTooltip,
    TagPicker,
    OrgPicker,
  },

  data() {
    return {
      activeStep: 1,
      busy: false,
      dontWarnBilling: false,
      targets: [],
      destinations: [],
      reportTitle: '',
      attributionReport: null,
      organization: this.$store.state.user.orgDetails,
      showDsp: this.$store.state.user.orgDetails.attribution_overrides,
      dspMatches: null,
      uniquesServed: null,
      generateLocationReport: false,
      generateJourneyReport: false,
      generateHHReport: false,
      createRespondersAudience: true,
      autoComplete: '',
      tags: [],
      saving: false,
      steps: [],
      limitWarningOffset: 0.9,
      loading: false,
      customOptions: null,
    };
  },

  computed: {
    ...mapGetters('settings', [
      'dspMatchPercent',
      'uniqueServedPercent',
      'rangeStart',
      'destinationDeviceLimit',
      'targetDeviceLimit',
      'targetGeoframeLimit',
      'destinationGeoframeLimit',
    ]),
    ...mapGetters('user', ['hasPermission', 'preferences', 'userId']),

    canCreateEnhancedAttribution() {
      return this.hasPermission('create_and_view_enhanced_attribution');
    },
    canDownloadLocations() {
      return this.hasPermission('create_attribution_location_reports');
    },
    canDownloadJourney() {
      return this.hasPermission('create_attribution_journey_reports');
    },
    canDownloadHHLocations() {
      return this.hasPermission('create_attribution_hh_location_reports');
    },
    canCreateResponderAudience() {
      return this.hasPermission('create_attribution_responder_audiences');
    },
    campaignDevices() {
      return _sumBy(this.targets, i => i.count || i.publish_count);
    },
    destinationDevices() {
      return _sumBy(this.destinations, 'count');
    },
    campaignGeoframes() {
      return _sumBy(this.targets, i => i.geo_count);
    },
    destinationGeoframes() {
      return _sumBy(this.destinations, 'geo_count');
    },
    historical() {
      return (
        this.targets.some(t => t.historical) ||
        this.destinations.some(t => t.historical)
      );
    },
    submissionReady() {
      if (
        this.campaignDevices > 0 &&
        this.destinationDevices > 0 &&
        this.reportTitle &&
        !this.outdated &&
        ((this.historical && this.canDownloadLocations) || !this.historical)
      ) {
        return true;
      }

      return false;
    },
    exceedTargetDeviceLimit() {
      return this.campaignDevices > this.targetDeviceLimit;
    },
    exceedDestinationLimit() {
      return this.destinationDevices > this.destinationDeviceLimit;
    },
    exceedGeoframeTargetLimit() {
      return this.campaignGeoframes > this.targetGeoframeLimit;
    },
    exceedGeoframeDestinationLimit() {
      return this.destinationGeoframes > this.destinationGeoframeLimit;
    },
    outdatedDestinations() {
      return this.destinations.some(d => moment(d.start_date).isBefore(this.rangeStart) && !d.historical);
    },
    outdatedTargets() {
      return this.targets.some(t => moment(t.start_date).isBefore(this.rangeStart) && !t.historical);
    },
    outdated() {
      return this.outdatedTargets || this.outdatedDestinations;
    },
    pageTitle() {
      if (this.$route.params.id) {
        return 'Clone Attribution Report';
      }
      return 'Create Attribution Report';
    },
    fileAudiencePresent() {
      if (this.targets && this.destinations) {
        return this.targets.some(audience => isDeviceAudience(audience.type)) || this.destinations.some(audience => isDeviceAudience(audience.type));
      }
      return true;
    },
    isReportStep() {
      const currentStepId = this.steps[this.activeStep - 1]?.id;
      return currentStepId === 4;
    },
  },

  watch: {
    '$route.params.id': {
      handler(val) {
        if (typeof val !== 'undefined') {
          this.init();
        } else {
          this.reset();
        }
      },
      immediate: true,
    },
    historical(val) {
      this.generateLocationReport = val;
      this.generateJourneyReport = val;
      this.generateHHReport = val;
    },
  },

  created() {
    this.steps = [
      {
        title: 'Target Audience',
        description: 'Define your target audience',
        status: 'finish',
        id: 1,
      },
      {
        title: 'Destination Audience',
        description: 'Define your destination audience',
        status: 'wait',
        id: 2,
      },

    ];

    if (this.organization.attribution_overrides) {
      this.steps.push({
        title: 'Campaign Details',
        description: "Define your campaign's details",
        status: 'wait',
        id: 3,
      });
    }

    if (this.canCreateEnhancedAttribution) {
      this.steps.push({
        title: 'Customize Reports',
        description: 'Customize your attribution reports',
        status: 'wait',
        id: 4,
      });
    }

    this.steps.push({
      title: 'Finish Report',
      description: 'Name, save and run your report',
      status: 'wait',
      id: 5,
    });
  },

  mounted() {
    if (!this.$route.params.id && !this.preferences.options.disable_attrib_warn) {
      const h = this.$createElement;

      this.$msgbox({
        title: 'Attribution Report',
        // message: 'This report is a billable item. Do you wish to continue?',
        message: h('div', null, [
          h('div', null, 'This report is a billable item. Do you wish to continue?'),
          h('div', { style: 'margin-top: 1em' }, [
            h(ElCheckbox, {
              input: {
                value: this.dontWarnBilling,
              },
              on: {
                change: value => { this.dontWarnBilling = value; },
              },
            }, 'Don\'t show this again'),
          ]),
        ]),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async () => {
        if (this.dontWarnBilling) {
          await userApi.update(this.userId, {
            preferences: {
              ...this.preferences,
              options: {
                ...this.preferences.options,
                disable_attrib_warn: this.dontWarnBilling,
              },
            },
          });
        }
      }).catch(err => {
        if (err !== 'cancel') {
          this.$reportError(err);
        } else {
          this.$router.go(-1);
        }
      });
    }
  },

  methods: {
    getItemName(item) {
      return item.targetType !== 'publication'
        ? item.name
        : item.parent.name +
            ` (${item.publish_partner.partner.name}` +
            (item.extension ? ', extended)' : ')');
    },
    isOutdated(row) {
      if (row && row.start_date) {
        return moment(row.start_date).isBefore(this.rangeStart);
      }
      return false;
    },
    handleCustomOptions(options) {
      this.customOptions = options;
    },
    handleChooseTarget(items) {
      for (const { item, type } of items) {
        if (this.exceedTargetDeviceLimit) {
          return this.$alert(`Target devices exceeded. Maximum number of target devices is ${this.targetDeviceLimit}`);
        }
        if (this.exceedGeoframeTargetLimit) {
          return this.$alert(`Target geoframes exceeded. Maximum number of target geoframes is ${this.targetGeoframeLimit}.`);
        }
        this.targets.push({
          ...item,
          targetType: type,
        });
        if (this.campaignDevices > this.targetDeviceLimit * this.limitWarningOffset) {
          if (this.exceedTargetDeviceLimit) {
            this.$alert(`Target devices exceeded. Maximum number of target devices is ${this.targetDeviceLimit}`);
          } else {
            this.$notify.warning({
              title: 'Approaching Limit Warning',
              message: `You are approaching the maximum number of target devices of ${this.targetDeviceLimit}.`,
            });
          }
        }
        if (this.campaignGeoframes > this.targetGeoframeLimit * this.limitWarningOffset) {
          if (this.exceedGeoframeTargetLimit) {
            this.$alert(`Target geoframes exceeded. Maximum number of target geoframes is ${this.targetGeoframeLimit}.`);
          } else {
            this.$notify.warning({
              title: 'Approaching Limit Warning',
              message: `You are approaching the maximum number of target geoframes of ${this.targetGeoframeLimit}.`,
            });
          }
        }
      }
    },
    handleChooseDestination(items) {
      for (const { item } of items) {
        if (this.exceedDestinationLimit) {
          return this.$alert(`Destination devices exceeded. Maximum number of destination devices is ${this.destinationDeviceLimit}.`);
        }
        if (this.exceedGeoframeDestinationLimit) {
          return this.$alert(`Destination geoframes exceeded. Maximum number of destination geoframes is ${this.destinationGeoframeLimit}.`);
        }
        this.destinations.push(item);
        if (this.destinationDevices > this.destinationDeviceLimit * this.limitWarningOffset) {
          if (this.exceedDestinationLimit) {
            this.$alert(`Destination devices exceeded. Maximum number of destination devices is ${this.destinationDeviceLimit}.`);
          } else {
            this.$notify.warning({
              title: 'Approaching Limit Warning',
              message: `You are approaching the maximum number of destination devices of ${this.destinationDeviceLimit}.`,
            });
          }
        }
        if (this.destinationGeoframes > this.destinationGeoframeLimit * this.limitWarningOffset) {
          if (this.exceedGeoframeDestinationLimit) {
            this.$alert(`Destination geoframes exceeded. Maximum number of destination geoframes is ${this.destinationGeoframeLimit}.`);
          } else {
            this.$notify.warning({
              title: 'Approaching Limit Warning',
              message: `You are approaching the maximum number of destination geoframes of ${this.destinationGeoframeLimit}.`,
            });
          }
        }
      }
    },

    async init() {
      this.busy = true;

      try {
        const report = await attributionApi.findOne(this.$route.params.id);
        this.targets = report.source_audiences.map(a => {
          return {
            targetType: 'audience',
            type: a.type,
            id: a.id,
            count: a.count,
            geo_count: a.geo_count,
            name: a.name,
            start_date: a.start_date,
            end_date: a.end_date,
          };
        }).concat(report.source_publications.map(p => {
          return {
            targetType: 'publication',
            id: p.id,
            count: p.publish_count,
            completed: p.completed,
          };
        }));

        this.destinations = report.destination_audiences.map(a => {
          return {
            targetType: 'audience',
            type: a.type,
            id: a.id,
            count: a.count,
            geo_count: a.geo_count,
            name: a.name,
            start_date: a.start_date,
            end_date: a.end_date,
          };
        }).concat(report.destination_publications.map(p => {
          return {
            targetType: 'publication',
            id: p.id,
            count: p.publish_count,
            completed: p.completed,
          };
        }));

        this.reportTitle = report.name;
        this.attributionReport = report;
        this.organization = { id: report.organization_id };
        this.tags = report.tags;
        this.dspMatches = report.dsp_count;
        this.uniquesServed = report.unique_count;
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'The requested attribution report is not available.',
        });
        setTimeout(() => this.$router.go(-1), 1500);
      } finally {
        this.busy = false;
      }
    },

    disabled(direction) {
      if (this.exceedDestinationLimit || this.exceedTargetDeviceLimit || this.exceedGeoframeDestinationLimit || this.exceedGeoframeTargetLimit) {
        return true;
      }
      switch (direction) {
        case 'prev':
          if (this.activeStep === 1) return true;
          break;
        case 'next':
          if (
            this.activeStep === 1 &&
            ((this.campaignDevices === 0 &&
            !this.historical) ||
            this.outdatedTargets)
          ) {
            return true;
          }
          if (
            this.activeStep === 2 &&
            ((this.destinationDevices === 0 &&
            !this.historical) ||
            this.outdatedDestinations)
          ) {
            return true;
          }
          if (this.activeStep === this.steps.length) {
            // Test required fields for save
            if (!this.submissionReady) return true;
          }
          break;
        default:
          break;
      }
      return false;
    },
    nextStep() {
      if (this.activeStep === this.steps.length) {
        this.steps[this.activeStep - 1].status = 'success';
        this.submitReport();
      } else {
        this.steps[this.activeStep - 1].status = 'success';
        this.activeStep++;
        this.steps[this.activeStep - 1].status = 'finish';
        this.autoComplete = '';
      }
    },
    previousStep() {
      this.steps[this.activeStep - 1].status = 'process';
      this.activeStep--;
      this.steps[this.activeStep - 1].status = 'finish';
      this.autoComplete = '';
    },
    removeAudience(index, property) {
      this[property].splice(index, 1);
      if (this.campaignDevices < this.targetDeviceLimit * this.limitWarningOffset && this.targetLimitWarning === true) {
        this.toggleTargetLimitWarning();
      }
      if (this.destinationDevices < this.destinationDeviceLimit * this.limitWarningOffset && this.destinationLimitWarning === true) {
        this.toggleDestinationLimitWarning();
      }
    },
    reset() {
      this.activeStep = 1;
      this.targets = [];
      this.destinations = [];
      this.reportTitle = '';
      this.organization = this.$store.state.user.orgDetails;
      this.dspMatches = null;
      this.uniquesServed = null;
      this.autoComplete = '';
      this.tags = [];
    },
    selectCampaignAudience(item) {
      this.autoComplete = '';
      this.targets.push(item.data);
    },
    selectDestinationAudience(item) {
      this.autoComplete = '';
      this.destinations.push(item.data);
    },

    async submitReport() {
      if (this.historical && !this.organization.historical_access) {
        return this.$notify.error({
          title: 'Date Range Error',
          message: `The selected organization is not configured to access dates prior to ${this.rangeStart.format(
            'YYYY-MM-DD',
          )}.`,
        });
      }

      if (this.exceedDestinationLimit || this.exceedTargetDeviceLimit || this.exceedGeoframeDestinationLimit || this.exceedGeoframeTargetLimit) {
        return this.$alert(
          'Maximum audience devices or geoframe limits exceeded. Reduce to submit report.',
        );
      }

      this.saving = true;
      const params = {
        name: this.reportTitle,
        auto_dedupe: true,
        custom_options: this.customOptions,
        organization_id: this.organization.id,
        source_count: this.campaignDevices,
        destination_count: this.destinationDevices,
        sources: {
          audiences: this.targets
            .filter(t => t.targetType === 'audience')
            .map(t => t.id),
          publications: this.targets
            .filter(t => t.targetType === 'publication')
            .map(t => t.id),
        },
        destinations: {
          audiences: this.destinations.map(d => d.id),
        },
        file_audience_present: this.fileAudiencePresent,
        tags: this.tags,
        historical: this.historical,
        create_responders_audience: this.canCreateEnhancedAttribution ? this.createRespondersAudience : false,
        generate_household_report: this.generateHHReport,
        generate_location_report: this.generateLocationReport,
        generate_journey_report: this.generateJourneyReport,
        ...(this.dspMatches ? { dsp_count: this.dspMatches } : {}),
        ...(this.uniquesServed ? { unique_count: this.uniquesServed } : {}),
      };

      try {
        const response = await attributionApi.createReport(params);
        const { attribution_report: attrReport } = response.data;

        window.mixpanel.track('Create Attribution Report', {
          ID: attrReport.id,
          Name: attrReport.name,
          'Source Count': attrReport.source_count,
          'Source Audience IDs': attrReport.source_audience_ids,
          'Source Publication IDs': attrReport.source_publication_ids,
          'Destination Count': attrReport.destination_count,
          'Destination Audience IDs': attrReport.destination_audience_ids,
          'Destination Publication IDs': attrReport.destination_publication_ids,
          Historical: attrReport.historical,
        });

        this.$message({
          message: 'Attribution report saved.',
          type: 'success',
        });
        this.$router.push('/attribution-reports/library');
      } catch (e) {
        this.$notify.error({
          message:
            'The report failed to generate. This issue has been reported.',
        });
        this.$reportError(e, params);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style lang="scss">
.stats {
  display: inline-block;
  background: #ededed;
  padding: 12px;
  font-size: 14px;
  border-radius: 8px;
  margin-bottom: 20px;
  span {
    display: block;
    text-align: left;
  }
}

.el-steps {
  margin-bottom: 20px;
}

.form-control {
  margin: 14px 0;
  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
    display: block;
  }
  .checkboxes {
    margin-bottom: 1.2em;
  }

  .el-table {
    width: 100%;
    max-width: 1000px;
  }

  em {
    font-weight: bold;
    color: #dc7733;
    font-style: normal;
  }
}

.exceed-warning {
  font-size: 12px;
  color: red;
}

.geo-stats {
  margin-left: .5em;
}

.outdated-tag {
  font-size: 10px;
  padding: 0 2px;
  height: 16px;
  line-height: 15px;
}

.attr-picker {
  margin-top: 1em;
  max-width: 1000px;
}

.outdated-warning {
  font-size: .8em;
  margin: 1em 0;
  padding: .8em;
  background: #fecccc;
  width: 510px;
  border-radius: 3px;
  color: #550000;
  border: 1px solid #cc0000;
}
</style>
