<template>
  <div class="footfall-container">
    <!-- Header with Banner Image -->
    <div class="header">
      <h1>Online Attribution</h1>
      <p
        v-if="
          reportData.reportconfigs.onlineAttributionOptions
            .attribution_online_description
        "
      >
        {{ reportData.reportconfigs.onlineAttributionOptions.attribution_online_description }}
      </p>
    </div>
    <div class="content-wrapper">
      <div class="data-row">
        <div class="first-row">
          <name-and-responses
            :orientation="orientation"
            :items="reportData.reportData.digitalAudienceResponsesByCity"
            title="Online Responses By City"
            responses-key="sum"
            name-key="city"
            name-column-label="City"
            third-column-key="state"
            :show-third-column="true"
            :height="containerHeight"
          />
          <!-- :height="containerHeight" -->
          <!-- <map-attribution
            :key="mapForceRerenderKey"
            :id-map="0"
            :maps-config="cityData"
            :map="cityConfigs"
            style="width: 100%"
            :height="'100%'"
          /> -->
        </div>
        <div class="second-row">
          <name-and-responses
            :orientation="orientation"
            :items="reportData.reportData.digitalAudienceResponsesByZipcode"
            title="Online Responses By Zip Code"
            name-column-label="Zip Code"
            name-key="zipcode"
            responses-key="sum"
            :show-hr="true"
          />
          <!-- <map-attribution
            :key="mapForceRerenderKey"
            :id-map="1"
            :maps-config="zipcodeData"
            :map="zipcodeConfigs"
            boundary-style="dotted"
            style="width: 100%"
            :height="'100%'"
          /> -->
        </div>
      </div>
      <footer-section :logo-u-r-l="logoURL" />
    </div>
  </div>
</template>

<script>
import NameAndResponses from '../NameAndResponses.vue';
import { attribution as attributionApi } from '@/adonis-api';
import MapAttribution from '../MapAttribution.vue';
import FooterSection from '../FooterSection.vue';

export default {
  name: 'OnlineReport',
  components: {
    NameAndResponses,
    MapAttribution,
    FooterSection,
  },
  props: {
    orientation: {
      type: String,
      required: true,
      validator: (value) => ['landscape', 'portrait'].includes(value),
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cityData: this.reportData.reportData.digitalMapsData[0],
      zipcodeData: this.reportData.reportData.digitalMapsData[1],
      cityConfigs: {
        id: 'attributionResponsesByCity_data',
        accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-74.5, 40],
        zoom: 10,
      },
      zipcodeConfigs: {
        id: 'attributionResponsesByZipcode_data',
        accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
        style: 'mapbox://styles/mapbox/light-v10',
        center: [-74.5, 40],
        zoom: 10,
      },
      mapForceRerenderKey: 0, // Add this property
    };
  },
  computed: {
    containerHeight() {
      return this.orientation === 'landscape' ? '625px' : '675px';
    },
    displayData() {
      return {};
    },

    logoURL() {
      return this.reportData.logoURL || '/images/onspot-logo.svg';
    },
    mapStyle() {
      return window.orientation === 0 ? 'width: 100%;' : '';
    },
  },
  mounted() {
    // Check if the orientation is 'portrait'
    if (this.orientation === 'portrait') {
      // Set a timeout to increment the key after 3 seconds
      setTimeout(() => {
        this.mapForceRerenderKey++;
      }, 3000);
    }
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
    async handleCityClicked(item) {
      // Handle the clicked item here
      const data = await attributionApi.getTopCity(item);
      this.cityData = data;
      this.mapForceRerenderKey++;
    },
    async handleZipClicked(item) {
      // Handle the clicked item here
      const data = await attributionApi.getTopZipcode(item);
      this.zipcodeData = data;
      this.mapForceRerenderKey++;
    },
  },
};
</script>

<style lang="scss" scoped>
.footfall-container {
  max-height: 100vh;
  width: 100%;
  // max-width: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  height: 10vh;
  margin: 12px;
  * {
    margin: 0;
  }
}

.content-wrapper {
  max-width: 100%;
  display: flex;
  height: 90vh;
  max-height: 90vh;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  background-color: #f5f5f5;
  padding: 12px;
  margin: 12px;
  border-radius: 8px;

  .data-row {
    display: flex;
    width: 100%;
    gap: 12px;
    justify-content: space-between;
    height: 90%;

    .first-row {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    .second-row {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }
}
.nameAndResponses.container {
  height: 100%;
}
@media (max-height: 900px) {
  .header {
    height: 15vh;
  }
  .content-wrapper {
    height: 75vh;
  }
}
</style>
