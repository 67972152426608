<template>
  <div @click.stop>
    <el-dialog
      :visible.sync="popoverVisible"
      width="75%"
    >
      <div>
        <div class="paginator">
          <div class="result-count">
            {{ total }} results
          </div>
          <el-button
            class="multi-select"
            icon="fa fa-plus"
            size="mini"
            type="primary"
            :disabled="selected.length === 0"
            @click="handleMultiSelection"
          >
            Add Selected
            <span v-if="selected.length">
              ({{ selected.length }})
            </span>
          </el-button>
          <el-button-group>
            <el-button
              :disabled="pageNum === 1"
              icon="fa fa-chevron-left"
              plain
              size="mini"
              @click="pageNum--"
            />
            <el-button
              :disabled="noMoreItems"
              icon="fa fa-chevron-right"
              plain
              size="mini"
              @click="pageNum++"
            />
          </el-button-group>
        </div>
        <div
          v-for="(result, index) in limitedResults"
          :key="String(result.id) + index"
          class="data-row"
        >
          <div class="item">
            <el-checkbox
              :checked="!!selected.find(v => v.item.id === result.id)"
              :disabled="outsideOfRange(result) || selectedAudiences.includes(result.id)"
              @change="handleCheckItem('audience', result)"
            />
            <div
              :class="audienceClass(result)"
              @click="handleChooseItem('audience', result)"
            >
              <audience-type-tooltip :type="result.type" />
              <div class="name">
                <el-tooltip
                  effect="light"
                  placement="right"
                  :open-delay="250"
                >
                  <div slot="content">
                    {{ result.name }}
                  </div>
                  <span>{{ result.name }}</span>
                </el-tooltip>
              </div>
              <div class="count">
                <i class="fa fa-mobile-android-alt" />
                {{ result.count | numeral }}
              </div>
              <div class="count">
                <i class="fa fa-fw fa-map" />
                {{ result.geo_count | numeral | handleNull }}
              </div>
              <div>
                <el-tag
                  size="mini"
                  :type="outsideOfRange(result) ? 'danger' : ''"
                >
                  {{ result.start_date }} - {{ result.end_date }}
                </el-tag>
              </div>
            </div>
          </div>

          <div v-if="result.publications && result.publications.length > 0">
            <template v-for="pub in result.publications">
              <div
                v-if="pub.device_type === 'MOBILE'"
                :key="pub.id"
                class="item"
              >
                <el-checkbox
                  :checked="!!selected.find(v => v.item.id === pub.id)"
                  @change="handleCheckItem('publication', pub, result)"
                />
                <div
                  class="publication selectable"
                  @click="handleChooseItem('publication', pub, result)"
                >
                  <div class="icon">
                    <i class="fa fa-paper-plane" />
                  </div>
                  <div class="name">
                    {{ pub.publish_partner.partner.name }}
                    <span v-if="pub.extension">({{ pub.extension_type.toLowerCase() | upperCaseFirst }} Extension)</span>
                  </div>
                  <div class="count">
                    <i class="fa fa-mobile-android-alt" />
                    {{ pub.publish_count | numeral }}
                  </div>
                  <div>
                    <el-tag
                      size="mini"
                      type="info"
                    >
                      {{ pub.updated_at | dateString({ includeTime: true }) }}
                    </el-tag>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-dialog>

    <div class="search-box">
      <el-input
        v-model="searchKeyword"
        placeholder="Search by audience name, tags, etc."
        prefix-icon="fa fa-search"
        @keydown.native.enter="handleSearch"
        @keydown.native.esc="handleClear"
      />
      <el-button
        type="primary"
        :disabled="searching"
        @click="handleSearch"
      >
        Search
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { attribution as attributionApi } from '@/adonis-api';
import { upperCaseFirst } from 'upper-case-first';
import AudienceTypeTooltip from '@/components/global/AudienceTypeTooltip';

const FETCH_COUNT = 100; // get 100 at a time, max
const PER_PAGE = 10;

export default {
  name: 'AttributionPicker',
  components: {
    AudienceTypeTooltip,
  },
  filters: {
    upperCaseFirst,
  },
  props: {
    recur: { type: Boolean, default: false },
    selectedItems: { type: Array, default: () => [] },
    stage: {
      validator(value) {
        return ['target', 'destination'].includes(value);
      },
      required: true,
    },
  },

  data() {
    return {
      itemsFetched: 0,
      pageNum: 1,
      perPage: 10,
      popoverVisible: false,
      results: [],
      searching: false,
      searchKeyword: '',
      selected: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters('settings', ['rangeStart']),
    ...mapGetters('user', ['hasPermission']),

    limitedResults() {
      const start = (this.pageNum - 1) * PER_PAGE;
      const end = this.pageNum * PER_PAGE;

      return this.results.slice(start, end);
    },

    noMoreItems() {
      return this.total <= this.pageNum * this.perPage;
    },

    selectedAudiences() {
      return this.selectedItems
        .filter(item => item.targetType === 'audience')
        .map(item => item.id);
    },

    selectedPublications() {
      return this.selectedItems
        .filter(item => item.targetType === 'publication')
        .map(item => item.id);
    },
  },

  watch: {
    pageNum(val) {
      if (val * PER_PAGE > this.itemsFetched) {
        this.fetchItems();
      }
    },

    searchKeyword() {
      this.popoverVisible = false;
    },
  },

  created() {
    window.addEventListener('click', this.clickHandler);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.clickHandler);
  },

  methods: {
    audienceClass(audience) {
      return {
        audience: true,
        selectable: true,
        disabled: this.outsideOfRange(audience) || this.selectedAudiences.includes(audience.id),
      };
    },

    clickHandler(event) {
      this.popoverVisible = false;
    },

    async fetchItems() {
      const response = await attributionApi.search({
        page: (this.itemsFetched / FETCH_COUNT || 0) + 1,
        per_page: FETCH_COUNT,
        recur: this.recur,
        search: this.searchKeyword.trim().split('-').join(' '),
        selected_audiences: this.selectedAudiences,
        selected_publications: this.selectedPublications,
        stage: this.stage,
      });

      this.results.push(...response.data);
      this.itemsFetched += FETCH_COUNT;
      this.total = Number(response.total);
    },

    handleCheckItem(type, item, parent) {
      if (parent) {
        item.parent = parent;
      }
      const index = this.selected.findIndex((value) => value.item.id === item.id);
      if (index !== -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push({ type, item });
      }
    },

    handleMultiSelection() {
      const filteredSelections = this.selected.filter(({ type, item }) => {
        if (type === 'audience') { return !this.outsideOfRange(item); }
        return true;
      });
      this.$emit('select', filteredSelections);
      this.reset();
    },

    handleChooseItem(type, item, parent) {
      if (type === 'audience' && this.outsideOfRange(item)) return;
      if (this.selectedItems.map(i => i.id).includes(item.id)) return;

      const newItem = item;

      if (parent) newItem.parent = parent;
      this.$emit('select', [{ type, item: newItem }]);
      this.reset();
    },

    handleClear() {
      this.reset();
    },

    async handleSearch() {
      if (this.searchKeyword.length < 3) return;

      this.itemsFetched = 0;
      this.results = [];
      try {
        this.searching = true;
        await this.fetchItems();
        this.pageNum = 1;
        this.popoverVisible = true;
      } catch (e) {
        this.$reportError(e);
      } finally {
        this.searching = false;
      }
    },

    outsideOfRange(result) {
      return this.rangeStart.isAfter(result.start_date);
    },

    reset() {
      this.popoverVisible = false;
      this.searchKeyword = '';
      this.selected = [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.paginator {
  align-items: center;
  display: flex;
  margin-bottom: 1em;

  .result-count {
    flex: 1;
  }
}

.data-row {
  display: flex;
  flex-direction: column;

  .el-checkbox {
    padding: 0.4em;
  }

  .selectable {
    cursor: pointer;
    padding: 0.4em;

    &:hover {
      background-color: $--select-option-hover-background;
    }
  }

  .item {
    display: flex;
    align-items: baseline;
  }

  .audience {
    align-items: center;
    display: flex;
    flex: 1;
  }

  .disabled {
    color: #bbb;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  }

  .publication {
    align-items: center;
    display: flex;
    flex: 1;
    padding-left: 2em;
  }

  .icon {
    margin-right: 0.4em;
  }

  .name {
    flex: 1;
    margin: 0 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .count {
    margin-right: 1em;
  }
}

.multi-select {
  margin-right: 2em;
}

.search-box {
  align-items: center;
  display: flex;

  > div:first-of-type {
    margin-right: 0.4em;
  }
}
</style>
