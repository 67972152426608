<template>
  <div class="footfall-container">
    <!-- Header with Banner Image -->
    <div class="header">
      <h1>Footfall Attribution</h1>
      <p
        v-if="
          reportData.reportconfigs.footfallAttributionOptions
            .attribution_footfall_description
        "
      >
        {{
          reportData.reportconfigs.footfallAttributionOptions
            .attribution_footfall_description
        }}
      </p>
    </div>
    <div class="content-wrapper">
      <div class="first-row">
        <div class="cell-wrapper">
          <div
            v-if="
              reportData.reportconfigs.footfallAttributionOptions
                .footfallUniqueResponders
            "
            class="cell"
          >
            <p class="number">
              {{
                Number(
                  reportData.reportData.footfallUniqueResponders[0]
                    .response_uniques
                ).toLocaleString() || 0
              }}
            </p>
            <p class="description">
              Footfall Unique Responders
            </p>
          </div>
          <div
            v-if="
              reportData.reportconfigs.footfallAttributionOptions
                .footfallTotalVisits
            "
            class="cell"
          >
            <p class="number">
              {{
                Number(
                  reportData.reportData.footfallTotalVisits[0].sum
                ).toLocaleString() || 0
              }}
            </p>
            <p class="description">
              Footfall Total Visits
            </p>
          </div>
        </div>
        <div
          v-if="
            reportData.reportconfigs.footfallAttributionOptions.visitsByWeekday
              .enabled
          "
          class="chart-wrapper"
        >
          <div class="legend-wrapper">
            <h1 class="title">
              Visits by Weekday
            </h1>
            <div class="legend-container">
              <div class="legend-item">
                <div class="circle blue" />
                <span>Initial</span>
              </div>
              <div
                class="legend-item"
                style="margin-top: 10px"
              >
                <div class="circle yellow" />
                <span>Repeat</span>
              </div>
            </div>
          </div>
          <line-chart
            class="line-chart-container"
            :chart-data="reportData.reportData"
            :labels="
              reportData.reportconfigs.footfallAttributionOptions
                .visitsByWeekday.days
            "
          />
        </div>
      </div>
      <div class="second-row">
        <div
          v-if="
            reportData.reportconfigs.footfallAttributionOptions
              .FootfallResponsesTarget
          "
          class="table"
        >
          <name-and-responses
            :orientation="orientation"
            :items="reportData.reportData.footfallResponsesByAudience"
            title="Footfall Responses By Target Audience"
          />
        </div>
        <div
          v-if="
            reportData.reportconfigs.footfallAttributionOptions
              .FootfallResponsesDestination
          "
          class="table"
        >
          <name-and-responses
            :orientation="orientation"
            :items="reportData.reportData.responsesByAudienceDestinations"
            title="Footfall Responses By Destination Location"
            responses-key="Responses"
          />
        </div>
      </div>
      <footer-section :logo-u-r-l="logoURL" />
    </div>
  </div>
</template>

<script>
import NameAndResponses from '../NameAndResponses.vue';
import LineChart from '../../report-components/FootfallLineChart';
import FooterSection from '../FooterSection.vue';

export default {
  name: 'FootfallReport',
  components: {
    NameAndResponses,
    LineChart,
    FooterSection,
  },
  props: {
    orientation: {
      type: String,
      required: true,
      validator: (value) => ['landscape', 'portrait'].includes(value),
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    displayData() {
      return {};
    },

    logoURL() {
      return this.reportData.logoURL || '/images/onspot-logo.svg';
    },
  },
  mounted() {},

  methods: {
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
  },
};
</script>

<style lang="scss" scoped>
.footfall-container {
  max-height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  height: 10vh;
  margin: 12px;

  h1,
  p {
    margin: 0;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 90vh;
  max-height: 89vh;
  gap: 12px;
  margin: 12px;
  border-radius: 8px;
  justify-content: space-between;

  background-color: #f3f3f3;
  padding: 14px;
}

.first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30%;
  gap: 12px;

  .cell-wrapper {
    // width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 12px;

    .cell {
      height: 100%;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      p {
        margin: 12px;
        text-align: left;
      }

      .number {
        margin-bottom: 12px;
      }
    }
  }

  .chart-wrapper {
    // width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color: white;
    // padding: 8px 18px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 100%;

    .legend-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: fit-content;
      margin: 14px;

      h1 {
        margin: 0;
      }
    }

    .line-chart-container {
      width: 100%;
      margin: 14px;
    }
  }

  .cell-wrapper,
  .chart-wrapper {
    flex-grow: 1;
  }
}

.second-row {
  height: 60%;
  max-height: 60%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;

  .table {
    width: 49%;
  }
}

.footer-wrapper {
  max-height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  // margin-top: 24px;

  .footer-text {
    text-align: left;
  }
}

.data-display-area {
  background-color: #f3f3f3; /* Grey background */
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  padding-top: 3%;
}

.data-display-area-landscape {
  background-color: #f3f3f3; /* Grey background */
  padding-left: 10%;
  padding-right: 10%;
}
.white-background {
  background-color: white;
}

.data-row {
  margin-bottom: 20px; /* Adjust as needed for gap between rows */
}

.card-portrait {
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 20px; /* Space between cards */
}

.card {
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 20px; /* Space between cards */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for the card effect */
}

.row-gap {
  height: 20px; /* Adjust as needed for gap between rows */
}

.portrait {
  padding-top: 1% !important;
}

// .cell {
//   display: flex; /* Use flexbox for layout */
//   flex-direction: column; /* Stack children vertically */
//   align-items: center; /* Center children horizontally */
//   justify-content: center; /* Center children vertically */
//   padding: 10px;
//   margin: 10px;
// }

.number {
  font-size: 36px; /* Larger font for numbers */
  text-align: center; /* Center text horizontally */
  margin-right: 10px; /* Spacing between number and description */
}

.description {
  font-size: 24px; /* Smaller font for descriptions */
  text-align: left; /* Center text horizontally */
}

.chart-container {
  width: 750px;
  height: 300px; /* Adjust as needed */
  overflow: hidden;
}

.chart-container-landscape {
  width: 100%;
  height: 300px; /* Adjust as needed */
  overflow: hidden;
}

.title {
  font-size: 24px; /* Adjust as needed */
}
.legend-container {
  display: flex;
  flex-direction: column; /* This will make them stack vertically */
  align-items: left;
  text-align: left; /* Centering the text */
}
.description,
.number {
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflowed content */
  text-overflow: ellipsis; /* Add ellipsis when overflowed */
  max-width: 100%; /* Set a max-width */
}
.legend-item {
  display: flex;
  align-items: left;
  margin-bottom: 24px; /* Bigger space between the items */
}

.circle {
  width: 30px; /* Bigger circles */
  height: 30px;
  border-radius: 50%;
  margin-right: 12px;
}

.blue {
  background-color: rgba(43, 116, 184, 0.4);
}

.yellow {
  background-color: rgba(244, 212, 112, 0.4);
}

.page-content {
  padding: 40px; /* You can adjust the value as needed */
  background-color: white; /* Ensuring the background is white */
}
.landscape {
  padding-top: 2% !important;
}

@media (max-height: 916px) {
  .cell {
    .number {
      font-size: 18px;
      font-weight: bold;
    }

    .description {
      font-size: 14px;
    }
  }

  .second-row {
    .table {
      .container {
        h1 {
          font-size: 18px !important;
        }
      }
    }
  }

  .legend-wrapper {
    h1 {
      font-size: 18px;
    }
  }
  .header {
    height: 15vh;
  }
  .content-wrapper {
    height: 75vh;
    gap: 8px;
  }
  .line-chart-container {
    margin: 8px !important;
  }
}
</style>
