<template>
  <div class="footfall-container">
    <!-- Header with Banner Image -->
    <div class="header">
      <h1>Footfall Attribution</h1>
      <p
        v-if="
          reportData.reportconfigs.footfallAttributionOptions
            .attribution_footfall_description
        "
      >
        {{
          reportData.reportconfigs.footfallAttributionOptions
            .attribution_footfall_description
        }}
      </p>
    </div>
    <div class="content-wrapper">
      <div class="data-row">
        <div class="first-row">
          <name-and-responses
            :orientation="orientation"
            :items="reportData.reportData.attributionResponsesByCity"
            title="Footfall Responses By City"
            responses-key="sum"
            name-key="city"
            name-column-label="City"
            third-column-key="state"
            :show-third-column="true"
            @item-clicked="handleCityClicked"
          />
          <!-- :height="containerHeight" -->
          <map-attribution
            :key="mapForceRerenderKey"
            :id-map="0"
            :maps-config="cityData"
            :map="cityConfigs"
            style="width: 100%"
            :height="'100%'"
          />
        </div>
        <div class="second-row">
          <name-and-responses
            :orientation="orientation"
            :items="reportData.reportData.attributionResponsesByZipcode"
            title="Footfall Responses By Zip Code"
            name-column-label="Zip Code"
            name-key="zipcode"
            responses-key="sum"
            :show-hr="true"
            @item-clicked="handleZipClicked"
          />
          <map-attribution
            :key="mapForceRerenderKey"
            :id-map="1"
            :maps-config="zipcodeData"
            :map="zipcodeConfigs"
            boundary-style="dotted"
            style="width: 100%"
            :height="'100%'"
          />
        </div>
      </div>
      <div
        v-if="weatherData"
        class="third-row"
      >
        <weather-card :weather-data="weatherData" />
      </div>
      <footer-section :logo-u-r-l="logoURL" />
    </div>
  </div>
</template>

<script>
import NameAndResponses from '../NameAndResponses.vue';
import {
  attribution as attributionApi,
  reporting as reportingApi,
} from '@/adonis-api';
import MapAttribution from '../MapAttribution.vue';
import WeatherCard from '../WeatherCard.vue';
import FooterSection from '../FooterSection.vue';

export default {
  name: 'FootfallReport',
  components: {
    NameAndResponses,
    MapAttribution,
    WeatherCard,
    FooterSection,
  },
  props: {
    orientation: {
      type: String,
      required: true,
      validator: (value) => ['landscape', 'portrait'].includes(value),
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      weatherFetchFailures: 0,
      weatherData: null,
      cityData: this.reportData.reportData.mapsData[0],
      zipcodeData: this.reportData.reportData.mapsData[1],
      cityConfigs: {
        id: 'attributionResponsesByCity_data',
        accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-74.5, 40],
        zoom: 10,
      },
      zipcodeConfigs: {
        id: 'attributionResponsesByZipcode_data',
        accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
        style: 'mapbox://styles/mapbox/light-v10',
        center: [-74.5, 40],
        zoom: 10,
      },
      mapForceRerenderKey: 0, // Add this property
    };
  },
  computed: {
    containerHeight() {
      return this.orientation === 'landscape' ? '600px' : '700px';
    },
    displayData() {
      return {};
    },
    logoURL() {
      return this.reportData.logoURL || '/images/onspot-logo.svg';
    },
  },
  mounted() {
    // Check if the orientation is 'portrait'
    if (this.orientation === 'portrait') {
      // Set a timeout to increment the key after 3 seconds
      setTimeout(() => {
        this.mapForceRerenderKey++;
      }, 3000);
    }
    this.fetchWeatherData();

    this.$nextTick(() => {
      if (this.reportData.reportData.attributionResponsesByCity.length > 0) {
        this.handleCityClicked(this.reportData.reportData.attributionResponsesByCity[0]);
      }
      if (this.reportData.reportData.attributionResponsesByZipcode.length > 0) {
        this.handleZipClicked(this.reportData.reportData.attributionResponsesByZipcode[0]);
      }
    });
  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    async fetchWeatherData(
      city = this.reportData.reportData.attributionResponsesByCity[0].city,
      state = this.reportData.reportData.attributionResponsesByCity[0].state,
    ) {
      const defaultOptions = {
        location: `${city},${state}`,
        start_date: this.reportData.reportData.cumulativeResponses[0].date_hour,
        end_date:
          this.reportData.reportData.cumulativeResponses[
            this.reportData.reportData.cumulativeResponses.length - 1
          ].date_hour,
      };
      if (this.weatherFetchFailures <= 3) {
        // Only make the API call if there have been 3 or fewer failures
        try {
          const response = await reportingApi.historicalWeather(defaultOptions);

          if (!response.success) {
            this.weatherFetchFailures++;
          } else {
            this.weatherData = response.data;
            this.weatherFetchFailures = 0; // Reset on success
          }
        } catch (error) {}
      }
    },
    setDefaultImage(event) {
      event.target.src = '/images/onspot-logo.svg';
    },
    async handleCityClicked(item) {
      // Handle the clicked item here
      const data = await attributionApi.getTopCity(item);
      this.cityData = data;
      this.mapForceRerenderKey++;
      this.fetchWeatherData(item.city, item.state);
    },
    async handleZipClicked(item) {
      // Handle the clicked item here
      const data = await attributionApi.getTopZipcode(item);
      this.zipcodeData = data;
      this.mapForceRerenderKey++;
    },
  },
};
</script>
<style lang="scss" scoped>
.footfall-container {
  max-height: 100vh;
  width: 100%;
  // max-width: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  height: 10vh;
  margin: 12px;

  h1,
  p {
    margin: 0;
  }
}

.content-wrapper {
  max-width: 100%;
  display: flex;
  height: 80vh;
  max-height: 90vh;
  flex-direction: column;
  gap: 12px;
  background-color: #f5f5f5;
  padding: 12px;
  margin: 12px;
  border-radius: 8px;

  .data-row {
    display: flex;
    width: 100%;
    gap: 12px;
    justify-content: space-between;
    height: 60%;

    .first-row {
      width: 48%;
      display: flex;
      flex-direction: column;
      // background-color: white;
    }

    .second-row {
      width: 48%;
      display: flex;
      flex-direction: column;
      // background-color: white;
      // height: 100%;

      // .item {
      //   height: 50%;
      // }
    }
  }

  .third-row {
    height: 30%;
    min-height: 30%;
  }
}

@media (max-height: 900px) {
  .nameAndResponses.container {
    height: 60% !important;
  }
}
</style>
