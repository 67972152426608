<template>
  <div>
    <el-row
      v-if="jobs.length > 0 && isInternal"
      :gutter="20"
      class="job-detail"
    >
      <h3>Jobs</h3>
      <div
        v-for="job of jobs"
        :key="job.id"
        class="job"
        :class="job.status.toLowerCase()"
      >
        <div class="label">
          {{ job.label }}
        </div>
        <div>
          <span
            class="status"
            :class="job.status.toLowerCase()"
          >{{ job.status }}</span>
          <el-tag
            class="id-tag"
            size="small"
          >
            ID: {{ job.id }}
          </el-tag>
          <el-button
            size="mini"
            @click="handleViewJob(job)"
          >
            View Job
          </el-button>
        </div>
      </div>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="12"
      >
        <h4>Target Audiences</h4>
        <el-table
          v-loading="targets.length === 0"
          :data="targets"
          stripe
        >
          <el-table-column
            prop="name"
            label="Audience"
          >
            <template slot-scope="data">
              <audience-type-tooltip :type="data.row.type" />
              &nbsp;
              {{ data.row.name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="count"
            label="Devices"
            :width="120"
          >
            <template slot-scope="data">
              {{ data.row.count | numeral }}
            </template>
          </el-table-column>
          <el-table-column
            prop="geo_count"
            label="Geoframes"
            :width="100"
          >
            <template slot-scope="data">
              {{ data.row.geo_count | numeral | handleNull }}
            </template>
          </el-table-column>
          <el-table-column
            prop="start_date"
            label="Start Date"
            :width="110"
          />
          <el-table-column
            prop="end_date"
            label="End Date"
            :width="110"
          />
          <el-table-column
            label="Publication Completed"
            :width="172"
          >
            <template slot-scope="{ row }">
              {{ row.publish_completed | dateString({ includeTime: true }) }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col
        :xs="24"
        :sm="12"
      >
        <h4>Destination Audiences</h4>
        <el-table
          v-loading="destinations.length === 0"
          :data="destinations"
          stripe
        >
          <el-table-column
            prop="name"
            label="Audience"
          >
            <template slot-scope="data">
              <audience-type-tooltip :type="data.row.type" />
              &nbsp;
              {{ data.row.name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="count"
            label="Devices"
            :width="120"
          >
            <template slot-scope="data">
              {{ data.row.count | numeral }}
            </template>
          </el-table-column>
          <el-table-column
            prop="geo_count"
            label="Geoframes"
            :width="100"
          >
            <template slot-scope="data">
              {{ data.row.geo_count | numeral | handleNull }}
            </template>
          </el-table-column>
          <el-table-column
            prop="start_date"
            label="Start Date"
            :width="110"
          />
          <el-table-column
            prop="end_date"
            label="End Date"
            :width="110"
          />
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AudienceTypeTooltip from '../global/AudienceTypeTooltip';
import Attribution from '@/adonis-api/attribution';
import { mapGetters } from 'vuex';
import { upperCaseFirst } from 'upper-case-first';

export default {
  components: {
    AudienceTypeTooltip,
  },

  props: {
    attributionId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      jobs: [],
      sourceAudiences: [],
      sourcePublications: [],
      destinationAudiences: [],
      destinationPublications: [],
    };
  },

  computed: {
    ...mapGetters('user', ['isInternal']),

    targets() {
      const list = [...this.sourceAudiences];

      this.sourcePublications.map(item =>
        list.push({
          name: `${item.audience.name} (${item.publish_partner.partner.name}${item.extension ? ', ' + upperCaseFirst(item.extension_type.toLowerCase()) + ' Extension' : ''})`,
          count: item.publish_count,
          type: 'PUBLICATION',
          start_date: null,
          end_date: null,
          publish_completed: item.completed,
        }),
      );

      return list;
    },
    destinations() {
      const list = [...this.destinationAudiences];
      return list;
    },
  },

  async mounted () {
    const attribution = await Attribution.findOne(this.attributionId);
    this.jobs = attribution.jobs;
    this.sourceAudiences = attribution.source_audiences;
    this.sourcePublications = attribution.source_publications;
    this.destinationAudiences = attribution.destination_audiences;
    this.destinationPublications = attribution.destination_publications;
  },

  methods: {
    handleViewJob(job) {
      this.$router.push(`/jobs/${job.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$element';

.el-row.job-detail {
  margin-left: 0 !important;
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px dotted #dedede;
}

.job {
  padding-left: 0.6em;
  border-left-width: 3px;
  border-left-style: solid;
  margin-bottom: .5em;

  .label {
    flex: 1;
    font-weight: bold;
    margin-bottom: 5px;
  }

  &.complete {
    border-left-color: $--color-success;
  }

  &.failed {
    border-left-color: $--color-danger;
  }
}

.status {
  font-size: .85em;
  width: 100px;
  text-align: center;
  display: inline-block;

  &.complete {
    color: $--color-success;
  }

  &.failed {
    color: $--color-danger;
  }
}

.id-tag {
  font-size: .85em;
  font-weight: bold;
  margin: 0 15px;
}
</style>
