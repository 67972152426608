var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"banner",class:_vm.orientation,style:(_vm.bannerStyle)}),_c('div',{staticClass:"page-content"},[_c('h1',[_vm._v("Attribution Summary")]),(_vm.reportData.reportconfigs.attributionSummary
        .attribution_summary_description
      )?_c('p',[_vm._v(" "+_vm._s(_vm.reportData.reportconfigs.attributionSummary .attribution_summary_description)+" ")]):_vm._e()]),_c('div',{staticClass:"data-display-area"},[_c('div',{staticClass:"card-wrapper"},[(_vm.reportData.reportconfigs.attributionSummary
          .footfallUniqueResponders && !_vm.reportData.digital_only
        )?_c('div',{staticClass:"data-card"},[_c('p',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.displayData.footfallUniqueResponders)+" ")]),_c('p',{staticClass:"description"},[_vm._v(" Footfall Unique Responders ")])]):_vm._e(),(_vm.reportData.reportconfigs.attributionSummary.footfallTotalVisits &&
          !_vm.reportData.digital_only
        )?_c('div',{staticClass:"data-card"},[_c('p',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.displayData.footfallTotalVisits)+" ")]),_c('p',{staticClass:"description"},[_vm._v(" Footfall Total Visits ")])]):_vm._e(),(_vm.reportData.reportconfigs.attributionSummary.totalResponders &&
          _vm.reportData.digital_display &&
          !_vm.reportData.digital_only
        )?_c('div',{staticClass:"data-card"},[_c('p',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.displayData.totalResponders)+" ")]),_c('p',{staticClass:"description"},[_vm._v(" Total Combined Responders ")])]):_vm._e(),(_vm.reportData.reportconfigs.attributionSummary
          .onlineUniqueResponders && _vm.reportData.digital_display
        )?_c('div',{staticClass:"data-card"},[_c('p',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.displayData.onlineUniqueResponders)+" ")]),_c('p',{staticClass:"description"},[_vm._v(" Online Unique Responders ")])]):_vm._e(),(_vm.reportData.reportconfigs.attributionSummary.onlineTotalVisits &&
          _vm.reportData.digital_display &&
          !_vm.reportData.digital_only
        )?_c('div',{staticClass:"data-card"},[_c('p',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.displayData.onlineTotalVisits)+" ")]),_c('p',{staticClass:"description"},[_vm._v(" Online Total Visits ")])]):_vm._e(),(_vm.displayData.totalCombinedVisits && _vm.reportData.digital_display)?_c('div',{staticClass:"data-card"},[_c('p',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.displayData.totalCombinedVisits)+" ")]),_c('p',{staticClass:"description"},[_vm._v(" Total Combined Visits ")])]):_vm._e()]),_c('div',{staticClass:"heat-map",attrs:{"id":"heatmap"}}),_c('footer-section',{attrs:{"logo-u-r-l":_vm.logoURL}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }