<template>
  <div>
    <data-table
      ref="dataTable"
      :key="forceRender"
      :actions="dataTable.actions"
      :data-class="attribution"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      disable-archive
      :page-size="dataTable.pageSize"
      permission-suffix="audiences"
      disable-checkboxes
      @action="handleAction"
      @data-fetch="handleDataFetch"
    >
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <attribution-audience-list :attribution-id="row.id" />
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="Report"
        sortable="custom"
      />
      <el-table-column
        prop="tags"
        label="Tags"
      >
        <template slot-scope="{ row }">
          <el-tag
            v-for="tag in row.tags"
            :key="tag.id"
            size="mini"
          >
            {{ tag.name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="organization"
        label="Organization"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.organization.name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="Created"
        sortable="custom"
        :width="110"
      >
        <template slot-scope="{ row }">
          {{ row.created_at | dateString }}
        </template>
      </el-table-column>
      <el-table-column label="Author">
        <template slot-scope="{ row }">
          {{ row.author | fullName }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="canDownloadLocations"
        label="Report Status"
      >
        <template slot-scope="{ row }">
          <div>
            Attribution:
            <status-dot
              :config="statusDotConfig"
              :value="row.attribution_status"
            />
          </div>
          <div v-if="canDownloadJourneys && row.journey_status !== 'DISABLED'">
            Journey:
            <status-dot
              :config="statusDotConfig"
              :value="row.journey_status"
            />
          </div>
          <div v-if="canDownloadLocations && row.location_status !== 'DISABLED'">
            Locations:
            <status-dot
              :config="statusDotConfig"
              :value="row.location_status"
            />
          </div>
          <div v-if="canDownloadHHLocations && row.household_status !== 'DISABLED'">
            Households:
            <status-dot
              :config="statusDotConfig"
              :value="row.household_status"
            />
          </div>
        </template>
      </el-table-column>
    </data-table>
    <div class="dot-legend">
      <status-dot-legend :config="statusDotConfig" />
    </div>
    <edit-dialog
      v-if="selectedReport"
      :data="selectedReport"
      :visible.sync="showEditNameDialog"
      width="50%"
      @save="handleSave"
      @request-close="selectedReport = null"
    >
      <template slot-scope="{ formData }">
        <el-form-item
          label="Name"
          prop="name"
        >
          <el-input
            v-model="formData.name"
            placeholder="Attribution Name"
          />
        </el-form-item>
        <el-form-item label="Tags">
          <tag-picker :tags.sync="formData.tags" />
        </el-form-item>
      </template>
    </edit-dialog>

    <edit-dialog
      v-if="responderAudience"
      :data="responderAudience"
      :visible.sync="showCreateResponderAudienceDialog"
      width="50%"
      override-title="Create Attribution Responders Audience"
      @save="createResponderAudience"
      @request-close="responderAudience = null"
    >
      <template slot-scope="{ formData }">
        <el-form-item
          label="Name"
          prop="name"
        >
          <el-input
            v-model="formData.name"
            placeholder="Audience Name"
          />
        </el-form-item>
        <el-form-item
          label="Organization"
          prop="organization"
        >
          <org-picker :organization.sync="formData.organization" />
        </el-form-item>
        <el-form-item label="Tags">
          <tag-picker :tags.sync="formData.tags" />
        </el-form-item>
      </template>
    </edit-dialog>
    <attribution-dialog-post-campaign
      :show-dialog="dialog"
      :report="selectedReport"
      @close="handleDialogClose"
    />
  </div>
</template>

<script>
import Hashids from 'hashids';
import _flattenDeep from 'lodash/flattenDeep';
import { mapGetters, mapState } from 'vuex';
import { attribution as attributionApi } from '@/adonis-api';
import AttributionAudienceList from './AttributionAudienceList';
import DataTable from '../global/DataTable.vue';
import { getAttributionMenu } from '@/menus';
import StatusDot from '../global/StatusDot.vue';
import StatusDotLegend from '../global/StatusDotLegend.vue';
import jobWatcher from '@/mixins/job-watcher';
import moment from 'moment';
import { rangeStart } from '../../store/modules/settings';
import { isEmpty } from '../../helpers';
import EditDialog from '../global/EditDialog.vue';
import TagPicker from '../global/TagPicker.vue';
import OrgPicker from '../global/OrgPicker.vue';
import _omit from 'lodash/omit';
import AttributionDialogPostCampaign from './AttributionDialogPostCampaign.vue';
import axios from 'axios';

const hashids = new Hashids(
  process.env.VUE_APP_HASHIDS_SALT,
  Number(process.env.VUE_APP_HASHIDS_PADDING),
);

export default {
  components: {
    AttributionAudienceList,
    DataTable,
    StatusDot,
    StatusDotLegend,
    EditDialog,
    OrgPicker,
    TagPicker,
    AttributionDialogPostCampaign,
  },
  mixins: [jobWatcher],

  data() {
    return {
      forceRender: 0,
      dataTable: Object.freeze({
        actions: getAttributionMenu(this),
        order: 'desc',
        pageSize: this.$store.state.settings.options['pagination:pageSize'],
        sortBy: 'created_at',
      }),
      report: {},
      responderAudience: null,
      statusDotConfig: Object.freeze({
        statuses: [
          {
            value: 'NOT_STARTED',
            label: 'Not Started',
            color: '#666',
            hollow: true,
          },
          {
            value: 'PENDING',
            label: 'Pending',
            color: '#f90',
          },
          {
            value: 'PROCESSING',
            label: 'Processing',
            color: '#07f',
          },
          {
            value: 'COMPLETE',
            label: 'Complete',
            color: '#090',
          },
        ],
      }),
      showEditNameDialog: false,
      showCreateResponderAudienceDialog: false,
      selectedReport: null,
    };
  },

  computed: {
    ...mapGetters('app', ['environment']),
    ...mapGetters('user', ['isInternal', 'hasPermission']),
    ...mapState('attribution', ['chosenAttribution', 'dialog']),
    ...mapGetters('settings', ['rangeStart']),

    canPrepAttribution() {
      return !this.report.historical;
    },

    canViewAttribution() {
      return (
        this.isInternal || this.hasPermission('create_attribution_reports')
      );
    },
    canViewEnhancedAttribution() {
      return (
        (this.isInternal || this.hasPermission('create_and_view_enhanced_attribution'))
      );
    },
    canCreateResponderAudience() {
      return (
        this.isInternal ||
        this.hasPermission('create_attribution_responder_audiences')
      );
    },

    canDownloadLocations() {
      return (
        this.isInternal ||
        this.hasPermission('create_attribution_location_reports')
      );
    },

    canDownloadHHLocations() {
      return this.hasPermission('create_attribution_hh_location_reports');
    },

    canDownloadJourneys() {
      return (
        this.isInternal ||
        this.hasPermission('create_attribution_journey_reports')
      );
    },

    canGenerateDeviceFiles() {
      return this.isInternal;
    },
  },

  watch: {
    watchedJobs: {
      handler(jobs) {
        const completedJobs = jobs.filter(
          job => job.payload.status === 'COMPLETE',
        );

        if (!completedJobs.length) return;

        for (const job of completedJobs) {
          if (job.payload.result) {
            this.$refs.dataTable.updateItemById(
              job.payload.result.attribution_report.id,
              job.payload.result.attribution_report,
            );
          }
        }
      },
      deep: true,
    },
  },

  created() {
    this.attribution = attributionApi;
  },

  methods: {
    handleDialogClose() {
      this.$store.dispatch('attribution/closeDialog');
    },
    async createResponderAudience(data) {
      this.showCreateResponderAudienceDialog = false;
      try {
        const payload = {
          ..._omit(data, ['organization']),
          organization_id: data.organization.id,
        };

        await attributionApi.createResponderAudience({ ...payload })
          .then(response => {
            const that = this;
            if (response.status === 'success') {
              this.$message({
                message: response.message,
                duration: 1000,
              });
              that.responderAudience = null;

              this.$store.dispatch('mqtt/addRawJob', response.data.job);
            } else {
              throw new Error('Bad response while creating attribution responder audience');
            }
          });
      } catch (e) {
        this.$reportError(e);
        this.$notify.error({
          message: 'There was a problem creating the audience.',
          duration: 1000,
        });
        this.responderAudience = null;
      }
    },

    async handleSave(data) {
      this.showEditNameDialog = false;
      try {
        await attributionApi.update(
          this.selectedReport.id,
          { ...data },
        ).then(response => {
          const that = this;
          if (response.status === 'success') {
            this.$message({
              message: 'Successfully updated report name..',
              onClose: function() {
                that.$refs.dataTable.updateItemById(data.id, response.data.attribution_report);
                that.forceRender++;
              },
              duration: 1000,
            });
          } else {
            throw new Error('Bad response while editing attribution report name..');
          }
        });
      } catch (e) {
        this.$reportError(e);
        this.$notify.error({
          message:
                'There was a problem editing the report name.',
          duration: 1000,
        });
        this.forceRender++;
      }
    },

    getAttributionReport(report) {
      return report.data.find(d => d.type === 'attribution');
    },

    async handleAction(command, report) {
      const genReports = {};
      let reportRoute;
      let response;

      switch (command) {
        case 'createResponderAudience':
          this.responderAudience = {
            name: `${report.name} responders`,
            organization: Object.freeze(report.organization),
            attribution_id: report.id,
            auto_dedupe: true,
            tags: Object.freeze(report.tags),
          };
          this.showCreateResponderAudienceDialog = true;
          break;

        case 'clone':
          this.$router.push(`/attribution-reports/clone/${report.id}`);
          break;

        case 'prepAttribution':
        case 'prepJourney':
        case 'prepLocation':
        case 'prepHousehold':
          if (!this.validDateRange(report)) {
            return this.$alert('Dates of the underlying audiences are no longer within the allowed date range.', 'This action cannot be completed');
          }
          if (command === 'prepAttribution') {
            genReports.generate_attribution_report = true;
          } else if (command === 'prepJourney') {
            genReports.generate_journey_report = true;
          } else if (command === 'prepLocation') {
            genReports.generate_location_report = true;
          } else if (command === 'prepHousehold') {
            genReports.generate_household_report = true;
          }

          try {
            response = await attributionApi.requestReport(
              report.id,
              genReports,
            );
            this.watchJob(...response.data.jobs.map(job => job.id));
            this.$message('Report is being prepared.');
          } catch (e) {
            this.$notify.error({
              message:
                'There was a problem preparing the report. This issue has been reported.',
            });
            this.$reportError(e, {
              command,
              report,
            });
          }
          break;
        case 'downloadPDF':
          try {
            const url = process.env.VUE_APP_ADONIS_ENDPOINT === 'https://app-backend-dev.onspotdata.com' ? `https://osd-v2-backend-development.s3.amazonaws.com/public/attributionPdf/merged_${report.uuid}.pdf` : `https://osd-v2-backend-production.s3.amazonaws.com/public/attributionPdf/merged_${report.uuid}.pdf`;

            const response = await axios.get(url, {
              responseType: 'blob', // Important for receiving binary data
            });

            if (response.status === 200) {
              const blob = new Blob([response.data], { type: 'application/pdf' });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = `${report.name}.pdf`;
              link.click();
              window.URL.revokeObjectURL(link.href);
            }
          } catch (e) {
            this.$notify.error({
              message:
                'There was a problem downloading your PDF.',
            });
            this.$reportError(e, {
              command,
              report,
            });
          }
          break;
        case 'generateDeviceFiles':
          if (!this.validDateRange(report)) {
            return this.$alert('Dates of the underlying audiences are no longer within the allowed date range.', 'This action cannot be completed');
          }

          try {
            response = await attributionApi.generateDeviceFiles(report.id);
            this.watchJob(...response.data.jobs.map(job => job.id));
            this.$message('Device files are being prepared.');
          } catch (e) {
            this.$notify.error({
              message:
                'There was a problem preparing the device files. This issue has been reported.',
            });
            this.$reportError(e, {
              command,
              report,
            });
          }
          break;
        case 'viewAttribution':
          reportRoute = this.$router.resolve({
            path: `/report-viewer/${hashids.encode(report.id)}`,
          });
          window.open(reportRoute.href, '_blank');
          break;
        case 'viewEnhancedAttribution': {
          const queryParams = new URLSearchParams({
            reportId: report.uuid,
            orientation: 'landscape',
          }).toString();

          const resolvedQuery = JSON.parse('{"' + decodeURI(queryParams).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

          const reportRoute = this.$router.resolve({
            path: '/attribution/reports/title_page',
            query: resolvedQuery,
          });

          window.open(reportRoute.href, '_blank');
          break;
        }

        case 'copyAttributionLink':
          await this.$copyText(`${window.location.protocol}//${window.location.host}/report-viewer/${hashids.encode(report.id)}`);
          this.$message({
            message: 'URL copied to clipboard.',
            type: 'success',
          });
          break;
        case 'downloadJourney':
          window.location.href = report.journey_report.signed_url;
          break;
        case 'downloadLocation':
          window.location.href = report.location_report.signed_url;
          break;
        case 'downloadHousehold':
          window.location.href = report.household_report.signed_url;
          break;
        case 'editReportName':
          this.showEditNameDialog = true;
          this.selectedReport = report;
          break;
        case 'generatePostCampaign':
          this.selectedReport = report;
          this.$store.dispatch('attribution/showDialog', {
            attribution: report,
            dialog: 'postCampaign',
          });
          break;
        default:
          break;
      }
    },

    handleDataFetch(reports) {
      const jobIds = _flattenDeep(reports.map(report => report.job_ids)).filter(
        id => id,
      );
      this.setWatchedJobs(jobIds);
    },

    validDateRange(item) {
      let earliest = [
        ...item.source_audiences.map(i => i.start_date),
        ...item.destination_audiences.map(i => i.start_date),
      ];

      if (earliest.length > 0) {
        earliest = earliest.reduce((previous, current) => previous > current ? current : previous);
      }

      let latest = [
        ...(item.source_audiences.map(i => i.end_date) || [null]),
        ...(item.destination_audiences.map(i => i.end_date) || [null]),
      ];

      if (latest.length > 0) {
        latest = latest.reduce((previous, current) => previous > current ? previous : current);
      }

      if ((earliest !== null && moment(earliest, 'YYYY-MM-DD').isBefore(rangeStart)) || (latest !== null && moment(latest, 'YYYY-MM-DD').isBefore(rangeStart))) {
        return false;
      } else if ((Array.isArray(earliest) && isEmpty(earliest)) || (Array.isArray(latest) && isEmpty(latest))) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: inline-block;
  margin: 16px 0;
}

.search-box {
  max-width: 180px;
}

.small-text {
  font-size: 0.75em;
}

.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin-left: 4px;
}

.controls {
  align-items: center;
  display: flex;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}

.dot-legend {
  margin-top: 1em;
}
</style>
